export const ADD_FILE = "ADD_FILE";

export const addFile = file => ({
  type: ADD_FILE,
  payload: { file }
});

export const RESET_FILE = "RESET_FILE";

export const resetFile = () => ({
  type: RESET_FILE
});


export const LIST_FILE = "LIST_FILE";

export const listFile = files => ({
  type: LIST_FILE,
  payload: { files }
});

export const FILE_COUNT = "FILE_COUNT";

export const getFileCount = count => ({
  type: FILE_COUNT,
  payload: { count }
});

export const SET_FILE_PAGE = "SET_FILE_PAGE";

export const setFilePage = page => ({
  type: SET_FILE_PAGE,
  payload: { page }
});

export const SET_FILE_SIZE = "SET_FILE_SIZE";

export const setFileSize = size => ({
  type: SET_FILE_SIZE,
  payload: { size }
});

export const UPDATE_FILE = "UPDATE_FILE";

export const updateFile = file => ({
  type: UPDATE_FILE,
  payload: { file }
});

export const SET_SELECTED_FILE = "SET_SELECTED_FILE";

export const setSelectedFile = selectedFile => ({
  type: SET_SELECTED_FILE,
  payload: { selectedFile }
});

export const ALL_FILES = "ALL_FILES";

export const allFiles = files => ({
  type: ALL_FILES,
  payload: { files }
});

export const SET_SELECTED_FILE_ID = "SET_SELECTED_FILE_ID";

export const setSelectedFileId = selectedFileId => ({
  type: SET_SELECTED_FILE_ID,
  payload: { selectedFileId }
});

export const SEARCH_FILE_COUNT = "SEARCH_FILE_COUNT";

export const getSearchFileCount = searchFileCount => ({
  type: SEARCH_FILE_COUNT,
  payload: { searchFileCount }
});