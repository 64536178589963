
export const getFeatures = state => {
  return state.features.data;
};

export const getFeatureCount = state => {
  return state.features.count;
};

export const getSize = state => {
  return state.features.size;
};

export const getPage = state => {
  return state.features.page;
};

export const getIncludeFeatures = state => {
  return state.features.IncludeFeatureData;
};

export const getIncludeFeatureCount = state => {
  return state.features.includeFeatureCount;
};

export const getIncludeFeatureSize = state => {
  return state.features.includeFeatureSize;
};

export const getIncludeFeaturePage = state => {
  return state.features.includeFeaturePage;
};

export const getSelectedFeature = state => {
  return state.features.selectedFeature;
};

export const getAssociatedFeatures = state => {
  return state.features.associatedFeaturesData;
};


export const getIncludeFiles = state => {
  return state.features.IncludeFileData;
};

export const getIncludeFileCount = state => {
  return state.features.includeFileCount;
};

export const getIncludeFileSize = state => {
  return state.features.includeFileSize;
};

export const getIncludeFilePage = state => {
  return state.features.includeFilePage;
};

export const getAssociatedFiles = state => {
  return state.features.associatedFilesData;
};

export const getCreatedBy = keycloak => {
  return keycloak.tokenParsed.preferred_username;
};

export const getUpdatedby = keycloak => {
  return keycloak.tokenParsed.preferred_username;
};

export const getSearchParameters = state => {
  return state.features.searchParameters;
}

export const getSearchFeatureCount = state => {
  return state.features.searchFeatureCount;
};

