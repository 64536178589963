import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SaveIcon from "@material-ui/icons/Save";
import { connect } from "react-redux";
import { updateEnvironmentRequest } from "../../thunks/environment/thunks";
import { getSelectedEnvironment } from "../../selectors/environment/selector";
import AceEditor from "react-ace";
import Chip from "@material-ui/core/Chip";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-gherkin";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/gherkin";
import TextField from "@material-ui/core/TextField";
import { hasReadOnlyRole } from '../../thunks/common/auth';
import EnvironmentSelector from '../menu/EnvironmentSelector'
import { getSelectedApplication } from "../../selectors/application/selector";
import axios,* as others from 'axios';
import {GPT_TOKEN} from "../../EnvVariables";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  dropdown: {
    marginTop: 15,
    marginRight: 50
  },
  buttonGroupPosition: {
    position: "absolute",
    top: theme.spacing(10),
    right: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  }
}));

const TabPanel = props => {
  const { children, value, index } = props;

  return (
    <Typography hidden={value !== index} id={`environment-${index}`}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const currentTab = index => {
  return {
    id: `environment-tab-${index}`
  };
};

const EnvironmentTab = ({ updateEnvironment, selectedEnvironment, applicationId }) => {
  const [value, setValue] = React.useState(0);
  const [liveAnorocContent, setAnorocContent] = React.useState("");
  const [liveKarateContent, setKarateContent] = React.useState("");
  const [environmentName, setEnvironmentName] = React.useState("");
  const [liveSettingsContent, setSettingsContent] = React.useState("");
  const [show, setShow] = React.useState('true');


  useEffect(() => {
    if (selectedEnvironment) {
      if (selectedEnvironment.anoroc_content !== null) {
        setAnorocContent(selectedEnvironment.anoroc_content);
      } else {
        setAnorocContent("");
      }
      if (selectedEnvironment.karate_content !== null) {
        setKarateContent(selectedEnvironment.karate_content);
      } else {
        setKarateContent("");
      }
      if (selectedEnvironment.settings_content !== null) {
        setSettingsContent(selectedEnvironment.settings_content);
      } else {
        setSettingsContent("");
      }
      setEnvironmentName(selectedEnvironment.name);
    }
  }, [selectedEnvironment, applicationId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function isValidJSONString(str) {
    try {
      if(str !== ""){
        JSON.parse(str);
        }
    } catch (e) {
      console.log(e);
      return e;
    }
    return true;
  }

  // GPT Implementation 

  const updateAnorocContentWithGptResponse = async(selectedEnvironment,environmentName,liveAnorocContent,liveKarateContent,liveSettingsContent,applicationId) =>{
    const obj = JSON.parse(liveAnorocContent);
    const gptText = obj.gptPrompt;
    const data = await gptResponse(gptText);
    liveAnorocContent = data;
    updateEnvironment(
      selectedEnvironment,
      environmentName,
      liveAnorocContent,
      liveKarateContent,
      liveSettingsContent,
      applicationId
   );
  }
  const updateKarateContentWithGptResponse = async(selectedEnvironment,environmentName,liveAnorocContent,liveKarateContent,liveSettingsContent,applicationId) =>{
    const obj = JSON.parse(liveAnorocContent);
    const gptText = obj.gptPrompt;
    const data = await gptResponse(gptText);
    liveKarateContent = data;
    updateEnvironment(
      selectedEnvironment,
      environmentName,
      liveAnorocContent,
      liveKarateContent,
      liveSettingsContent,
      applicationId
   );
  }
  const gptResponse = async(gptText) =>
      {

          const format = "need  JSON Key value pairs format with unique values for ";
          const context = ". Please return data in Valid JSON format";
          console.log("Passed content is ",gptText);
          console.log("format content is ",format);
          console.log("whole content is ",format+gptText+context);

          const result = ( await axios.post('https://api.openai.com/v1/chat/completions',
        {
          'model': 'gpt-3.5-turbo',
          'messages': [{"role": "user", "content": format+gptText+context}]
        },
        {
          headers: {'Authorization': `Bearer ${GPT_TOKEN}`,
          'Content-Type': 'application/json'}
      }));
      const data = result.data.choices[0].message.content;
      console.log('api response :', data)

      return data;
      }

  function SubmitButton(){
    if (isValidJSONString(liveAnorocContent) == true && isValidJSONString(liveKarateContent) == true && isValidJSONString(liveSettingsContent) == true) {
      return   <Button
      color="primary"
      startIcon={<SaveIcon />}
      variant="contained"
      className={classes.button}
      onClick={() => {
        try{
          if(liveAnorocContent.includes("gptPrompt")){
            updateAnorocContentWithGptResponse(selectedEnvironment,environmentName,liveAnorocContent,liveKarateContent,liveSettingsContent,applicationId);
          }else if(liveKarateContent.includes("gptPrompt")){
            updateKarateContentWithGptResponse(selectedEnvironment,environmentName,liveAnorocContent,liveKarateContent,liveSettingsContent,applicationId);
          }else{
            updateEnvironment(
              selectedEnvironment,
              environmentName,
              liveAnorocContent,
              liveKarateContent,
              liveSettingsContent,
              applicationId
            );
          }
        }catch(error){
          console.error('An error occurred while updating the feature:', error);
        }finally{
          updateEnvironment(
            selectedEnvironment,
            environmentName,
            liveAnorocContent,
            liveKarateContent,
            liveSettingsContent,
            applicationId
          );  }
      }}
      disabled={hasReadOnlyRole()}>Update</Button>
    } else {
      return  <Button
      color="primary"
      startIcon={<SaveIcon />}
      variant="contained"
      className={classes.button}
      disabled>Update</Button>
    }
  };


  const classes = useStyles();

  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }} >
        <Box className={classes.root}>
          <TextField
            id="environmentName"
            label="Environment Name"
            style={{ margin: 12 }}
            width="1000"
            value={environmentName}
            onChange={e => setEnvironmentName(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="UI Test Data" {...currentTab(0)} />
            <Tab label="API Test Data" {...currentTab(1)} />
            <Tab label="Settings" {...currentTab(1)} />
         {isValidJSONString(liveAnorocContent) != true && 
         <Typography variant="caption" color="error" align="right" style= {{marginLeft: '50px', marginTop: '12px'}} > Invalid json format, please update the file and retry. </Typography>
        }
         {isValidJSONString(liveKarateContent) != true && 
         <Typography variant="caption" color="error" align="right" style= {{marginLeft: '50px', marginTop: '12px'}} > Invalid json format, please update the file and retry. </Typography>
        }
         {isValidJSONString(liveSettingsContent) != true && 
         <Typography variant="caption" color="error" align="right" style= {{marginLeft: '50px', marginTop: '12px'}} > Invalid json format, please update the file and retry. </Typography>
        }
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={liveAnorocContent}
              onChange={newContent => {
                setAnorocContent(newContent);
              }}
              name="anarocContentEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
          <div style={{ marginTop: '15px' }}>
            <Chip label="View File History" style={{ backgroundColor: '#f50057', color: 'white', marginRight: '10px' }} onClick={() => setShow(prev => !prev)} />
            {!show && <Typography variant="caption" color="Inherit" align="center" >
              Environment File: {selectedEnvironment.name} was Created By <strong>{selectedEnvironment.created_by}</strong> On {selectedEnvironment.created_date_time} and Last Updated By <strong>{selectedEnvironment.updated_by}</strong> On {selectedEnvironment.updated_date_time}
            </Typography>}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={liveKarateContent}
              onChange={newContent => {
                setKarateContent(newContent);
              }}
              name="karateContentEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={liveSettingsContent}
              onChange={newContent => {
                setSettingsContent(newContent);
              }}
              name="settingsContentEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
        </TabPanel>
        <ButtonGroup color="primary" className={classes.buttonGroupPosition}>
          <Box className={classes.dropdown} >
            <EnvironmentSelector />
          </Box>
          <SubmitButton />
        </ButtonGroup>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box className={classes.root}>
          <TextField
            id="environmentName"
            label="Environment Name"
            style={{ margin: 12 }}
            width="1000"
            value={environmentName}
            onChange={e => setEnvironmentName(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <ButtonGroup color="primary" style={{}}>
          <EnvironmentSelector />
          <SubmitButton />
        </ButtonGroup>
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="UI Test Data" {...currentTab(0)} />
            <Tab label="API Test Data" {...currentTab(1)} />
            <Tab label="Settings" {...currentTab(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={liveAnorocContent}
              onChange={newContent => {
                setAnorocContent(newContent);
              }}
              name="anarocContentEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
          <div style={{ marginTop: '15px' }}>
            <Chip label="View File History" style={{ backgroundColor: '#f50057', color: 'white', marginRight: '10px' }} onClick={() => setShow(prev => !prev)} />
            {!show && <Typography variant="caption" color="Inherit" align="center" >
              Environment File: {selectedEnvironment.name} was Created By <strong>{selectedEnvironment.created_by}</strong> On {selectedEnvironment.created_date_time} and Last Updated By <strong>{selectedEnvironment.updated_by}</strong> On {selectedEnvironment.updated_date_time}
            </Typography>}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={liveKarateContent}
              onChange={newContent => {
                setKarateContent(newContent);
              }}
              name="karateContentEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={liveSettingsContent}
              onChange={newContent => {
                setSettingsContent(newContent);
              }}
              name="settingsContentEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
        </TabPanel>

      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedEnvironment: getSelectedEnvironment(state),
  applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  updateEnvironment: (
    environment,
    environmentName,
    anarocContent,
    karateContent,
    settingsContent,
    applicationId
  ) => {
    dispatch(
      updateEnvironmentRequest(
        environment.id,
        environmentName,
        anarocContent,
        karateContent,
        settingsContent,
        applicationId
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentTab);
