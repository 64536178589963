import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { getSelectedApplication } from "../../selectors/application/selector";
import { connect } from "react-redux";
import {
  listGlobalObjectRequest
} from "../../thunks/globalObject/thunks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 2px",
    display: "flex",
    alignItems: "center",
    border: '1px solid darkgrey'
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  iconButton: {
    padding: 10,
    color: 'black'
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const GlobalObjectSearch = ({loadGlobalObjectFromServer, applicationId}) => {

  useEffect(() => {
    loadGlobalObjectFromServer("",0, 10,applicationId);
  }, [loadGlobalObjectFromServer,applicationId]);


  const classes = useStyles();

  const onSearchText = (event) => {
    loadGlobalObjectFromServer(event.target.value,0, 10,applicationId);
  };
  

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        {/* <MenuIcon /> */}
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Search GlobalObject"
        inputProps={{ "aria-label": "search GlobalObject" }}
        onChange={onSearchText}
      />
      <IconButton
        // type="submit"
        className={classes.iconButton}
        // aria-label="search"
        // onSearch={onSearch()}
      >

        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
      >
      </IconButton>
    </Paper>
  );
};

const mapStateToProps = state => ({
  applicationId : getSelectedApplication(state).id
 
});

const mapDispatchToProps = dispatch => ({
  loadGlobalObjectFromServer: (searchText, page, size,applicationId) => {
    dispatch(listGlobalObjectRequest(page, size, searchText,applicationId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalObjectSearch);

