export const getFiles = state => {
  return state.files.data;
};

export const getFileCount = state => {
  return state.files.count;
};

export const getSize = state => {
  return state.files.size;
};

export const getPage = state => {
  return state.files.page;
};

export const getSelectedFile = state => {
  return state.files.selectedFile;
};

export const getAllFiles = state => {
  return state.files.allFiles;
};

export const getSelectedFileId = state => {
  return state.files.selectedFileId;
};

export const getSearchFileCount = state => {
  return state.files.searchFileCount;
};