import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddGlobalObject from "./AddGlobalObject";

const useStyles = makeStyles(theme => ({
  font: {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    }
  }
}));

const EmptyGlobalObject = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Box mt={16} ml={2} mr={2} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <img src="/empty_global_object.svg" alt="No Records" width="360" />
      </Box>
      <Box mt={4} ml={2} mr={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      <img src="/empty_global_object.svg" alt="No Records" width="600" />
      </Box>


      <Box mt={2} ml={2}>
        <Typography variant="h6" color="primary" className={classes.font}>
          No Global Objects found. Do you want to add the first Global Object?
        </Typography>
      </Box>
      <Box mt={2}>
        <AddGlobalObject />
      </Box>
    </Grid>
  );
};

export default EmptyGlobalObject;
