import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 2px",
    display: "flex",
    alignItems: "center",
    border: '1px solid #e0e0e0',
    borderRadius: '30px'
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  iconButton: {
    padding: 10,
    color: 'black'
  }
}));

const SearchBar = () => {
const classes = useStyles();
  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ "aria-label": "search feature" }}
        // onChange={onSearchText}
      />
      <IconButton
        // type="submit"
        className={classes.iconButton}
        // onSearch={onSearch()}
      >

        <SearchIcon />
      </IconButton>
    </Paper>
  );
};


export default SearchBar;

