import React, { useEffect } from "react";
import FeatureList from "./FeatureList";
import EmptyFeature from "./EmptyFeature";
import { connect } from "react-redux";
import { getFeatureCount } from "../../selectors/feature/selector";
import { getSelectedApplication } from "../../selectors/application/selector";
import { getFeatureCountRequest } from "../../thunks/feature/thunks";

const Feature = ({ loadCountFromServer, count, applicationId }) => {
  useEffect(() => {
    loadCountFromServer(applicationId);
  }, [loadCountFromServer,applicationId]);

  const featureComponent = () => {
    if (count !== 0) {
      return <FeatureList />;
    }
    return <EmptyFeature />;
  };

  return featureComponent();
};

const mapStateToProps = state => ({
  count: getFeatureCount(state),
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  loadCountFromServer: (applicationId) => {
    dispatch(getFeatureCountRequest(applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Feature);
