import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Redirect } from "react-router-dom";



const KeycloakLogin  = () =>{

    const { keycloak, initialized } = useKeycloak();

    if (initialized && !keycloak.authenticated ) {
        return  keycloak.login();
      }else if (initialized && keycloak.authenticated ) {
        return <Redirect to="/projects" />;
      }
      return "";
    }

export default KeycloakLogin;