import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from "react-redux";
import { deleteEnvironmentRequest } from "../../thunks/environment/thunks";
import { getSelectedApplication } from "../../selectors/application/selector";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = ({ dialogLabel, selectedEnvironmentId, environmentName, deleteSelectedEnvironment, applicationId }) => {
    const handleDialog = () => {
        setOpen(true);
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOk = (selectedEnvironmentId) => {
        deleteSelectedEnvironment(selectedEnvironmentId,applicationId);
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };


    return (
        <div>
            <DeleteOutlineIcon onClick={handleDialog} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancel}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure, you want to delete the {dialogLabel} '{environmentName}' ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClickOk(selectedEnvironmentId)} color="primary" variant="outlined">
                        Ok
                </Button>
                    <Button onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const mapStateToProps = state => ({
    applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
    deleteSelectedEnvironment: (selectedEnvironmentId,applicationId) => {
        dispatch(deleteEnvironmentRequest(selectedEnvironmentId,applicationId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
