import keycloak from "../../keycloak";
import {KEYCLOAK_URL,KEYCLOAK_REALM} from "../../EnvVariables";
import {
  startLoading,
  stopLoading,
  displayMessage,
  listUser,
  listDevUser,
  listAdminUser,
  setSelectedUser
} from "../../actions/common/actions";

export const token = () => {
  return localStorage.getItem("anoroc-token");
};

export const setToken = token => {
  localStorage.setItem("anoroc-token", token);
};

export const clear = () => {
  localStorage.clear();
};

export const setKeycloakJson = keycloak => {
  localStorage.setItem("keycloak-json", JSON.stringify(keycloak));
}

export const getKeyCloakJson = () => {
  return JSON.parse(localStorage.getItem("keycloak-json"));
};


export const READ_WRITE_ROLE = "read_write";
export const READ_ONLY_ROLE = "read_only";
export const DEVELOPER_ROLE = "developer";
export const PROJECT_ADMIN_ROLE = "projectadmin";
const ADMIN_ROLE = "admin";

export const getUserListRequest = () => async dispatch => {
  try {
    dispatch(startLoading());
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/users`, {
    headers: {
      Authorization: "Bearer " + token()
    },
    method: "GET"
  });
  const user = await response.json();
  dispatch(listUser(user));
}catch (e) {
  console.log(e)
  dispatch(
    displayMessage("Could not fetch user list Try the operation again")
  );
  dispatch(stopLoading());
}
};


export const getDeveloperListRequest = (selectedApplication) => async dispatch =>{
  try {
    dispatch(startLoading());
var projectname = selectedApplication;
const allDevelopers = await getAllUsersOfRole(DEVELOPER_ROLE);
const allUsersInProject = await getAllUsersInProject(projectname);
// console.log("developerList ",getListUnion(allDevelopers,allUsersInProject) )
const devUser = getListUnion(allDevelopers,allUsersInProject);
dispatch(listDevUser(devUser));
  }catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch dev user list Try the operation again")
    );
    dispatch(stopLoading());
  }
}

export const getProjectAdminListRequest = (selectedApplication)=> async dispatch =>{
  try {
  dispatch(startLoading());
  var projectname = selectedApplication;
  const allDevelopers = await getAllUsersOfRole(PROJECT_ADMIN_ROLE);
  const allUsersInProject = await getAllUsersInProject(projectname);
  // console.log("project admin list ",getListUnion(allDevelopers,allUsersInProject) )
  const Admuser = getListUnion(allDevelopers,allUsersInProject);
  dispatch(listAdminUser(Admuser));
  }catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch project admin user list Try the operation again")
    );
    dispatch(stopLoading());
  }
  }

  const getListUnion = (allDevelopers,allUsersInProject) =>{
    const developerList =[]
 allUsersInProject.forEach( user =>{
   allDevelopers.forEach(developer =>{
    if(developer.username === user.username)
      developerList.push(user.username)
   })
})
return developerList;
  }

  const getListProjects = (allDevelopers,allUsersInProject) =>{
    const developerList =[]
 allUsersInProject.forEach( user =>{
   allDevelopers.forEach(developer =>{

    if(developer.name === user)
      developerList.push(developer)
   })
})
return developerList;
  }

const getAllUsersOfRole = async(role) =>{
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/roles/${role}/users`, {
    headers: {
      Authorization: "Bearer " + token()
    },
    method: "GET"
  });
  const responceData = await response.json();
  return responceData;
}

const getAllUsersInProject = async(projectname) =>{
  const projectId = await getprojectId(projectname);
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/groups/${projectId}/members`, {
    headers: {
      Authorization: "Bearer " + token()
    },
    method: "GET"
  });
  const responceData = await response.json();
  return responceData;
}

export const checkRole = (role) => {
  var keyCloakJson = getKeyCloakJson();
  if (keyCloakJson) {
    //var resourceRoles = keyCloakJson.resourceAccess[keyCloakJson.clientId].roles;
    var resourceRoles = keyCloakJson.realmAccess.roles;
    return resourceRoles.findIndex(roles => roles === role) > -1;
  }
  return false;

}

export const getApplicationList = (applications) =>{
  var keyCloakJson = getKeyCloakJson();
  if (keyCloakJson) {
    var idTokenParsedProjects = keyCloakJson.idTokenParsed.projects;
    var projects = idTokenParsedProjects.map(s => s.slice(1));
    var projectList =  getListProjects(applications,projects);
    return projectList;
  }else
    return applications;
  
}


export const getListofGroupsforAUser = async(page,size) =>{
  var keyCloakJson = getKeyCloakJson();
  var groups = keyCloakJson.idTokenParsed.projects.map(s => s.slice(1));
  var firstIndex = page*size;
  var lastIndex = Math.min((page*size)+10, groups.length);
  var paginatedGroups = groups.slice(firstIndex,lastIndex)
  
    return paginatedGroups;
}

export const getCountofGroupsforAUser = async() =>{
  var keyCloakJson = getKeyCloakJson();
  const count = keyCloakJson.idTokenParsed.projects.length
  return count;
}

export const deleteProjectKeyCloak = async(ApplicationName) =>{ 
  var projectname = ApplicationName

  try{
    const projectId = await getprojectId(projectname);
  
    const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/groups/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "DELETE"
    });
    if(response.status==204)
      return true;
    else
      return false;
  }catch(e){
    return false
  }

}

export const addOrEditGroupsInKeyCloak = async(name,oldname) =>{
 if(oldname)
   return await updateProject(oldname,name);
 else
   return await createGroup(name);
}

const updateProject = async(oldname,name) =>{
  try{
    const projectId = await getprojectId(oldname);
  const body = {
    name:name
  };
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/groups/${projectId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token()
    },
    method: "PUT",
    body: JSON.stringify(body)
  });
  if(response.status===204)
    return true
  else 
    return false 
}catch(e){

}
}

    

export const createGroup =  async(name) =>{
  const body = {
    name:name
  };
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/groups`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token()
    },
    method: "POST",
    body: JSON.stringify(body)
  });

  if(response.status === 409)
      return false
  else{
      addAdminToGroup(name)
      return true
    }
}

const addAdminToGroup = (name) =>{
  var keyCloakJson = getKeyCloakJson();
  if(keyCloakJson && hasAdminRole && hasReadWriteRole)
    addToProject(name,keyCloakJson.idTokenParsed.preferred_username)
}

export const hasReadWriteRole = () => {
  var keyCloakJson = getKeyCloakJson();
  if (keyCloakJson) {
    var resourceRoles = keyCloakJson.realmAccess.roles;
    return resourceRoles.findIndex(role => role === READ_WRITE_ROLE) > -1;
  }
  return false;
}

export const hasReadOnlyRole = () => {
  var keyCloakJson = getKeyCloakJson();
  if (keyCloakJson) {
    //var resourceRoles = keyCloakJson.resourceAccess[keyCloakJson.clientId].roles;
    var resourceRoles = keyCloakJson.realmAccess.roles;
    return resourceRoles.findIndex(role => role === READ_ONLY_ROLE) > -1;
  }
  return false;
}

export const hasAdminRole = () =>{
 
  var keyCloakJson = getKeyCloakJson();
  if (keyCloakJson) {
    var resourceRoles = keyCloakJson.realmAccess.roles;
    return resourceRoles.findIndex(role => role === ADMIN_ROLE) > -1;
  }
  return false;
}

export const isNotProjectAdmin = () =>{

  if(!checkRole(PROJECT_ADMIN_ROLE))
    return true;
  else
    return false;

}

export const hasProjectAdminRole = () =>{

  if(checkRole(PROJECT_ADMIN_ROLE))
    return true;
  else
    return false;

}

export const loginwithloginpage = async() =>{
  const body = {
    client_id : 'anoroc-app',
    username : 'admin',
    password : 'admin',
    grant_type : "password"
  };
  const response = await fetch(`http://localhost:9090/auth/realms/master/protocol/openid-connect/token`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: "POST",
    body: new URLSearchParams(body)
  });
  const response1 = await response.json();

  console.log(" response 1 is ",response1.refresh_token)

  const body2 = {
    client_id:'anoroc-app',
    client_secret:'dd479b8f-ef42-4cb4-8b87-d337cf938d6c',
    refresh_token:response1.refresh_token,
    grant_type:"refresh_token"
  };

  const response2 = await fetch(`http://localhost:9090/auth/realms/master/protocol/openid-connect/token`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: "POST",
    body: new URLSearchParams(body2)
  });

  const response3 = await response2.json();
  console.log(" response 2 ",response3)

  console.log("keycloak.authenticated ",keycloak.authenticated)

  console.log("keycloak json ",JSON.stringify(keycloak))
  setToken(response3.access_token)

}

export const removeuser = (selectedUser, selectedApplicationName)=> async dispatch =>{

try{
  dispatch(startLoading());
  const userId = await getUserId(selectedUser);
  const projectId = await getprojectId(selectedApplicationName);

  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/users/${userId}/groups/${projectId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token()
    },
    method: "DELETE"
  });
  if (response.status === 204) {
    dispatch(displayMessage("User deleted successfully."));
    dispatch(getProjectAdminListRequest(selectedApplicationName));
    dispatch(getDeveloperListRequest(selectedApplicationName));
  }
  else{
    dispatch(
      displayMessage("Could not delete the User. Try the operation again.")
    );
  }
}catch(e){
  dispatch(
    displayMessage("Could not delete the User. Try the operation again")
  );
  dispatch(stopLoading());
}


}
export const assignUser = (username,role,selectedApplication) => async dispatch =>{

  try{
    dispatch(startLoading());

    if(! await getUserId(username)){
      dispatch(displayMessage("UserName is Invalid "))
    }
    else{
        if(role === DEVELOPER_ROLE)
          addDeveloper(username)
        else
          addProjectAdmin(username)

        addToProject(selectedApplication,username)
        dispatch(displayMessage("Role Assigned To User"))
    }

    dispatch(stopLoading());
  }catch(e){
    dispatch(displayMessage("Error While Assigning Role "))
  }

}

const addToProject = async(projectname,username) =>{

  try{
    const userId = await getUserId(username);
    const projectId = await getprojectId(projectname);
  const body = {
    realm:KEYCLOAK_REALM,
    userId:userId,
    groupId:projectId
  };
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/users/${userId}/groups/${projectId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token()
    },
    method: "PUT",
    body: JSON.stringify(body)
  });
}catch(e){
  return false
  }
}

const addDeveloper = async(username) =>{
  try{
    const readWriteID = await getRoleId(READ_WRITE_ROLE);
    const developerID = await getRoleId(DEVELOPER_ROLE);
    const userId = await getUserId(username);
    
  const body = [{
    id:developerID,
    name:DEVELOPER_ROLE
  },{
    id:readWriteID,
    name:READ_WRITE_ROLE
  }
];
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/users/${userId}/role-mappings/realm`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token()
    },
    method: "POST",
    body: JSON.stringify(body)
  });
}catch(e){
  return false
}
}

const addProjectAdmin = async(username) =>{
  try{
    const readWriteID = await getRoleId(READ_WRITE_ROLE);
    const projectadminID = await getRoleId(PROJECT_ADMIN_ROLE);
    const userId = await getUserId(username);
  
  const body = [{
    id:readWriteID,
    name:READ_WRITE_ROLE
  },
  {
    id:projectadminID,
    name:PROJECT_ADMIN_ROLE
  }
];
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/users/${userId}/role-mappings/realm`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token()
    },
    method: "POST",
    body: JSON.stringify(body)
  });
}catch(e){
 return false
}
}

const getRoleId = async(role) =>{

  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/roles/${role}`, {
    headers: {
      Authorization: "Bearer " + token()
    },
    method: "GET"
  });

  const roleResponse = await response.json();
  return roleResponse.id;
}

const getUserId = async(username) =>{
  try{
  const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/users?username=${username}`, {
    headers: {
      Authorization: "Bearer " + token()
    },
    method: "GET"
  });
  const userResponse = await response.json();
  return userResponse[0].id;
}catch(e){
  return false
}
}

export const getprojectId = async(projectname) =>{
  var projectId
  try{
    const response = await fetch(`${KEYCLOAK_URL}/admin/realms/master/groups`, {
      headers: {
        Authorization: "Bearer " + token()
      },
      method: "GET"
    });
    const groups = await response.json();
    groups.forEach(group =>{
      if(group.name === projectname){
        projectId = group.id;
      }
    })
    return projectId;
  }catch(e){
    return false
  }
}

export const setSelectedUserRequest = user => async dispatch => {
  dispatch(setSelectedUser(user));
};