import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { updateBusinessScenarioRequest } from "../../thunks/businessScenario/thunks";
import { runBusinessScenarioRequest } from "../../thunks/run/thunks";
import { getSelectedBusinessScenario } from "../../selectors/businessScenario/selector";
import { getSelectedEnvironmentId } from "../../selectors/environment/selector";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import AddFeature from "./AddFeature";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RunTestPlan from './RunTestPlan'
import AddScheduler from './AddSchedule'
import { map } from "lodash";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { deepPurple, grey } from "@material-ui/core/colors";
import { hasReadOnlyRole } from '../../thunks/common/auth'
import { getSelectedApplication } from "../../selectors/application/selector";
import SpeedIcon from '@material-ui/icons/Speed';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { listSchedule } from "../../actions/common/actions";
import { getSchedulerListRequest } from "../../thunks/businessScenario/thunks";
import { getSchedule } from "../../selectors/common/selector";
import AlertDialog from './SchedulerDeleteDialog';
import EditSchedule from './EditSchedule';
import { hasReadWriteRole, hasAdminRole, hasProjectAdminRole } from '../../thunks/common/auth';
import cronstrue from 'cronstrue';


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  buttonGroupPosition: {
    position: "absolute",
    top: theme.spacing(10),
    right: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  brown: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

const BusinessScenariosTab = ({
  updateBusinessScenario,
  selectedBusinessScenario,
  applicationId,
  scheduleList = [],
  getScheduleList,
}) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (selectedBusinessScenario) {
      setName(selectedBusinessScenario.name);
      setDescription(selectedBusinessScenario.description);
      getScheduleList(selectedBusinessScenario.id);
    }
  }, [selectedBusinessScenario]);

  const classes = useStyles();
  var nameLength = name.length > 3 && name.length < 21;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentTab = index => {
    return {
      id: `feature-tab-${index}`
    };
  };

  function ListOfSchedules() {
    return scheduleList
  }

  const TabPanel = props => {
    const { children, value, index } = props;

    return (
      <Typography hidden={value !== index} id={`feature-${index}`}>
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  };

  function SubmitButton() {
    if (nameLength && description) {
      return <Button
        color="primary"
        startIcon={<SaveIcon />}
        variant="contained"
        className={classes.button}
        onClick={() => {
          updateBusinessScenario(selectedBusinessScenario, name, description, applicationId);
        }}
        disabled={hasReadOnlyRole()}
      >
        Update
      </Button>

    } else {
      return <Button
        color="primary"
        startIcon={<SaveIcon />}
        variant="contained"
        className={classes.button}
        onClick={() => {
          updateBusinessScenario(selectedBusinessScenario, name, description, applicationId);
        }}
        disabled
      >
        Update
      </Button>

    };
  };




  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }} >
        <Box className={classes.root}>
          <TextField
            id="businessScenariosName"
            label="Name"
            style={{ margin: 12 }}
            fullWidth
            value={name}
            error={!nameLength}
            onChange={e => setName(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="businessScenariosDescription"
            multiline
            rows={4}
            variant="outlined"
            label="Description"
            style={{ margin: 12 }}
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <ButtonGroup color="primary" className={classes.buttonGroupPosition}>
          <AddFeature />
          <SubmitButton />
          <RunTestPlan />
          <AddScheduler />
        </ButtonGroup>
        {selectedBusinessScenario &&
          selectedBusinessScenario.features.length === 0 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box mt={8} ml={2} mr={2}>
                <img
                  src="/empty-features-in-bs.svg"
                  alt="No Records"
                  width="600"
                />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  No features associated to this business scenario.
                </Typography>
              </Box>
            </Grid>
          )}

        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Feature" {...currentTab(0)} />
            <Tab label="Schedules" {...currentTab(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Box>
            <Grid>
              <Box mt={2} mr={2} ml={2}>
                <Typography color="primary">
                  {selectedBusinessScenario &&
                    selectedBusinessScenario.features.length}{" "}
                  Associated Features
                </Typography>
                <Box mt={2} mb={2}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Order</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Application</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedBusinessScenario &&
                          map(
                            selectedBusinessScenario.features,
                            (feature, index) => {
                              return (
                                <TableRow key={feature.id} hover>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    {feature.featureType === "ANOROC" && (
                                      <Avatar className={classes.purple}>
                                        <AcUnitIcon />
                                      </Avatar>
                                    )}
                                    {feature.featureType === "KARATE" && (
                                      <Avatar className={classes.brown}>
                                        <SportsKabaddiIcon />
                                      </Avatar>
                                    )}
                                    {feature.featureType === "PERFORMANCE" && (
                                      <Avatar className={classes.brown}>
                                        <SpeedIcon />
                                      </Avatar>
                                    )}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {feature.name}
                                  </TableCell>
                                  <TableCell>
                                    <Chip label={feature.application.name} style={{ backgroundColor: '#f50057', color: 'white' }} />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {scheduleList.length === 0 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box mt={8} ml={2} mr={2}>
                <img
                  src="/empty-features-in-bs.svg"
                  alt="No Records"
                  width="600"
                />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  No Schedules Created.
                </Typography>
              </Box>
            </Grid>
          )}
          <Box>
            <Grid>
              {scheduleList.length !== 0 && (
                <Box>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>History</TableCell>
                          <TableCell>Schedule Name</TableCell>
                          <TableCell>Info</TableCell>
                          <TableCell>Modify</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedBusinessScenario &&
                          map(ListOfSchedules(), (schedule) => {
                            return (
                              <TableRow key={schedule.id} hover>

                                <TableCell component="th" scope="row">
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <Typography gutterBottom>
                                      {schedule.updated_by}
                                    </Typography>
                                    <Typography gutterBottom>
                                      {schedule.updated_date_time}
                                    </Typography>
                                  </Box>

                                </TableCell>

                                <TableCell component="th" scope="row">
                                  {schedule.name}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <Typography gutterBottom>
                                      Schedule id : {schedule.id}
                                    </Typography>
                                    <Typography gutterBottom>                           
                                      Cron Text : <b>{cronstrue.toString(schedule.croneExpression)}</b> 
                                    </Typography>

									                  <Typography gutterBottom>
                                      Environment Name : {schedule.environment.name}
                                    </Typography>

                                    <Typography gutterBottom>
                                      Run Tags : {schedule.scenarioTags}
                                    </Typography>
                                  </Box>
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                                    <Grid item xs={12} sm={1}>
                                      <EditSchedule dialogLabel='schedule' selectedSchedule={schedule} />
                                    </Grid>
                                  )}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                  {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                                    <Grid item xs={12} sm={1}>
                                      <AlertDialog dialogLabel='schedule' selectedScheduleName={schedule.name} selectedScheduleId={schedule.id} />
                                    </Grid>
                                  )}
                                </TableCell>

                              </TableRow>
                            );
                          }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Grid>
          </Box>
        </TabPanel>

      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }} >
        <Box className={classes.root}>
          <TextField
            id="businessScenariosName"
            label="Name"
            style={{ margin: 12 }}
            fullWidth
            value={name}
            error={!nameLength}
            helperText={name !== "" ? "Test Plan name should be between 3-20 character" : " "}
            onChange={e => setName(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="businessScenariosDescription"
            multiline
            rows={4}
            variant="outlined"
            label="Description"
            style={{ margin: 12 }}
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <ButtonGroup color="primary">
          <AddFeature />
          <Button
            color="primary"
            startIcon={<SaveIcon />}
            variant="contained"
            className={classes.button}
            onClick={() => {
              updateBusinessScenario(selectedBusinessScenario, name, description, applicationId);
            }}
            disabled={hasReadOnlyRole()}
          >
            Update
          </Button>
          <RunTestPlan />
        </ButtonGroup>
        {selectedBusinessScenario &&
          selectedBusinessScenario.features.length === 0 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box mt={8} ml={2} mr={2}>
                <img
                  src="/empty-features-in-bs.svg"
                  alt="No Records"
                  width="300"
                />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  No features associated to this business scenario.
                </Typography>
              </Box>
            </Grid>
          )}

        <Box>
          <Grid>
            <Box mt={2} mr={2} ml={2}>
              <Typography color="primary">
                {selectedBusinessScenario &&
                  selectedBusinessScenario.features.length}{" "}
                Associated Features
              </Typography>
              <Box mt={2} mb={2}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Application</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedBusinessScenario &&
                        map(
                          selectedBusinessScenario.features,
                          (feature, index) => {
                            return (
                              <TableRow key={feature.id} hover>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {feature.featureType === "ANOROC" && (
                                    <Avatar className={classes.purple}>
                                      <AcUnitIcon />
                                    </Avatar>
                                  )}
                                  {feature.featureType === "KARATE" && (
                                    <Avatar className={classes.brown}>
                                      <SportsKabaddiIcon />
                                    </Avatar>
                                  )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {feature.name}
                                </TableCell>
                                <TableCell>
                                  <Chip label={feature.application.name} style={{ backgroundColor: '#f50057', color: 'white' }} />
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedBusinessScenario: getSelectedBusinessScenario(state),
  selectedEnvironmentId: getSelectedEnvironmentId(state),
  applicationId: getSelectedApplication(state).id,
  scheduleList: getSchedule(state)
});

const mapDispatchToProps = dispatch => ({
  updateBusinessScenario: (businessScenarios, name, description, applicationId) => {
    let featureIds = businessScenarios.features.map(feature => feature.id);
    dispatch(
      updateBusinessScenarioRequest(
        businessScenarios.id,
        name,
        description,
        featureIds,
        applicationId
      )
    );
  },
  runBusinessScenario: (businessScenarios, selectedEnvironmentId, applicationId) => {
    dispatch(
      runBusinessScenarioRequest(businessScenarios.id, selectedEnvironmentId, applicationId)
    );
  },

  getScheduleList: (businessScenarioId) => {
    dispatch(
      getSchedulerListRequest(businessScenarioId)
    );
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessScenariosTab);
