import { BASE_URL } from "../../EnvVariables";
import { REPORT_URL} from "../../EnvVariables";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import {
  runFeature,
  listRuns,
  listAllRuns,
  setPage,
  setSize,
  setSelectedRun,
  setRunCount
} from "../../actions/run/actions";
import { token } from "../common/auth";
import {getSchedulerListRequest} from "../businessScenario/thunks"


export const runFeatureRequest = (
  featureId,
  selectedEnvironmentId,
  applicationId,
  runScenarioTags
) => async dispatch => {
  try {
    dispatch(startLoading());
    if (selectedEnvironmentId) {
      const body = {
        feature_id: featureId,
        env_id: selectedEnvironmentId,
        application_id: applicationId,
        run_scenariotags: runScenarioTags
      };
      const response = await fetch(`${BASE_URL}/api/run/feature`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "post",
        body: JSON.stringify(body)
      });
      const runReponse = await response.json();
      dispatch(
        displayMessage(
          "Run initiated successfully. Triggered Run ID : " + runReponse.id
        )
      );
      dispatch(runFeature(runReponse));
    } else {
      dispatch(
        displayMessage(
          "Please select environment details before running the feature."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not intiate the run for the feature. Please try again later."
      )
    );
    dispatch(stopLoading());
  }
};

export const runBusinessScenarioRequest = (
  bussinessScenarioId,
  selectedEnvironmentId,
  applicationId,
  run_scenariotags
) => async dispatch => {
  try {
    dispatch(startLoading());
    if (selectedEnvironmentId) {
      const body = {
        business_scenario_id: bussinessScenarioId,
        env_id: selectedEnvironmentId,
        application_id: applicationId,
        run_scenariotags: run_scenariotags
      };

      const response = await fetch(`${BASE_URL}/api/run/businessScenario`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "post",
        body: JSON.stringify(body)
      });
      const runReponse = await response.json();
      dispatch(
        displayMessage(
          "Run initiated successfully. Please check the runs for more details. Triggered Run ID : " + runReponse.id
        )
      );
      dispatch(runFeature(runReponse));
    } else {
      dispatch(
        displayMessage(
          "Please select environment details from the before running the business scenario."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not intiate the run for the business scenario. Please try again later."
      )
    );
    dispatch(stopLoading());
  }
};

export const addOrEditScheduleRequest = (
  id,
  bussinessScenarioId,
  selectedEnvironmentId,
  applicationId,
  scheduleName,
  croneExpression,
  run_scenariotags
) => async dispatch => {
  try {
    dispatch(startLoading());
    if (selectedEnvironmentId) {
      const body = {
        id: id,
        business_scenario_id: bussinessScenarioId,
        env_id: selectedEnvironmentId,
        application_id: applicationId,
        name: scheduleName,
        croneExpression: croneExpression,
        run_scenariotags: run_scenariotags
      };

      const response = await fetch(`${BASE_URL}/api/schedule/businessScenario`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "post",
        body: JSON.stringify(body)
      });
      const runReponse = await response.json();

      if (response.status === 200) {
        dispatch(
          displayMessage(
            "Schedule saved successfully."
          )
        );
        dispatch(getSchedulerListRequest(bussinessScenarioId))
      } else {
        dispatch(
          displayMessage(
            runReponse.message
          )
        );
      }

      dispatch(runFeature(runReponse));
    } else {
      dispatch(
        displayMessage(
          "Please select environment details from the before running the test plan."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not add the schedule for the test plan. Please try again later."
      )
    );
    dispatch(stopLoading());
  }
};

export const listRunRequest = (
  page = 0,
  size = 10,
  applicationId = 0
) => async dispatch => {
  try {
    dispatch(startLoading());
    const runCountResponse = await fetch(`${BASE_URL}/api/run/count?applicationId=${applicationId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "get"
    });
    const runCount = await runCountResponse.json();
    dispatch(setRunCount(runCount.count));
    dispatch(setPage(page));
    dispatch(setSize(size));
    const response = await fetch(
      `${BASE_URL}/api/run/list?page=${page}&size=${size}&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const runs = await response.json();
    dispatch(listRuns(runs));
    if (runs.length > 0) {
      dispatch(setSelectedRun(runs[0]));
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the runs. Try the operation again")
    );
    dispatch(stopLoading());
  }
};


export const listallRunRequest = (
  applicationId = 0
) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await fetch(
      `${BASE_URL}/api/run/listAllRuns?applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const runs = await response.json();
    dispatch(listAllRuns(runs));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the runs. Try the operation again")
    );
    dispatch(stopLoading());
  }
};


export const deleteFlagUpdateRequest = (
  runId,
  flag
) => async dispatch => {
  try {
    dispatch(startLoading());

    const response = await fetch(`${BASE_URL}/api/run/deleteFlag?flagRun=${flag}&runId=${runId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "put"
    });
    const runReponse = await response.json();
    dispatch(
      displayMessage("Delete flag updated successfully.")
    );
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not update delete flag. Please try again later."
      )
    );
    dispatch(stopLoading());
  }
};



export const zipReportFolder = (
  runId
) => async dispatch => {
  try {
    dispatch(startLoading());

    const response = await fetch(`${BASE_URL}/api/run/zipFolder?runId=${runId}`, {
          headers: {
            "Content-Type": "application/json", 
            Authorization: "Bearer " + token()
          },
          method: "get"
        });
   
    if (response.status === 200){
      const aTag=document.createElement("a");
      aTag.href=REPORT_URL+runId+".zip";
      aTag.setAttribute("download", runId+".zip");
      aTag.setAttribute("target","_blank")
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove(); 
    dispatch(
      displayMessage("Reports zipped successfully.")
    );
    dispatch(stopLoading());
  }

  else{
    dispatch(
      displayMessage(
        "Could not zip the file. Please try again later."
      )
    );
  }

  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not zip the file. Please try again later."
      )
    );
    dispatch(stopLoading());
  }
};


export const setSelectedRunRequest = run => async dispatch => {
  dispatch(setSelectedRun(run));
};
