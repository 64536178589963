import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from '@material-ui/icons/FilterList';
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { listFeatureRequest } from "../../thunks/feature/thunks.js";
import { getAllApplicationsRequest } from "../../thunks/application/thunks.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { map } from "lodash";
import { getSearchParameters } from "../../selectors/feature/selector.js";
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2)
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FeatureSearch = ({
  onFilterApply,
  searchParameters,
}) => {
  const [open, setOpen] = React.useState(false);
  const [scriptType, setScriptTypeValue] = React.useState("");
  const [applications, setApplications] = React.useState("");
  const [selectedFeatureType, setSelectedFeatureType] = React.useState([]);
  const [selectedApplications, setSelectedApplications] = React.useState([]);

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedFeatureType(event.target.value);
  };

  const handleApplicationChange = (event) => {
    setSelectedApplications(event.target.value);
  };

  const handleClickOpen = () => {

    setOpen(true);
    console.log("in advance filter -> "+JSON.stringify(searchParameters));
    const featureTypes = "Test";
    let featureTypesArray = [];
    Object.keys(featureTypes).forEach(function(key){
      var value = featureTypes[key];
      const item = {id: key, name: key + ' ( ' + value+' )'}
      featureTypesArray.push(item);
      console.log(key + ':' + value);
  });
    setScriptTypeValue(featureTypesArray);

    const applicationNames = "App";
    let applications = [];
    Object.keys(applicationNames).forEach(function(key){
      var value = applicationNames[key];
      const item = {id: key,name: key + ' ( ' + value+' )'}
      applications.push(item);
      console.log(key + ':' + value);
  });
  setApplications(applications);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Box>
      <ToolTip title="Advanced filter">
        <FilterListIcon fontSize={"medium"}
        onClick={handleClickOpen}/>
      </ToolTip>
      <Dialog
        fullWidth
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Advanced filter</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Select below filters</DialogContentText> */}
          <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="script-select-label">
                Select Feature Types
              </InputLabel>
              <Select
                // labelId="source-script-type"
                // id="source-script-type"
                labelId="script-mutiple-checkbox-label"
                id="script-mutiple-checkbox"
                multiple
                value= {selectedFeatureType}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                // renderValue={(selected) => selected.join(', ')}
                onChange={handleChange}
                MenuProps={MenuProps}
                >
                {map(scriptType, (type, index) => (
                   <MenuItem key={type.id} value={type.id}>
                   <Checkbox checked={selectedFeatureType.indexOf(type.id) > -1}/>
                   <ListItemText primary={type.name} />
                 </MenuItem>
                  // <MenuItem value={type.id}>{type.value}</MenuItem>
                ))}
              
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="application-select-label">Select Applications</InputLabel>
              <Select
                 labelId="application-mutiple-checkbox-label"
                 id="application-mutiple-checkbox"
                multiple
                value= {selectedApplications}
                input={<Input />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                // renderValue={(selected) => selected.join(', ')}
                onChange={handleApplicationChange}
                MenuProps={MenuProps}
                >
                {map(applications, (application, index) => (
                   <MenuItem key={application.id} value={application.id}>
                   <Checkbox checked={selectedApplications.indexOf(application.id) > -1}/>
                   <ListItemText primary={application.name} />
                 </MenuItem>
                  // <MenuItem value={type.id}>{type.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions mt={30} md={30}>
          <Button
            onClick={() => {
              // onFilterApply(selectedApplications, selectedFeatureType, searchParameters);
              handleClose();
            }}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>
          <Button 
          onClick={handleClose} 
          color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  searchParameters: getSearchParameters(state)
});

const mapDispatchToProps = dispatch => ({
  onFilterApply: (selectedApplications, selectedFeatureType, searchParameters) => {
    let facetFilter = [];
    selectedApplications.map((value) => {
      let item = 
        'applicationName:'+ value;
      
      facetFilter.push(item)
    })
    selectedFeatureType.map((value) => {
      let item = 
        'featureType:' + value;
      facetFilter.push(item)
    })
    dispatch(listFeatureRequest(0,10,searchParameters.query, facetFilter));
  },
  loadAllApplicationsFromServer: () => {
    dispatch(getAllApplicationsRequest());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSearch);
