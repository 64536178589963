export const loading = state => {
  return state.loading;
};

export const getMessage = state => {
  return state.message;
};

export const getCreatedBy = keycloak => {
  return keycloak.tokenParsed.preferred_username;
};

export const getUpdatedby = keycloak => {
  return keycloak.tokenParsed.preferred_username;
};

export const getUserName = (keycloak) => {
  return keycloak.tokenParsed.preferred_username;
}

export const getUser = state => {
  return state.user;
};

export const getSchedule = state => {
  return state.schedule.data;
};


export const getProjAdminUser = state => {
  return state.user.admdata;
};

export const getDevUser = state => {
  return state.user.devdata;
};

export const getSelectedUser = state => {
  return state.user.selectedUser;
};
