import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SaveIcon from "@material-ui/icons/Save";
import { connect } from "react-redux";
import { saveFileRequest } from "../../thunks/supportiveFiles/thunks";
import { getSelectedFile } from "../../selectors/file/selector";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-gherkin";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/gherkin";
import TextField from "@material-ui/core/TextField";
import { hasReadOnlyRole } from '../../thunks/common/auth';
import { getSelectedApplication } from "../../selectors/application/selector";
import ReplaceFile from "./ReplaceFile";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  buttonGroupPosition: {
    position: "absolute",
    top: theme.spacing(10),
    right: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  }
}));

const TabPanel = props => {
  const { children, value, index } = props;

  return (
    <Typography hidden={value !== index} id={`file-${index}`}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const currentTab = index => {
  return {
    id: `file-tab-${index}`
  };
};

const FileTab = ({ replaceFile, selectedFile }) => {
  const [value, setValue] = React.useState(0);
  const [show, setShow] = React.useState('true');


  const classes = useStyles();

  function SubmitButton() {
    return <Button
      color="primary"
      startIcon={<SaveIcon />}
      variant="contained"
      className={classes.button}
      onClick={() => {
        replaceFile(
          selectedFile,
          selectedFile.
          selectedFile.application_id
        );
      }}
      disabled={hasReadOnlyRole()}>Replace</Button>
  };

  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }} >
        <ButtonGroup color="primary" className={classes.buttonGroupPosition}>
        </ButtonGroup>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <ButtonGroup color="primary" className={classes.buttonGroupPosition}>
        </ButtonGroup>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedFile: getSelectedFile(state),
  applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  replaceFile: (
    file,
    applicationId
  ) => {
    dispatch(
      saveFileRequest(
        file.id,
        file,
        applicationId
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FileTab);
