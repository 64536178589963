import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FileTab from "./FileTab";
import TablePagination from "@material-ui/core/TablePagination";
import { connect } from "react-redux";
import {
  listFileRequest,
  setSelectedFileRequest
} from "../../thunks/supportiveFiles/thunks";
import AddFile from "./AddFile";
import ReplaceFileDialog from "./ReplaceFile";
import { map } from "lodash";
import {
  getFiles,
  getSearchFileCount,
  getPage,
  getSize
} from "../../selectors/file/selector";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import DisplayDate from "../common/DisplayDate";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import Chip from "@material-ui/core/Chip";
import AlertDialog from './FileDialog'
import { getSelectedApplication } from "../../selectors/application/selector";
import { hasReadWriteRole, hasAdminRole, hasProjectAdminRole } from '../../thunks/common/auth';
import FileSearch from "./FileSearchBar";
import DownloadFileDialog from "./DownloadFile";

const FileList = ({
  loadFileFromServer,
  setSelectedFile,
  files = [],
  count = -1,
  page = 0,
  size = 10,
  applicationId,
  applicationName
}) => {
  useEffect(() => {
    loadFileFromServer(0, size, applicationId);
  }, [loadFileFromServer, size, applicationId]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [fileSelected, setFileSelected] = React.useState({});

  const handleListItemClick = index => {
    setSelectedIndex(index);
    setFileSelected(files[index]);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    purple: {
      backgroundColor: '#3f51b5'
    }
  }));

  const classes = useStyles();

  const onChangePage = (event, pageSelected) => {
    loadFileFromServer(pageSelected, size,applicationId);
    setSelectedIndex(0);
  };

  return (
    <div>
    <Box sx={{  display: { xs: 'none', md: 'block' } }}>
      <Grid container>
        <Grid item xs={4}>
          <Box mt={1} mr={2}>
          <div style={{ marginTop: '30px' }}> <FileSearch /></div>
            <List className={classes.root} component="nav">
              {map(files, (file, index) => (
                <ListItem
                  divider
                  button
                  selected={selectedIndex === index}
                  onClick={event => {
                    handleListItemClick(index);
                    setSelectedFile(files[index]);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.purple}>
                      <ImportantDevicesIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    secondary={
                      <DisplayDate
                        dateString={file.created_date_time}
                        prefixString="Created on "
                      />}
                  />
                   <Grid item xs={12} sm={3} style={{paddingRight: '140px'}}>
                          <Chip label={ applicationName} style={{backgroundColor: '#f50057', color: 'white'}}/>
                        </Grid>

                  <Grid item xs={12} sm={2}>
                      <DownloadFileDialog dialogLabel='file' selectedFileId={fileSelected.id} fileName={fileSelected.name} />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                      <ReplaceFileDialog dialogLabel='file' selectedFileId={fileSelected.id} fileName={fileSelected.name} />
                  </Grid>
               {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                  <Grid item xs={12} sm={1}>
                      <AlertDialog dialogLabel='file' selectedFileId={fileSelected.id} fileName={fileSelected.name} />
                  </Grid>
                  )}
                  </ListItem>
              ))}
            </List>
            {count > 0 && (
              <Box display="flex" flexDirection="row-reverse">
                <AddFile />
                <TablePagination
                  rowsPerPageOptions={[size]}
                  component="div"
                  count={count}
                  rowsPerPage={size}
                  page={page}
                  onChangePage={onChangePage}
                />
              </Box>
            )}
            
{count === 0 && (
                <Box display="flex" justifyContent="space-evenly" style={{ color: '#f50057', fontSize: "large" }}>
                  No Search Results found. Please clear search box and try again !!!
                </Box>
              )}

          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box mt={2} mr={2}>
            {count > 0 && <FileTab />}
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box sx={{  display: { xs: 'block', md: 'none' } }}>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={1} mr={2}>
            <List  component="nav">
              {map(files, (file, index) => (               
                <ListItem
                  divider
                  button
                  selected={selectedIndex === index}
                  onClick={event => {
                    handleListItemClick(index);
                    setSelectedFile(files[index]);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.purple}>
                      <ImportantDevicesIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Box><Typography variant="body2"  style={{ width: '400px' }}>{file.name}</Typography></Box>}
                    secondary={<Typography variant="body2" color="textSecondary" style={{ width: '400px' }}> <DisplayDate
                       dateString={file.created_date_time} />
                      </Typography>}
                  />
                   <Grid item xs={12} style={{marginLeft: '200px', marginRight: '20px'}}>
                          {/* <Chip label={ applicationName} style={{backgroundColor: '#f50057', color: 'white'}}/> */}
                        </Grid>
               {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                  <Grid item xs={12} sm={1}>
                      <AlertDialog dialogLabel='file' selectedFileId={fileSelected.id} fileName={fileSelected.name} />
                  </Grid>
                  )}
                  </ListItem>
              ))}
            </List>
            {count > 0 && (
              <Box display="flex" flexDirection="row-reverse">
                <AddFile />
                <TablePagination
                  rowsPerPageOptions={[size]}
                  component="div"
                  count={count}
                  rowsPerPage={size}
                  page={page}
                  onChangePage={onChangePage}
                />
              </Box>
            )}

{count === 0 && (
                <Box display="flex" justifyContent="space-evenly" style={{ color: '#f50057', fontSize: "large" }}>
                  No Search Results found. Please clear search box and try again !!!
                </Box>
              )}

          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mr={2}>
            {count > 0 && <FileTab />}
          </Box>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  files: getFiles(state),
  count: getSearchFileCount(state),
  page: getPage(state),
  size: getSize(state),
  applicationId : getSelectedApplication(state).id,
  applicationName: getSelectedApplication(state).name
});

const mapDispatchToProps = dispatch => ({
  loadFileFromServer: (page, size, applicationId) => {
    dispatch(listFileRequest(page, size, "",applicationId));
  },
  setSelectedFile: file => {
    dispatch(setSelectedFileRequest(file));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
