import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import ScheduleIcon from '@material-ui/icons/Timer';
import DialogTitle from "@material-ui/core/DialogTitle";
import { getSelectedBusinessScenario } from "../../selectors/businessScenario/selector";
import { getSelectedEnvironmentId } from "../../selectors/environment/selector";
import { getSelectedApplication } from "../../selectors/application/selector";
import { addOrEditScheduleRequest } from "../../thunks/run/thunks";
import { getSchedule } from "../../selectors/common/selector";
import { getSchedulerListRequest } from "../../thunks/businessScenario/thunks";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import EnvironmentSelector from "../menu/EnvironmentSelector"
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { map } from "lodash";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CronePicker from '../businessScenario/CronPicker';
import AlertDialog from './SchedulerDeleteDialog';
import { hasReadWriteRole, hasAdminRole, hasProjectAdminRole } from '../../thunks/common/auth';
import cronstrue from 'cronstrue';
import ToolTip from "@material-ui/core/Tooltip";

const AddSchedule = ({
  addSchedular,
  applicationId,
  selectedEnvironmentId,
  selectedBusinessScenario
}) => {


  const [open, setOpen] = React.useState(false);
  const [scheduleName, setScheduleName] = React.useState("");
  const [croneExpression, setCroneExpression] = React.useState("");
  const [tagname, setTagValue] = React.useState("");
  const [dense, setDense] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setScheduleName("");
    setCroneExpression("");
    setTagValue("");
  };
  let isValidCron = true;
  const getReadableCronText = () => {
    try {
  
      if(croneExpression===''){
        isValidCron = false;
        return '';
      }
     else if(croneExpression.split(' ').length === 5 || croneExpression.split(' ').length === 6  ){
        return cronstrue.toString(croneExpression);
        } 
     else{
      isValidCron = false;
      return 'Invalid Cron Expression';
     }
    } catch (error) {
      isValidCron = false;
      return 'Invalid Cron Expression';
       
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  var nameLength = scheduleName.length > 3 && scheduleName.length < 16;
  //var croneExpressionLen = croneExpression.split(' ').length === 5 || croneExpression.split(' ').length === 6;

  function SubmitButton() {
    if (nameLength && isValidCron) {
      return <Button onClick={() => {
        addSchedular(selectedBusinessScenario, selectedEnvironmentId, applicationId, scheduleName, croneExpression, tagname);
        handleClose();
      }}
        color="primary"
        variant="contained">Add Schedule</Button>
    } else {
      return <Button onClick={() => {
        handleClose();
      }}
        color="primary"
        disabled
        variant="contained">Add Schedule</Button>
    };
  };



  return (
    <Box>
      {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
      <Avatar style={{ backgroundColor: '#3f51b5', marginTop: '14px', marginRight: '15px' }}>
        <ToolTip title="Add Schedule">
        <Button style={{ color: 'white' }}> <ScheduleIcon onClick={handleClickOpen} /></Button>
        </ToolTip>
      </Avatar>
      )}
      <Dialog
        maxWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add run schedule</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Enter Schedule Name, Cron Expresssion, Environment and click on add button <br/>
            to configure new test run schedule.
          </DialogContentText>

          <Box mb={3}>
            <TextField
              autoFocus
              required
              autoComplete="off"
              margin="dense"
              id="standard-basic"
              label="Schedule Name"
              value={scheduleName}
              style={{ width: '250px' }}
              error={!nameLength}
              helperText={scheduleName !== "" ? "Schedule name should be between 3-15 character" : " "}
              onChange={e => setScheduleName(e.target.value)}
              fullWidth

            />
          </Box>

          <Box mb={3}>
            <TextField
              id="standard-expression"
              autoComplete="off"
              label="Crone Expression"
              fullWidth
              style={{ width: '250px' }}
              value={croneExpression}
             // error={!croneExpressionLen}
              onChange={e => setCroneExpression(e.target.value)}
              helperText ="Enter 5 digit or 6 digit crone Expression"
              required
            />
             <div>Cron Text:<b> {getReadableCronText()}</b></div>
          </Box>

          {/* <Box mb={2}>
            <TextField
              id="datetime-local"
              label="Add run schedule"
              type="datetime-local"
              defaultValue={currentDateTime}
              style={{ marginTop: '20px' }}
              onChange={e => setDateValue(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box> */}

          <EnvironmentSelector />
          {/* <CronePicker>
            
          </CronePicker> */}


          <Box mb={3}>
            <TextField
              id="standard-basic"
              autoComplete="off"
              label="Tag Name (Optional)"
              fullWidth
              style={{ width: '250px' }}
              value={tagname}
              onChange={e => setTagValue(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <SubmitButton />
          <Button onClick={handleClose} color="secondary" variant="contained" type="submit">
            Cancel
          </Button>
        </DialogActions>
        {/* <Grid item xs={12} md={6} mt={10} >
          <Accordion style={{ margin: "10px", width: '600px', boxShadow: 'none' }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                getScheduleList(selectedBusinessScenario.id)
              }}
            >
            </AccordionSummary>
            {map(ListOfSchedules(), (schedule) => (
              <AccordionDetails style={{ marginTop: '-20px', marginBottom: '-30px' }}>
                <List dense={dense}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: '#3f51b5' }}>
                        <ScheduleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary= {
                        <Typography gutterBottom>
                          Run Scheduled For: {schedule.name}
                        </Typography>
                        }
                      secondary={
                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                          <Typography gutterBottom>
                            Cron Expression : {schedule.croneExpression}
                          </Typography>
                          <Typography gutterBottom>
                            Environment Name : {schedule.environment.name}
                          </Typography>
                          <Typography gutterBottom>
                            Run Tags : {schedule.scenarioTags}
                          </Typography>
                          <Typography gutterBottom>
                            Scheduled On : {schedule.scheduleDate}
                          </Typography>
                        </Box>
                      }
                    />
                    {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                  <Grid item xs={12} sm={1}>
                      <AlertDialog dialogLabel='schedule' selectedScheduleName={schedule.name} selectedScheduleId={schedule.id}/>
                  </Grid>
                  )}
                  </ListItem>
                </List>
              </AccordionDetails>

            ))}
          </Accordion>
        </Grid> */}
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedBusinessScenario: getSelectedBusinessScenario(state),
  selectedEnvironmentId: getSelectedEnvironmentId(state),
  applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  addSchedular: (businessScenarios, selectedEnvironmentId, applicationId, scheduleName, croneExpression, run_scenariotags) => {
    dispatch(
      addOrEditScheduleRequest(0,businessScenarios.id, selectedEnvironmentId, applicationId, scheduleName, croneExpression, run_scenariotags)
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSchedule);