import React, { useEffect } from "react";
import FileList from "./FileList";
import EmptyFile from "./EmptyFile";
import { connect } from "react-redux";
import { getFileCount } from "../../selectors/file/selector";
import { getFileCountRequest } from "../../thunks/supportiveFiles/thunks";
import { getSelectedApplication } from "../../selectors/application/selector";

const File = ({ loadCountFromServer, count, applicationId }) => {
  useEffect(() => {
    loadCountFromServer(applicationId);
  }, [loadCountFromServer, applicationId]);

  const fileComponent = () => {
    if (count !== 0) {
      return <FileList />;
    }
    return <EmptyFile />;
  };

  return fileComponent();
};

const mapStateToProps = state => ({
  count: getFileCount(state),
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  loadCountFromServer: (applicationId) => {
    dispatch(getFileCountRequest(applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(File);
