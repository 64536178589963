import React from "react";
import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { runBusinessScenarioRequest } from "../../thunks/run/thunks";
import ToolTip from "@material-ui/core/Tooltip";
import { getSelectedBusinessScenario } from "../../selectors/businessScenario/selector";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { connect } from "react-redux";
import {hasReadOnlyRole} from '../../thunks/common/auth';
import { addFeatureRequest } from "../../thunks/feature/thunks.js";
import { getSelectedEnvironmentId } from "../../selectors/environment/selector";
import EnvironmentSelector from "../menu/EnvironmentSelector"
import { getSelectedApplication } from "../../selectors/application/selector";
import { hasReadWriteRole } from '../../thunks/common/auth';


const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: 20
  },
  formControl: {
    minWidth: 140
  }
}));

const RunFeature = ({
  selectedBusinessScenario,
  runBusinessScenario,
  selectedEnvironmentId,
  applicationId,
}) => {
  const [open, setOpen] = React.useState(false);
  const [tagname, setTagValue] = React.useState("");


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRun = () => {
    runBusinessScenario(selectedBusinessScenario, selectedEnvironmentId,applicationId, tagname)
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Box>
      {hasReadWriteRole() && (
        <ToolTip title="Run Suite">
            <Button
          color="primary"
          startIcon={<PlayArrowIcon />}
          variant="contained"
          className={classes.button}
          onClick={handleClickOpen}
          disabled={hasReadOnlyRole()}
        >
        Run
        </Button>
        </ToolTip>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Run Configurations</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container spacing={3}>
          <EnvironmentSelector />
          <TextField
          id="standard-basic"
          autoComplete="off"
          label="Tag Name (Optional)"
          fullWidth
          style={{ marginLeft: '30px', marginTop: '0px', marginBottom: '40px', width: '250px' }}
          value={tagname}
          onChange={e => setTagValue(e.target.value)}
        />
        </Grid>
        </DialogContent>
        <DialogActions style={{  marginTop: '5px', marginBottom: '20px' }}>
        <Button
          color="primary"
          startIcon={<DirectionsRunIcon />}
          variant="contained"
          onClick={handleRun}
          disabled={hasReadOnlyRole()}
        >
          Run Test Suite
        </Button>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedBusinessScenario: getSelectedBusinessScenario(state),
  selectedEnvironmentId: getSelectedEnvironmentId(state),
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  onAddFeatureClick: (name, scriptType, applicationId) => {
    dispatch(addFeatureRequest(name, scriptType, applicationId));
  },
  runBusinessScenario: (businessScenarios, selectedEnvironmentId,applicationId,tagname) => {
    dispatch(
      runBusinessScenarioRequest(businessScenarios.id, selectedEnvironmentId,applicationId,tagname)
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RunFeature);
