import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import AddUserIcon from '@material-ui/icons/GroupAdd';
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import { map } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import AlertDialog from './UserDialog'
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CancelIcon from '@material-ui/icons/Cancel';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TablePagination from "@material-ui/core/TablePagination";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListItemText from '@material-ui/core/ListItemText';
import { getDevUser, getProjAdminUser,getSelectedUser} from "../../selectors/common/selector"; 
import { getSelectedApplication } from "../../selectors/application/selector.js";
import { hasReadOnlyRole,getUserListRequest,assignUser,getDeveloperListRequest,getProjectAdminListRequest,hasAdminRole, hasReadWriteRole,PROJECT_ADMIN_ROLE,DEVELOPER_ROLE,setSelectedUserRequest, hasProjectAdminRole } from '../../thunks/common/auth';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const useStyles = makeStyles((theme) => ({
  tab: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    marginLeft: 20
  }, button: {
    margin: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  formControl: {
    minWidth: 240,
    marginLeft:50
  },
  icon: {
    margin: theme.spacing(1)
  }
}));


const AssignRoles = ({
  selectedApplication,
  setSelectedUser,
  devdata = [],
  projAdminUserList = [],
  loadDevUserList,
  loadProjAdmUserList,
  assignRole

}) => {
  useEffect(() => {
    if (selectedApplication) {
      setNameValue(selectedApplication.name);
    }
  }, [selectedApplication]);


  const [open, setOpen] = React.useState(false);
  const [name, setNameValue] = React.useState("");
  const [user, setUserValue] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [role,setRoleValue] = React.useState("");

  const handleSelectChange = event => {
    setRoleValue(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    loadProjAdmUserList(name);
    loadDevUserList(name);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Box>
     
       {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) &&(
      <ToolTip title="Assign User">
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          className={classes.icon}
          onClick={handleClickOpen}
          disabled={hasReadOnlyRole()}
        >
          <AddUserIcon />
        </Fab>
      </ToolTip>)}
      <Dialog
        maxWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar position="static" color="primary">
          <Toolbar>
            <Fab
              color="primary"
              aria-label="cancel"
              size="small"
            >
            <GroupAddIcon />
            </Fab>
            <Typography variant="h6" className={classes.title}>
              Manage Users
            </Typography>
            <Fab
              color="primary"
              aria-label="cancel"
              size="small"
              onClick={handleClose}
            >
              <CancelIcon />
            </Fab>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.content}>
          <DialogContentText>
            Please use below to assign user's access for {name} project. You can enter user's registered email id and provide them required access and role.
          </DialogContentText>
          <Box mb={3}>
            <Grid className={classes.root} spacing={3}>
              <Box mt={2} mr={2} ml={2} mb={6}>
                <TextField
                  id="email"
                  label="User"
                  autoComplete="off"
                  value={user}
                  onChange={e => setUserValue(e.target.value)}
                />
                <FormControl className={classes.formControl}>
                  <InputLabel id="script-select-label">
                    Select Roles
                  </InputLabel>
                  <Select
                    labelId="source-script-type"
                    required
                    id="source-script-type"
                    value={role}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={PROJECT_ADMIN_ROLE}>Project Admin</MenuItem>
                    <MenuItem value={DEVELOPER_ROLE}>Developer</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={() => {
                    assignRole(user,role,name);
                  }}
                  color="primary"
                  style={{marginTop: '10px', marginLeft: '50px'}}
                  variant="contained">Assign User Roles</Button>
              </Box>
              <Box mt={2} mb={2}>
                <div className={classes.tab}>
                  <AppBar position="static" >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="fullWidth"
                      indicatorColor="secondary"
                      textColor="white"
                      aria-label="project roles tab"
                    >
                      <Tab label="Super Admin User List" />
                      <Tab label="Project Admin User List" />
                      <Tab label="Developers User List" />

                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <List component="nav" >  
                          <div onClick= {event => {
                        setSelectedUser(devdata);
                      }} >          
                      <ListItem button>
                        <ListItemIcon>
                          <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary= "CATS Admin" />
                        <ListItemIcon>
                        </ListItemIcon>
                      </ListItem>
                      </div>  
                    </List>
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                  {map(projAdminUserList, (projAdminUserList, index) => (
                    <List component="nav" >  
                          <div onClick= {event => {
                        setSelectedUser(devdata);
                      }} >          
                      <ListItem button>
                        <ListItemIcon>
                          <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary= { projAdminUserList} />
                        <ListItemIcon>
                        <AlertDialog dialogLabel='user' selectedUser={projAdminUserList} selectedApplicationId={name}/>
                        </ListItemIcon>
                      </ListItem>
                      </div>  
                    </List>
                    ))}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {map(devdata, (devdata, index) => (
                    <List component="nav" >
                       <div onClick= {event => {
                        setSelectedUser(devdata);
                      }} >
                      <ListItem button>
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={devdata} />
                        <ListItemIcon>
                        <AlertDialog dialogLabel='user' selectedUser={devdata} selectedApplicationId={name}/>
                        </ListItemIcon>
                      </ListItem>
                      </div>
                    </List>
                    ))}
                  </TabPanel>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={1}
                  rowsPerPage={1}
                  page=""
                  // onChangePage={onChangePage}
                />
              </Box>
            </Grid>
          </Box>
        </DialogContent>

      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedApplication: getSelectedApplication(state),
  projAdminUserList: getProjAdminUser(state),
  devdata: getDevUser(state),
  selectedUser: getSelectedUser(state)
});

const mapDispatchToProps = dispatch => ({
  loadUserList: () => {
    dispatch(getUserListRequest());
  },
  loadProjAdmUserList: name => {
    dispatch(getProjectAdminListRequest(name));
  },
  loadDevUserList: name => {
    dispatch(getDeveloperListRequest(name));
  },
  setSelectedUser: user => {
    dispatch(setSelectedUserRequest(user));
  },
  assignRole: (user,role,name) =>{
    dispatch(assignUser(user,role,name));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignRoles);
