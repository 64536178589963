import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { AppBar, Toolbar } from "@material-ui/core";
import { listApplicationRequest,setSelectedApplicationRequest } from "../../thunks/application/thunks";
import { getApplications,getApplicationCount, getPage,getSize, getSelectedApplication} from "../../selectors/application/selector"; 
import {  grey } from "@material-ui/core/colors";
import AcUnitIcon from "@material-ui/icons/Laptop";
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import MobileScreenShareIcon from '@material-ui/icons/PhoneAndroid';
import SpeedIcon from '@material-ui/icons/Speed';
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import AddApplication from "./AddApplication";
import EditApplication from "./EditApplication";
import AssignRoles from "./AssignRoles";
import AlertDialog from './ApplicationDialog'
import { map } from "lodash";
import TablePagination from "@material-ui/core/TablePagination";
import DisplayDate from "../common/DisplayDate";
import SyncApplication from "./SyncApplication";
import {allFeatureRequest} from "../../thunks/feature/thunks"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop:20
  },
  avatar_root:{
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0.2),
    },
  },
  appbar_root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
  },
  space: {
    marginTop: theme.spacing(3)
  },
  brown: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900]
  }
}));

const ApplicationList = ({
  loadApplicationFromServer,
  applications = [],
  setSelectedApplication,
  count = 0,
  page = 0,
  size = 10,
  loadFeaturesFromServer
}) => {
  useEffect(() => {
    loadApplicationFromServer(page, size);
  }, [loadApplicationFromServer, page, size]);


  const onChangePage = (event, pageSelected) => {
    loadApplicationFromServer(pageSelected, size);
  };


  const classes = useStyles();
  return (
    <Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={3}
        alignContent="center"
      >
        {map(applications, (application, index) => (
          <Grid item spacing={3} xs={12} sm={3}>
            <Box mt={4} ml={2} mr={2}>
              <Card className={classes.root} style= {{boxShadow: '5px 5px lightgrey', border: '1px solid lightgrey'}}> 
              <div className={classes.appbar_root}>
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" className={classes.title} >
                    {application.name}
                    </Typography>
                    <div onClick= {event => {
                        setSelectedApplication(applications[index]);
                        loadFeaturesFromServer(applications[index]);
                      }} >
                    <AssignRoles color="inherit"></AssignRoles> 
                      </div>
                      <div onClick= {event => {
                        setSelectedApplication(applications[index]);
                        loadFeaturesFromServer(applications[index]);
                      }} >
                    <EditApplication color="inherit" selectedApplicationId={applications[index]} ></EditApplication>
                      </div>
                      <AlertDialog dialogLabel='application' selectedApplicationId={application.id} ApplicationName={application.name}/>
                      
                      <div onClick= {event => {
                        setSelectedApplication(applications[index]);
                        loadFeaturesFromServer(applications[index]);
                      }} >
                    <SyncApplication color="inherit" selectedApplicationId={applications[index]} ></SyncApplication>
                      </div>
                      
                  </Toolbar>
                </AppBar>
              </div>
              <div onClick= {event => {
                        setSelectedApplication(applications[index]);
                        loadFeaturesFromServer(applications[index]);
                      }} >
                <CardActionArea component={Link} to={"/features"} >
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary">
                      Created on  <DisplayDate dateString={application.created_date_time} />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{marginTop: '10px'}}
                      >
                      Project Type : {application.applicationType}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                </div>
                <Divider />
                <CardActions >
                      <div onClick= {event => {
                        setSelectedApplication(applications[index]);
                        loadFeaturesFromServer(applications[index]);
                      }} >
                  <Button size="small" color="primary" component={Link} to={"/features"}>
                    Features
                  </Button>
                  </div>
                  {application.applicationType === "ANOROC" && (
                            <Avatar className={classes.brown}>
                             <AcUnitIcon />
                            </Avatar>
                          )}
                          {application.applicationType === "MOBILE" && (
                            <Avatar className={classes.brown}>
                             <MobileScreenShareIcon />
                            </Avatar>
                          )}
                          {application.applicationType === "KARATE" && (
                            <Avatar className={classes.brown}>
                              <SportsKabaddiIcon />
                            </Avatar>
                          )}
                          {application.applicationType === "PERFORMANCE" && (
                            <Avatar className={classes.brown}>
                              <SpeedIcon />
                            </Avatar>
                          )}
                          {application.applicationType === "ALL" && (
                            <div className={classes.avatar_root}>
                          <Avatar className={classes.brown}>
                             <AcUnitIcon />
                           </Avatar>
                          <Avatar className={classes.brown}>
                             <MobileScreenShareIcon />
                             </Avatar>
                           <Avatar className={classes.brown}>
                             <SportsKabaddiIcon />
                           </Avatar>
                            <Avatar className={classes.brown}>
                             <SpeedIcon />
                           </Avatar>
                           </div>
                          )}
                 
                </CardActions>
              </Card>
            </Box>
          </Grid>
          
        ))}
        <Grid
          item
          direction="row"
          alignItems="center"
          spacing={3}
          alignContent="center"
        >
          <Box mt={12}>
            <AddApplication />
          </Box>
        </Grid>
      </Grid>
      {count > 0 && (
      <Box mt={4}>
        <TablePagination
          rowsPerPageOptions={[size]}
          component="div"
          count={count}
          rowsPerPage={size}
          page={page}
          onChangePage={onChangePage}
        />
      </Box>
      )}
    </Grid>

  );
};
const mapStateToProps = state => ({
  applications: getApplications(state),
  count: getApplicationCount(state),
  page: getPage(state),
  size: getSize(state),
  selectedApplication: getSelectedApplication(state)
});

const mapDispatchToProps = dispatch => ({
  loadApplicationFromServer: (page, size) => {
    dispatch(listApplicationRequest(page, size));
  },
  setSelectedApplication: application => {
    dispatch(setSelectedApplicationRequest(application));
  },
  loadFeaturesFromServer: application => {
    //dispatch(allFeatureRequest(application));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationList);
