import { BASE_URL } from "../../EnvVariables";
import {
  addApplication,
  getApplicationCount,
  listApplication,
  setApplicationPage,
  setApplicationSize,
  allApplications,
  setSelectedApplication
} from "../../actions/application/actions";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import {resetFeatures} from "../../actions/feature/actions";
import {resetTestPlan} from "../../actions/businessScenario/actions";
import {resetEnv} from "../../actions/environment/actions";
import {resetObject} from "../../actions/globalObject/actions";
import {resetRuns} from "../../actions/run/actions";
import { token,addOrEditGroupsInKeyCloak,deleteProjectKeyCloak,getApplicationList,getprojectId,getListofGroupsforAUser,getCountofGroupsforAUser } from "../common/auth";

export const addOrEditApplicationRequest = (name, id = 0,applicationType,oldname,gitBranchName) => async dispatch => {
  try {
    if(!await addOrEditGroupsInKeyCloak(name,oldname))
      throw "Keyclock error "

    dispatch(startLoading());
    const body = {
      name: name,
      id: id,
      applicationType: applicationType,
      gitBranchName: gitBranchName
    };
    const response = await fetch(`${BASE_URL}/api/applications/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const application = await response.json();
    if (response.status === 201 || response.status === 202) {
      dispatch(addApplication(application));
      dispatch(listApplicationRequest());
      if (id === 0) {
        dispatch(displayMessage("Project added successfully."));
      } else {
        dispatch(displayMessage("Project updated successfully."));
      }
    } else {
      dispatch(
        displayMessage(
          "Could not add the project. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not add the project. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const getApplicationCountRequest = () => async dispatch => {
  dispatch(startLoading());
  dispatch(resetFeatures());
  dispatch(resetTestPlan());
  dispatch(resetEnv());
  dispatch(resetObject());
  dispatch(resetRuns());
  console.log("Get application count request");
  try {
   const countOfGroupsforAUser = await getCountofGroupsforAUser(); 
   dispatch(getApplicationCount(countOfGroupsforAUser));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch project count. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const listApplicationRequest = (
  page = 0,
  size = 10
) => async dispatch => {
  try {
    const keycloakGroupNames = await getListofGroupsforAUser(page,size);
    dispatch(startLoading());

    const countOfGroupsforAUser = await getCountofGroupsforAUser();
    dispatch(getApplicationCount(countOfGroupsforAUser));
    dispatch(setApplicationPage(page));
    dispatch(setApplicationSize(size));
    const response = await fetch(
      `${BASE_URL}/api/applications/list?keycloakGroupNames=${keycloakGroupNames}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const applications = await response.json();
    dispatch(listApplication(applications));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the project. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const getAllApplicationsRequest = () => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await fetch(`${BASE_URL}/api/applications/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "get"
    });
    const applications = await response.json();
    dispatch(allApplications(applications));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the project. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const deleteApplicationRequest = (
  selectedApplicationId = 0,ApplicationName
) => async dispatch => {
  try {
    if(! await deleteProjectKeyCloak(ApplicationName))
      throw "KeyCloak error"
    const body = {
      id: selectedApplicationId
    };
    const response = await fetch(`${BASE_URL}/api/applications/delete`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token(),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
      },
      method: "post",
      body: JSON.stringify(body)
    });
    //const environment = await response.json();
    if (response.status === 200) {
      dispatch(listApplicationRequest());
      dispatch(displayMessage("Application deleted successfully."));
    } else {
      dispatch(
        displayMessage("Could not delete the Application. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not delete the Application. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const syncApplicationRequest = (
  applicationId = 0,
  gitUri = "",
  username="",
  password="",
  gitBranchName = ""
) => async dispatch => {
  try {
    const body = {
      applicationId: applicationId,
      gitUri: gitUri,
      username: username,
      password: password,
      branch: gitBranchName
    };
    dispatch(displayMessage("Syncing of application is in progress..."));
    dispatch(startLoading());
    const response = await fetch(
      `${BASE_URL}/api/applications/filestodb`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "post",
        body: JSON.stringify(body)
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 202) {
      // dispatch(listApplicationRequest());
      dispatch(displayMessage("Application Synced successfully."));
    }
     else if (response.status === 502) {
      dispatch(displayMessage("Could not Sync the Application. Enter Git Branch for Application " ));
    }
     else {
      dispatch(displayMessage("Could not Sync the Application. Verify GIT URL and credentials."));
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not Sync the Project. Try the operation again")
    );
    dispatch(stopLoading());
  }
};
export const pushApplicationRequest = (
  applicationId = 0,
  gitUri = "",
  username="",
  password="",
  branch = "",
  commitMessage = ""
) => async dispatch => {
  try {
    const body = {
      applicationId: applicationId,
      gitUri: gitUri,
      username: username,
      password: password,
      branch: branch,
      commitMessage: commitMessage
    };
    dispatch(displayMessage("Pushing of application to Git is in progress..."));
    dispatch(startLoading());
    const response = await fetch(
      `${BASE_URL}/api/applications/dbtofiles`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "post",
        body: JSON.stringify(body)
      }
    );
    if (response.status === 200 || response.status === 201 || response.status === 202) {
      const newBranchName = await response.text();
      // dispatch(listApplicationRequest());
      dispatch(displayMessage("Application Pushed successfully and new branch is created: " +newBranchName ));
    }
   else if (response.status === 502) {
      dispatch(displayMessage("Could not Push the Application.Enter Git Branch for Application " ));
    }
    else {
      dispatch(displayMessage("Could not Push the Application. Verify GIT URL and credentials."));
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not Push the Project. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const setSelectedApplicationRequest = application => async dispatch => {
  dispatch(setSelectedApplication(application));
};
