import { BASE_URL } from "../../EnvVariables";
import {
  addEnvironment,
  listEnvironment,
  getEnvironmentCount,
  setEnvironmentPage,
  setEnvironmentSize,
  updateEnvironment,
  setSelectedEnvironment,
  setSelectedEnvironmentId,
  allEnvironments,
  getSearchEnvironmentCount
} from "../../actions/environment/actions";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import { token } from "../common/auth";

export const addEnvironmentRequest = (
  name, 
  content = "",
  applicationId
  ) => async dispatch => {
  try {
    dispatch(startLoading());
    const body = {
      name: name,
      content: content,    
      applicationId: applicationId  
    };
    const response = await fetch(`${BASE_URL}/api/environment/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const environment = await response.json();
    if (response.status === 201) {
      dispatch(addEnvironment(environment));
      dispatch(getEnvironmentCountRequest(applicationId));
      dispatch(listEnvironmentRequest(0,10,"",applicationId));
      dispatch(getAllEnvironmentsRequest(applicationId));
      dispatch(displayMessage("Environment added successfully."));
    } else {
      dispatch(
        displayMessage(
          "Could not add the environment. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not add the environment. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const getEnvironmentCountRequest = (
  applicationId
) => async dispatch => {
  dispatch(startLoading());
  console.log("Get environment count request");
  try {
    const environmentCountResponse = await fetch(
      `${BASE_URL}/api/environment/count?applicationId=${applicationId}&searchValue=`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const environmentCount = await environmentCountResponse.json();
    dispatch(getEnvironmentCount(environmentCount.count));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch environment count. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const listEnvironmentRequest = (
  page = 0,
  size = 10,
  searchValue="",
  applicationId  
) => async dispatch => {
  try {
    dispatch(startLoading());
    const environmentCountResponse = await fetch(
      `${BASE_URL}/api/environment/count?applicationId=${applicationId}&searchValue=${searchValue}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const environmentCount = await environmentCountResponse.json();
    console.log("under list count is ",environmentCount.count)
    dispatch(getSearchEnvironmentCount(environmentCount.count));
    dispatch(setEnvironmentPage(page));
    dispatch(setEnvironmentSize(size));
    const response = await fetch(
      `${BASE_URL}/api/environment/list?page=${page}&size=${size}&applicationId=${applicationId}&searchValue=${searchValue}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const environments = await response.json();
    dispatch(listEnvironment(environments));
    if (environments.length > 0) {
      dispatch(setSelectedEnvironment(environments[0]));
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the environments. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const updateEnvironmentRequest = (
  id = 0,
  name = "",
  anorocContents = "",
  karateContents = "",
  settingsContent = "",
  applicationId
) => async dispatch => {
  try {
    const body = {
      id: id,
      name: name,
      anoroc_content: anorocContents,
      karate_content: karateContents,
      settings_content: settingsContent,
      applicationId: applicationId
    };
    const response = await fetch(`${BASE_URL}/api/environment/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const environment = await response.json();
    if (response.status === 202) {
      dispatch(displayMessage("Environment updated successfully."));
      dispatch(updateEnvironment(environment));
      dispatch(setSelectedEnvironment(environment));
      dispatch(getAllEnvironmentsRequest(applicationId));
    } else {
      dispatch(
        displayMessage(
          "Could not update the environment. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not update the environment. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const setSelectedEnvironmentRequest = environment => async dispatch => {
  dispatch(setSelectedEnvironment(environment));
};

export const getAllEnvironmentsRequest = (
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await fetch(`${BASE_URL}/api/environment/all?applicationId=${applicationId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      }
    });
    const environments = await response.json();
    dispatch(allEnvironments(environments));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the environments. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const deleteEnvironmentRequest = (
  selectedEnvironmentId = 0,
  applicationId
) => async dispatch => {
  try {
    const body = {
      id: selectedEnvironmentId
    };
    const response = await fetch(`${BASE_URL}/api/environment/delete`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token(),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const environment = await response.json();
    if (response.status === 200) {
      dispatch(getEnvironmentCountRequest(applicationId))
      dispatch(listEnvironmentRequest(0,10,"",applicationId));
      dispatch(getAllEnvironmentsRequest(applicationId));
      dispatch(displayMessage("Environment deleted successfully."));
    } else {
      dispatch(
        displayMessage("Could not delete the Environment. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not delete the Environment. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const setSelectedEnvironmentIdRequest = environmentId => async dispatch => {
  dispatch(setSelectedEnvironmentId(environmentId));
};
