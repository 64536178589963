import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EditSharpIcon from '@material-ui/icons/EditSharp';
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { addOrEditApplicationRequest } from "../../thunks/application/thunks.js";
import { getSelectedApplication } from "../../selectors/application/selector.js";
import { hasAdminRole, hasReadWriteRole,hasProjectAdminRole } from '../../thunks/common/auth';


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0)
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const EditApplication = ({
  selectedApplication,
  selectedApplicationId,
  onUpdateClick,
  setApplicationType
}) => {
  useEffect(() => {
    if (selectedApplication) {
      setNameValue(selectedApplication.name);
    }
  }, [selectedApplication]);

  const [open, setOpen] = React.useState(false);
  const [name, setNameValue] = React.useState("");
  const [oldname,setOldName] = React.useState("");
  const [applicationType, setScriptTypeValue] = React.useState("");
  const [gitBranchName, setGitBranchName] = React.useState("");


  var nameLength = name.length>3 && name.length<16;
  

  function SubmitButton(){
    if (nameLength && applicationType){
      return <Button onClick={() => {
        onUpdateClick(selectedApplication.id, name,applicationType, oldname, gitBranchName);
        handleClose();
      }}
      color="primary"
      variant="contained">Update</Button>
    } else {
      return <Button onClick={() => {
        onUpdateClick(selectedApplication.id, name,applicationType, oldname, gitBranchName);
        handleClose();
      }}
      color="primary"
      disabled
      variant="contained">Update</Button>
    };
  };

  const handleClickOpen = () => {
    setOpen(true);
    setOldName(selectedApplicationId.name);
    setNameValue(selectedApplicationId.name)
    setScriptTypeValue(selectedApplicationId.applicationType)
    setGitBranchName(selectedApplicationId.gitBranchName)
  };

  const handleSelectChange = event => {
    setScriptTypeValue(event.target.value);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Box>
      {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) &&(
      <ToolTip title="Edit Project">
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <EditSharpIcon />
        </Fab>
      </ToolTip>)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit name or project type, Please enter new project name or type and click on
            Update.
          </DialogContentText>
          <Box mb={2}>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="name"
              label="Project Name"
              value={name}
              error={!nameLength}
              helperText={name !== "" ? "Project name should be between 3-15 character" : " "}
              onChange={e => setNameValue(e.target.value)}
              fullWidth
            />

            <FormControl className={classes.formControl}>
              <InputLabel id="script-select-label">
                Project Type
              </InputLabel>
              <Select
                labelId="source-script-type"
                required
                id="source-script-type"
                value={applicationType}
                onChange={handleSelectChange}>
                <MenuItem value={"KARATE"}>KARATE</MenuItem>
                <MenuItem value={"ANOROC"}>SELENIUM</MenuItem>
                <MenuItem value={"MOBILE"}>MOBILE</MenuItem>
                <MenuItem value={"PERFORMANCE"}>PERFORMANCE</MenuItem>
                <MenuItem value={"ALL"}>ALL</MenuItem>
              </Select>
            </FormControl>

            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="gitBranchName"
              label="Git Branch Name (Optional)"
              value={gitBranchName}
              
              helperText={"Enter correct existing Branch name"}
              onChange={e => setGitBranchName(e.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <SubmitButton/>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedApplication: getSelectedApplication(state),
  //setApplicationType: getSelectedApplication(state).applicationType
});

const mapDispatchToProps = dispatch => ({
  onUpdateClick: (id, name,applicationType, oldname, gitBranchName) => dispatch(addOrEditApplicationRequest(name, id, applicationType, oldname,gitBranchName))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditApplication);
