import React, { useEffect } from "react";
import EnvironmentList from "./EnvironmentList";
import EmptyEnvironment from "./EmptyEnvironment";
import { connect } from "react-redux";
import { getEnvironmentCount } from "../../selectors/environment/selector";
import { getEnvironmentCountRequest } from "../../thunks/environment/thunks";
import { getSelectedApplication } from "../../selectors/application/selector";

const Environment = ({ loadCountFromServer, count, applicationId }) => {
  useEffect(() => {
    loadCountFromServer(applicationId);
  }, [loadCountFromServer, applicationId]);

  const environmentComponent = () => {
    if (count !== 0) {
      return <EnvironmentList />;
    }
    return <EmptyEnvironment />;
  };

  return environmentComponent();
};

const mapStateToProps = state => ({
  count: getEnvironmentCount(state),
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  loadCountFromServer: (applicationId) => {
    dispatch(getEnvironmentCountRequest(applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Environment);
