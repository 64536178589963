import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { addOrEditApplicationRequest } from "../../thunks/application/thunks.js";
import { hasAdminRole, hasReadWriteRole, isNotProjectAdmin } from '../../thunks/common/auth';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2)
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const AddApplication = ({ onAddApplicationClick }) => {
  const [open, setOpen] = React.useState(false);
  const [name, setNameValue] = React.useState("");
  const [applicationType, setScriptTypeValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setNameValue("");
    setScriptTypeValue("");
  };

  const handleSelectChange = event => {
    setScriptTypeValue(event.target.value);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  var nameLength = name.length>3 && name.length<16;
  
  function SubmitButton(){
    if (nameLength && applicationType){
      return <Button onClick={() => {
        onAddApplicationClick(name,applicationType);
        handleClose();
      }}
      color="primary"
      variant="contained">Add Project</Button>
    } else {
      return <Button onClick={() => {
        onAddApplicationClick(name);
        handleClose();
      }}
      color="primary"
      disabled
      variant="contained">Add Project</Button>
    };
  };


  return (
    <Box>
      {hasReadWriteRole() && hasAdminRole() &&isNotProjectAdmin() &&(
        <ToolTip title="Add Project">
          <Fab
            color="primary"
            aria-label="add"
            className={classes.button}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </ToolTip>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new project, Please enter the project name and select project type then click on
            Add Project.
          </DialogContentText>
          <Box mb={2}>
            <TextField
              autoFocus
              required
              autoComplete="off"
              margin="dense"
              id="outlined-required"
              label="Project Name"
              value={name}
              error={!nameLength}
              helperText={name !== "" ? "Project name should be between 3-15 character" : " "}
              onChange={e => setNameValue(e.target.value)}
              fullWidth
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="script-select-label">
                Project Type
              </InputLabel>
              <Select
                labelId="source-script-type"
                required
                id="source-script-type"
                value={applicationType}
                onChange={handleSelectChange}>
                <MenuItem value={"KARATE"}>KARATE</MenuItem>
                <MenuItem value={"ANOROC"}>SELENIUM</MenuItem>
                <MenuItem value={"MOBILE"}>MOBILE</MenuItem>
                <MenuItem value={"PERFORMANCE"}>PERFORMANCE</MenuItem>
                <MenuItem value={"ALL"}>ALL</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
        <SubmitButton/>
          <Button onClick={handleClose} color="secondary" variant="contained" type="submit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onAddApplicationClick: (name,applicationType) => dispatch(addOrEditApplicationRequest(name, 0,applicationType))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddApplication);
