import React, { useEffect } from "react";
import RunList from "./RunList";
import EmptyRun from "./EmptyRun";
import { connect } from "react-redux";
import { getRunCount } from "../../selectors/run/selector";
import { listRunRequest } from "../../thunks/run/thunks";
import { getSelectedApplication } from "../../selectors/application/selector";

const Run = ({ count, listRun,applicationId }) => {
  useEffect(() => {
    listRun(applicationId);
  },[listRun,applicationId]);

  const runComponent = () => {
    if (count !== 0) {
      return <RunList />;
    }
    return <EmptyRun />;
  };

  return runComponent();
};

const mapStateToProps = state => ({
  count: getRunCount(state),
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  listRun: (applicationId) => {
    dispatch(listRunRequest(0,10,applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Run);
