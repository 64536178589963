import React from "react";
import { Box, Grid, Typography, Button, Paper, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useKeycloak } from "@react-keycloak/web";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: '240px',
    height: 'flex',
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: '#eee',
      boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    }
  },
  button: {
    '&:hover': {
      boxShadow: '0px 7px 8px -4px #3f51b5'
    }
  }
}));

export default function EmptyHome() {
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  return (
    <div className={classes.root}>
      {keycloak && !keycloak.authenticated && (
        <Grid container >
          <Grid item xs={12}>
            <Paper style={{
               height: '500px', backgroundImage: "url(" + "./geo.jpg?auto=compress&w=900&h=600&fit=crop" + ")",
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              justifyContent: 'center',
              flex: '1',
              paddingBottom: '20px'
            }} >

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Typography variant="body2" style={{ paddingTop: '80px', marginLeft: '140px', fontWeight: '700', lineHeight: '56px', fontSize: '48px', color: 'white', fontFamily: 'sans-serif', width: '600px' }} >
                  Continuous Quality Engineering at speed with CATS Lite
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <Typography variant="body2" style={{ paddingTop: '70px', marginLeft: '80px', fontWeight: 'bold', fontSize: '30px', color: 'white', fontFamily: 'sans-serif', width: '300px' }} >
                  Continuous Quality Engineering at speed with CATS Lite
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <Typography variant="body2" style={{ marginTop: '10px', marginLeft: '80px', width: '300px', fontSize: '20px', color: 'white' }}>
                  A codeless approach to build high quality software                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Typography variant="body2" style={{ marginTop: '10px', marginLeft: '140px', width: '800px', fontSize: '24px', color: 'white', fontFamily: 'sans-serif' }}>
                  A codeless approach to build high quality software
                </Typography>
              </Box>


              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                startIcon={<LockOpenIcon />}
                style={{ marginTop: '40px', marginLeft: '140px', width: '180px', height: '50px', borderRadius: '20px', backgroundColor:'#1acc8d', color: 'white', border: '1px solid #1acc8d' }}
                onClick={() => keycloak.login()} >
                Login
              </Button>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                startIcon={<LockOpenIcon />}
                style={{ marginTop: '40px', marginLeft: '100px', width: '180px', height: '50px', borderRadius: '20px', backgroundColor:'#1acc8d', color: 'white', border: '1px solid #1acc8d' }}
                onClick={() => keycloak.login()} >
                Login
              </Button>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                startIcon={<ArrowForwardIcon />}
                style={{ marginTop: '-50px', marginLeft: '360px', width: '180px', height: '50px', borderRadius: '20px', backgroundColor:'#1acc8d', color: 'white', border: '1px solid #1acc8d' }}
                onClick={() => keycloak.login()} >
                Register
              </Button>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                startIcon={<ArrowForwardIcon />}
                style={{ marginTop: '20px', marginLeft: '100px', width: '180px', height: '50px', borderRadius: '20px', backgroundColor:'#1acc8d', color: 'white', border: '1px solid #1acc8d' }}
                onClick={() => keycloak.login()} >
                Register
              </Button>
              </Box>

            </Paper>
          </Grid>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={5} style={{ paddingTop: '100px', paddingLeft: '60px'}} >
            <img src="/about.svg" width="560px" />
          </Grid>
          </Box>


          <Grid item xs={7} style={{ paddingTop: '30px', paddingLeft: '80px' }}  >
            <Typography variant="body2" style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '32px', color: '#010483' }} >
              About CATS Lite
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{fontFamily: 'sans-serif', fontSize: '16px', maxWidth: '900px' }}>
              Comprehensive solution for all your testing needs from test automation, execution to reporting. It helps you to automate Web, API, Mobile, Performance and Accessibility testing at pace without need to write code.
            </Typography>


            <Typography variant="body2" style={{ paddingTop: '30px', paddingBottom: '2px', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
              Federated Test Management Framework
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px',fontFamily: 'sans-serif',  maxWidth: '900px' }}>
             Takes a federated approach towards quality engineering. Allows multiple tools and frameworks to co-exist. No vendor lock-in on one framework or tool be it open or paid, open for extensions to any kind of a tool.
            </Typography>

            <Typography variant="body2" style={{ paddingTop: '15px', paddingBottom: '2px',fontFamily: 'sans-serif',  fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
              Automate both functional and non functional
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif', maxWidth: '900px' }}>
             CATS lite provide stop solution for all of your testing needs from Functional ( Web, API, Mobile testing ) to Non functional ( Performance and Accessibility testing )
            </Typography>

            <Typography variant="body2" style={{ paddingTop: '15px', paddingBottom: '2px', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
              Standardized and Aggregated Continuous Testing Reports
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif', maxWidth: '900px' }}>
              Manage all your reports in one place. Analytics based on reports generated using AI and ML. Aggregation, collaboration, result analysis and quality reporting for easy defect identification and classification all in near real time.               </Typography>

            <Typography variant="body2" style={{ paddingTop: '15px', paddingBottom: '2px', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
              Scalable, Parallel Testing Grid integrated to CI/CD workflow
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px',fontFamily: 'sans-serif',  maxWidth: '900px' }}>
              Guaranteed execution of submitted workloads using scalable and parallel executions over an extensible grid. Integrate your CI/CD pipelines to trigger executions using our OPEN APIs
            </Typography>

            <Typography variant="body2" style={{ paddingTop: '15px', paddingBottom: '2px', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
              Test As A Service (TAAS)
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: '16px',fontFamily: 'sans-serif',  maxWidth: '900px' }}>
              CATS Lite takes Test As A Service approach. This approach provides entire solution as web app without need for provisioning and managing test infrastructure. You can virtually write and run any type of test.
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ padding: '40px', maxWidth: '1000px'}}>
            <Divider variant="middle" style={{backgroundColor: '#1acc8d',marginLeft: '300px'}} />
          </Grid>

          <Grid xs={12}>
            <Typography variant="body2" style={{ paddingLeft: '140px', fontWeight: 'bold', fontSize: '30px', color: '#010483',fontFamily: 'sans-serif' }} >
            CHECK THE FEATURES
            </Typography>
          </Grid>

          <Grid item xs={8} style={{ paddingTop: '40px', paddingLeft: '140px' }} >
            <Typography variant="body2" style={{ paddingBottom: '20px',fontFamily: 'sans-serif',fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
            Specifications expressed as features to match the Acceptance Criteria
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px' }}>
            Define the feature files with all your stakeholders using Behavior-Driven Development (BDD). Automatically create documentation that’s up to date and easily shareable.
            </Typography>
            <ul style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px', color: '#0000008A'}}>
              <li> Express business specifications as feature files.</li>
              <li>Collaborate and share with stake holders.</li>
              <li> Specifications become living documentation for the business features to be tested.</li>
              </ul>
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={4} style={{ paddingTop: '10px' }}>
            <img src="/env.svg" alt="rocket" width="380" />
          </Grid></Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={4} style={{ paddingTop: '0px', paddingLeft: '140px' }}>
            <img src="/spec.svg" alt="rocket" width="380" />
          </Grid></Box>

          <Grid item xs={8} style={{ paddingTop: '30px', paddingLeft: '80px' }} >
          <Typography variant="body2" style={{ paddingBottom: '20px',fontFamily: 'sans-serif',fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
              Target Execution Environments to seamlessly run tests
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px' }}>
              Run tests across multiple environments by defining the environment once and running the tests again and again with a single click switch.
            </Typography>
            <ul style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px', color: '#0000008A'}}>
              <li>  Define multiple environments.</li>
              <li>Define specific parameters related to the environments.</li>
              <li> Run your tests.</li>
              <li>No more messy spaghetti code to manage environments. Manage everything through a neat intuitive UI and simple JSON configuration.</li>
              </ul>
          </Grid>


          <Grid item xs={8} style={{ paddingTop: '30px', paddingLeft: '140px' }} >
          <Typography variant="body2" style={{ paddingBottom: '20px',fontFamily: 'sans-serif',fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
            Easily Test Management
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px' }}>
            Ability to create new projects or tests. Each project is associate  with features and its test data and setting specifications.
            </Typography>
            <ul style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px', color: '#0000008A'}}>
              <li>Create New Project.</li>
              <li>Provide user access and roles to the project</li>
              <li>Create features by testing type</li>
              <li>Define object repositories , test data and env settings for each project.</li>
              </ul>
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={4}>
            <img src="/business.svg" alt="" width="380" />
          </Grid></Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={4} style={{ paddingTop: '0px', paddingLeft: '140px' }}>
            <img src="/report.svg"  width="380" />
          </Grid></Box>

          <Grid item xs={8} style={{ paddingTop: '30px', paddingLeft: '80px' }} >
          <Typography variant="body2" style={{ paddingBottom: '20px',fontFamily: 'sans-serif',fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
            Write and Run tests as a service with click of a button and view execution reports in near real time
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px' }}>
            Ability to run your features and business scenarios with a click of a button or through the open APIs the system provides. See dynamic run reports during execution in near real time.
            </Typography>
            <ul style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px', color: '#0000008A'}}>
              <li>Select a feature or a business scenario to run.</li>
              <li>Run the business scenario and see the run reports.</li>
              <li>Perform detailed analysis of the reports during and after the execution.</li>
              <li>Identify specific launches and quarantine suspected runs to collaborate and fix.</li>
              </ul>
          </Grid>

          <Grid item xs={8} style={{ paddingTop: '30px', paddingLeft: '140px' }} >
          <Typography variant="body2" style={{ paddingBottom: '20px',fontFamily: 'sans-serif',fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
            AI and ML driven features to aggregate, assimilate, classify, delegate and integrate reports
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px' }}>
            Provides capability to aggregate features, assimilate them based on specific parameters, automatically classify specific defects based on predefined categories using integration with ReportPortal. AI and ML driven classifications assists in doing the analysis and segregation.
            </Typography>
            <ul style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px', color: '#0000008A'}}>
              <li> AI and ML driven defect classification.</li>
              <li> Real time defect identification based on test runs.</li>
              <li> Data available in dashboards to view the required metrics and capability to extend the same.</li>
              </ul>
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={4} style={{ paddingTop: '0px' }}>
            <img src="/ai.svg" alt="" width="380" />
          </Grid></Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs={4} style={{ paddingTop: '0px', paddingLeft: '140px' }}>
            <img src="/safe.svg" alt="rocket" width="400" />
          </Grid></Box>

          <Grid item xs={8} style={{ paddingTop: '30px', paddingLeft: '140px' }} >
          <Typography variant="body2" style={{ paddingBottom: '20px',fontFamily: 'sans-serif',fontWeight: 'bold', fontSize: '20px', color: '#010483' }} >
          Enterprise Security through Centralized Identity and Policy Management
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px' }}>
            Provides Enterprise grade security for all the data in the system by providing strong authentication and authorization mechanisms. Can be extended to provide encryption to certain parts of data.            </Typography>
            <ul style={{ fontSize: '16px', fontFamily: 'sans-serif',  maxWidth: '900px', color: '#0000008A'}}>
              <li> Secure authentication and authorization access using keycloack.</li>
              <li>Central management of the users and authorizations by defining client specific realms and central policy management.</li>
              <li> Access to each project & entity is controlled using role authorization</li>
              </ul>
          </Grid>

          <Grid item xs={12} style={{ padding: '50px' }}>
            <Divider variant="middle" />
          </Grid>

            <Grid container xs={12} style={{ marginLeft: '140px' }} >
              <Grid xs={12}>
                <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '30px', color: '#010483', paddingBottom: '30px' }} >
                  CATS Lite Team
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} spacing={3} style={{ background: '#fffff', flex: '1', padding: '20px' }}>
                <Card className={classes.card}>
                  <CardActionArea >
                    <CardContent>
                      <Typography color="inherit" style={{ textAlign: 'center', fontWeight: '900', fontSize: '16px' }}>
                        Prashant Kumar
                      </Typography>
                      <Divider style={{ margin: '15px', background: '#1acc8d' }} />
                      <Typography color="inherit" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '13px' }}>
                        prashant.kumar
                      </Typography>
                      <Typography color="inherit" style={{ textAlign: 'center', paddingBottom: '20px', fontStyle: 'italic', fontSize: '13px' }}>
                        @publicissapient.com
                      </Typography>
                      {/* <div style={{ paddingLeft: '8px' }}>
                        <img src="/de.png" alt="rocket" width="190" />
                      </div> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
             
              <Grid item xs={6} sm={2} style={{ background: '#fffff', flex: '1', padding: '20px' }}>
                <Card className={classes.card}>
                  <CardActionArea >
                    <CardContent>
                      <Typography color="inherit" style={{ textAlign: 'center', fontWeight: '900', fontSize: '16px' }}>
                        Abhijeet Kumar
                      </Typography>
                      <Divider style={{ margin: '15px', background: '#1acc8d' }} />
                      <Typography color="inherit" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '13px' }}>
                        abhijeet.kumar1
                      </Typography>
                      <Typography color="inherit" style={{ textAlign: 'center', paddingBottom: '20px', fontStyle: 'italic', fontSize: '13px' }}>
                        @publicissapient.com
                      </Typography>
                      {/* <div style={{ paddingLeft: '8px' }}>
                        <img src="/d4.jpg" alt="rocket" width="200" />
                      </div> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2} style={{ background: '#fffff', flex: '1', padding: '20px' }}>
                <Card className={classes.card}>
                  <CardActionArea >
                    <CardContent>
                      <Typography color="inherit" style={{ textAlign: 'center', fontWeight: '900', fontSize: '16px' }}>
                        Kaushal Bharti
                      </Typography>
                      <Divider style={{ margin: '15px', background: '#1acc8d' }} />
                      <Typography color="inherit" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '13px' }}>
                      kaushal.bharti
                      </Typography>
                      <Typography color="inherit" style={{ textAlign: 'center', paddingBottom: '20px', fontStyle: 'italic', fontSize: '13px' }}>
                        @publicissapient.com
                      </Typography>
                      {/* <div style={{ paddingLeft: '8px' }}>
                        <img src="/d1.jpg" alt="rocket" width="200" />
                      </div> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2} style={{ background: '#fffff', flex: '1', padding: '20px' }}>
                <Card className={classes.card}>
                  <CardActionArea >
                    <CardContent>
                      <Typography color="inherit" style={{ textAlign: 'center', fontWeight: '900', fontSize: '16px' }}>
                        Sanjeet Rapriya
                      </Typography>
                      <Divider style={{ margin: '15px', background: '#1acc8d' }} />
                      <Typography color="inherit" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '13px' }}>
                        sanrapri
                      </Typography>
                      <Typography color="inherit" style={{ textAlign: 'center', paddingBottom: '20px', fontStyle: 'italic', fontSize: '13px' }}>
                        @publicissapient.com
                      </Typography>
                      {/* <div style={{ paddingLeft: '8px' }}>
                        <img src="/d4.jpg" alt="rocket" width="200" />
                      </div> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} sm={2} style={{ background: '#fffff', flex: '1', padding: '20px' }}>
                <Card className={classes.card}>
                  <CardActionArea >
                    <CardContent>
                      <Typography color="inherit" style={{ textAlign: 'center', fontWeight: '900', fontSize: '16px' }}>
                        Maruthi Vippagunta
                      </Typography>
                      <Divider style={{ margin: '15px', background: '#1acc8d' }} />
                      <Typography color="inherit" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '13px' }}>
                        maruthi.vippagunta
                      </Typography>
                      <Typography color="inherit" style={{ textAlign: 'center', paddingBottom: '20px', fontStyle: 'italic', fontSize: '13px' }}>
                        @publicissapient.com
                      </Typography>
                      {/* <div style={{ paddingLeft: '8px' }}>
                        <img src="/d1.jpg" alt="rocket" width="200" />
                      </div> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

            </Grid>


          <Grid item xs={12} style={{ marginTop: '60px' }} >
            <AppBar position="static">
              <Toolbar>
                <Typography style={{ paddingLeft: '100px', fontFamily: 'sans-serif', fontSize: '14PX' }}>
                  © Copyright CATS Lite. All Rights Reserved
                </Typography>
              </Toolbar>
            </AppBar>
          </Grid>


        </Grid>
      )}

{keycloak && keycloak.authenticated && <Redirect to="/projects" />}

      {/* {keycloak && keycloak.authenticated && (
        <div>
          <Box mt={16} ml={4} mr={2} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <img src="/welcome.svg" alt="Welcome" width="360" />
          </Box>
          <Box mt={8} ml={2} mr={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <img src="/welcome.svg" alt="Welcome" width="600" style={{ marginLeft: '500px' }} />
          </Box>
          <Typography variant="body2" style={{ paddingTop: '30px', fontWeight: 'bold', fontSize: '30px', textAlign: 'center' }} >
            Getting Started
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px', paddingBottom: '50px', textAlign: 'center', fontSize: '18px' }}>
            Please access your project if your are assigned to a project or raise access request with your project admin
          </Typography>
          <Button
            variant="outlined"
            size="large"
            color='primary'
            style={{ marginTop: '40px', margin: 'auto', display: 'block', textAlign: 'center', width: '300px', height: '50px' }}
            component={Link}
            to={"/projects"}
          >
            Project List
          </Button>
        </div>
      )} */}
    </div>
  );
}
