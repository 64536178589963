import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import {allRuns} from "../../selectors/run/selector";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { map } from "lodash";


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const RunTable = ({ runs = []}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns = [
    { id: 'id', label: 'Run ID',  align: 'center', minWidth: 70 },
    { id: 'name', label: 'Name', minWidth: 70 },
    { id: 'run_type', label: 'Run Type', minWidth: 70 },
    { id: 'status', label: 'Build Status', minWidth: 70 },
    { id: 'scenario_count',label: 'Total Scenario Count',minWidth: 30, align: 'center', format: (value) => value.toLocaleString('en-US'),},
    { id: 'scenario_pass_count',label: 'Scenario Pass Count',minWidth: 30, align: 'center', format: (value) => value.toLocaleString('en-US'),},
    { id: 'scenario_fail_count',label: 'Scenario Fail Count',minWidth: 30, align: 'center', format: (value) => value.toLocaleString('en-US'),},
    { id: 'run_duration',label: 'Run Duration',minWidth: 70, align: 'center', format: (value) => (value/1000000000).toFixed(2),},
    { id: 'run_at',label: 'Run Time',minWidth: 70, align: 'center', format: (value) => value.toLocaleString('en-US'),},

  ];
  
  function createData(id, name,run_type, status, scenario_count, scenario_pass_count, scenario_fail_count, run_duration, run_at) {
    return { id, name, run_type, status, scenario_count, scenario_pass_count, scenario_fail_count, run_duration,run_at };
  }
  
  const rows =
      map(runs, (run) => (
        createData(run.id, run.details, run.run_type, run.status, run.scenario_count, run.scenario_pass_count, run.scenario_fail_count, run.run_duration, run.run_at)
      ))

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor:'#3f51b5', color: 'white' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
  }

const mapStateToProps = state => ({
    runs: allRuns(state)
  });

export default connect(mapStateToProps)(RunTable);