import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
// import ToolTip from "@material-ui/core/ToolTip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { addBusinessScenarioRequest } from "../../thunks/businessScenario/thunks.js";
import { hasReadWriteRole } from '../../thunks/common/auth';
import { getSelectedApplication } from "../../selectors/application/selector";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2)
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const AddBusinessScenario = ({ onAddBusinessScenarioClick, applicationId }) => {
  const [open, setOpen] = React.useState(false);
  const [name, setNameValue] = React.useState("");
  const [description, setDescriptionValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  var nameLength = name.length>3 && name.length<21;

  function SubmitButton(){
    if (nameLength && description ){
      return <Button
      onClick={() => {
        onAddBusinessScenarioClick(name, description,applicationId);
        setNameValue("");
        setDescriptionValue("");
        handleClose();
      }}
      color="primary"
      variant="contained"
    >Add Test Suite</Button>
    } else {
      return <Button
      onClick={() => {
        onAddBusinessScenarioClick(name, description,applicationId);
        setNameValue("");
        setDescriptionValue("");
        handleClose();
      }}
      color="primary"
      variant="contained"
      disabled
    >Add Test Suite</Button>
    };
  };


  return (
    <Box>
      {hasReadWriteRole() && (
        // <ToolTip title="Add Test Suite">
          <Fab
            color="primary"
            aria-label="add"
            className={classes.button}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        // </ToolTip>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Test Suite</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box mb={2}>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              required
              error={!nameLength}
              helperText={name !== "" ? "Test Plan name should be between 3-20 character" : " "}
              label="Name"
              value={name}
              onChange={e => setNameValue(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <TextField
              multiline
              autoComplete="off"
              rows={4}
              required
              variant="outlined"
              margin="dense"
              id="description"
              error={description==""}
              helperText={name !== "" ? "Please enter description" : " "}
              label="Description"
              value={description}
              onChange={e => setDescriptionValue(e.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
        <SubmitButton/>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  onAddBusinessScenarioClick: (name, description,applicationId) => {
    dispatch(addBusinessScenarioRequest(name, description,applicationId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBusinessScenario);
