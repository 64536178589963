import {
  LIST_SCHEDULE
} from "../../actions/common/actions.js";

export const schedule = (state = [], action) => {
  const { type, payload } = action;
  switch (type)
   {
    case LIST_SCHEDULE: {
      const { schedule } = payload;
      const scheduleList = {
        ...state,
        data: schedule
      };
      return scheduleList;
    }
    
    default:
      return state;
  }
};
