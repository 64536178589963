import { BASE_URL } from "../../EnvVariables";
import {
  addGlobalObject,
  listGlobalObject,
  getGlobalObjectCount,
  setGlobalObjectPage,
  setGlobalObjectSize,
  updateGlobalObject,
  setSelectedGlobalObject,
  setSelectedGlobalObjectId,
  allGlobalObjects,
  getSearchGlobalObjectCount
} from "../../actions/globalObject/actions";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import { token } from "../common/auth";

export const addGlobalObjectRequest = (
  name,
  applicationId,
  content = "",
  locator = ""
) => async dispatch => {
  try {
    dispatch(startLoading());
    const body = {
      name: name,
      content: content,
      application_id: applicationId,
      locator: locator
    };
    const response = await fetch(`${BASE_URL}/api/globalObject/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const globalObject = await response.json();
    if (response.status === 201) {
      dispatch(addGlobalObject(globalObject));
      dispatch(getGlobalObjectCountRequest(applicationId));
      dispatch(listGlobalObjectRequest(0,10,"",applicationId));
      dispatch(displayMessage("Global Object added successfully."));
    } else {
      dispatch(
        displayMessage(
          "Could not add the global object. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not add the global object. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const getGlobalObjectCountRequest = (
  applicationId,
  searchText=""
) => async dispatch => {
  dispatch(startLoading());
  console.log("Get global object count request");
  try {
    const globalObjectCountResponse = await fetch(
      `${BASE_URL}/api/globalObject/count?applicationId=${applicationId}&searchValue=${searchText}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const globalObjectCount = await globalObjectCountResponse.json();
    dispatch(getGlobalObjectCount(globalObjectCount.count));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch global object count. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const listGlobalObjectRequest = (
  page = 0,
  size = 10,
  searchText = "",
  applicationId=0
) => async dispatch => {
  try {
    dispatch(startLoading());
    const globalObjectCountResponse = await fetch(
      `${BASE_URL}/api/globalObject/count?applicationId=${applicationId}&searchValue=${searchText}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const globalObjectCount = await globalObjectCountResponse.json();
    dispatch(getSearchGlobalObjectCount(globalObjectCount.count));
    dispatch(setGlobalObjectPage(page));
    dispatch(setGlobalObjectSize(size));
    const response = await fetch(
      `${BASE_URL}/api/globalObject/list?page=${page}&size=${size}&searchValue=${searchText}&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const globalObjects = await response.json();
    dispatch(listGlobalObject(globalObjects));
    if (globalObjects.length > 0) {
      dispatch(setSelectedGlobalObject(globalObjects[0]));
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the global objects. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const updateGlobalObjectRequest = (
  id = 0,
  name = "",
  content = "",
  locator = "",
  applicationId = 0,
) => async dispatch => {
  try {
    const body = {
      id: id,
      name: name,
      content: content,
      application_id: applicationId,
      locator: locator
    };
    const response = await fetch(`${BASE_URL}/api/globalObject/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const globalObject = await response.json();
    if (response.status === 202) {
      dispatch(displayMessage("Global object updated successfully."));
      dispatch(updateGlobalObject(globalObject));
      dispatch(setSelectedGlobalObject(globalObject));
      dispatch(getAllGlobalObjectsRequest(applicationId))
    } else {
      dispatch(
        displayMessage(
          "Could not update the global object. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not update the global object. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const setSelectedGlobalObjectRequest = globalObject => async dispatch => {
  dispatch(setSelectedGlobalObject(globalObject));
};

export const getAllGlobalObjectsRequest = (
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await fetch(`${BASE_URL}/api/globalObject/all?applicationId=${applicationId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      }
    });
    const globalObjects = await response.json();
    dispatch(allGlobalObjects(globalObjects));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the global objects. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const deleteGlobalObjectRequest = (
  id = 0,
  applicationId = 0,
) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await fetch(`${BASE_URL}/api/globalObject/delete?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post"
    });
    if (response.status === 200) {
      dispatch(displayMessage("Global object Deleted successfully."));
      dispatch(listGlobalObjectRequest(0,10,"",applicationId));
      dispatch(getGlobalObjectCountRequest(applicationId))
    } else {
      dispatch(
        displayMessage(
          "Could not Delete the global object. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log("Exception for Delete",e);
    dispatch(
      displayMessage(
        "Could not Delete the global object. Try the operation again."
      )
    );
    dispatch(stopLoading());
  }
};

export const setSelectedGlobalObjectIdRequest = globalObjectId => async dispatch => {
  dispatch(setSelectedGlobalObjectId(globalObjectId));
};
