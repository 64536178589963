import {
  ADD_FILE,
  RESET_FILE,
  LIST_FILE,
  FILE_COUNT,
  SET_FILE_PAGE,
  SET_FILE_SIZE,
  UPDATE_FILE,
  SET_SELECTED_FILE,
  ALL_FILES,
  SET_SELECTED_FILE_ID,
  SEARCH_FILE_COUNT
} from "../../actions/file/actions";

export const files = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_FILE: {
      return state;
    }
    case LIST_FILE: {
      const { files } = payload;
      const filesList = {
        ...state,
        data: files
      };
      return filesList;
    }
    case RESET_FILE: {
      return [];
    }
    case FILE_COUNT: {
      const { count } = payload;
      console.log("------------->",payload)
      const fileCount = {
        ...state,
        count
      };
      return fileCount;
    }
    case SET_FILE_PAGE: {
      const { page } = payload;
      const pageData = {
        ...state,
        page
      };
      return pageData;
    }
    case SET_FILE_SIZE: {
      const { size } = payload;
      const sizeData = {
        ...state,
        size
      };
      return sizeData;
    }
    case UPDATE_FILE: {
      const { file: updatedFile } = payload;
      return {
        ...state,
        data: state.data.map(file => {
          if (file.id === updatedFile.id) {
            return updatedFile;
          }
          return file;
        })
      };
    }
    case SET_SELECTED_FILE: {
      const { selectedFile } = payload;
      const selectedFileData = {
        ...state,
        selectedFile
      };
      return selectedFileData;
    }
    case ALL_FILES: {
      const { files } = payload;
      const filesList = {
        ...state,
        allFiles: files
      };
      return filesList;
    }
    case SET_SELECTED_FILE_ID: {
      const { selectedFileId } = payload;
      const selectedFileData = {
        ...state,
        selectedFileId
      };
      return selectedFileData;
    }
    case SEARCH_FILE_COUNT: {
      const { searchFileCount } = payload;
      const searchFileCounts = {
        ...state,
        searchFileCount
      };
      return searchFileCounts;
    }
    default:
      return state;
  }
};
