import { BASE_URL } from "../../EnvVariables";
import {
  addFile,
  listFile,
  getFileCount,
  setFilePage,
  setFileSize,
  updateFile,
  setSelectedFile,
  setSelectedFileId,
  allFiles,
  getSearchFileCount
} from "../../actions/file/actions";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import { token } from "../common/auth";


export const saveFileRequest = (
  selectedFileId,
  fileContent,
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());

    var data = new FormData();
    data.append("id",selectedFileId)
    data.append("applicationId", applicationId)
    data.append("file", fileContent);
    const response = await fetch(`${BASE_URL}/api/files/upload`, {
      headers: {
        "accept": "*/*",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: data
    });

    const File = await response.json();
    if (response.status === 201 || 200) {
      dispatch(addFile(File));
      dispatch(listFileRequest(0, 10,"", applicationId));
      dispatch(displayMessage("File added successfully."));
    } else {
      dispatch(
        displayMessage(
          "Could not add the File. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not add the File. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const getFileCountRequest = (
  applicationId
) => async dispatch => {
  dispatch(startLoading());
  console.log("Get File count request");
  try {
    const FileCountResponse = await fetch(
      `${BASE_URL}/api/files/count?applicationId=${applicationId}&searchValue=`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const FileCount = await FileCountResponse.json();
    dispatch(getFileCount(FileCount.count));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch File count. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const listFileRequest = (
  page = 0,
  size = 10,
  searchValue="",
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());
    const fileCountResponse = await fetch(
      `${BASE_URL}/api/files/count?applicationId=${applicationId}&searchValue=${searchValue}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const fileCount = await fileCountResponse.json();
    dispatch(getSearchFileCount(fileCount.count));
    dispatch(setFilePage(page));
    dispatch(setFileSize(size));
    const response = await fetch(
      `${BASE_URL}/api/files/list?page=${page}&size=${size}&applicationId=${applicationId}&searchValue=${searchValue}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const Files = await response.json();
    dispatch(listFile(Files));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the Files. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const replaceFileRequest = (
  id,
  fileContent,
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());

    var data = new FormData();
    data.append("id", id)
    data.append("applicationId", applicationId)
    data.append("file", fileContent);
    const response = await fetch(`${BASE_URL}/api/files/upload`, {
      headers: {
        "accept": "*/*",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: data
    });

    
    const File = await response.json();
    if (response.status === 202) {
      dispatch(displayMessage("File replaced successfully."));
      dispatch(updateFile(File));
      dispatch(setSelectedFile(File));
      dispatch(getAllFilesRequest(applicationId));
    } else {
      dispatch(
        displayMessage(
          "Could not replace the File. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not replace the File. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const setSelectedFileRequest = File => async dispatch => {
  dispatch(setSelectedFile(File));
};

export const getAllFilesRequest = (
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await fetch(`${BASE_URL}/api/files/all?applicationId=${applicationId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      }
    });
    const Files = await response.json();
    dispatch(allFiles(Files));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the Files. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const deleteFileRequest = (
  selectedFileId = 0,
  applicationId
) => async dispatch => {
  try {

    const response = await fetch(`${BASE_URL}/api/files/delete/${selectedFileId}`, {
      headers: {
        "accept": "*/*",
        Authorization: "Bearer " + token()
      },
      method: 'post'
    });

    if (response.status === 200) {
      dispatch(listFileRequest(0, 10, "", applicationId));
      dispatch(displayMessage("File deleted successfully."));
    } else {
      dispatch(
        displayMessage("Could not delete the File. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not delete the File. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const setSelectedFileIdRequest = FileId => async dispatch => {
  dispatch(setSelectedFileId(FileId));
};

export const downloadFileRequest = (
  selectedFileId = 0,
  applicationId,
  fileName
) => async dispatch => {
  try {

    const response = await fetch(`${BASE_URL}/api/files/download/${selectedFileId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: 'get'
    });

    if (response.status === 200) {

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

      dispatch(listFileRequest(0, 10, "", applicationId));
      dispatch(displayMessage("File download successfully."));
    } else {
      dispatch(
        displayMessage("Could not download the File. Try the operation again")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not download the File. Try the operation again")
    );
    dispatch(stopLoading());
  }
};