import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import { connect } from "react-redux";
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PlanDashboard from './PlanDashboard';
import ExecutionDashboard from './ExecutionDashboard';
import DefectDashboard from './DefectDashboard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const Dashboard = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
    <Box sx={{  display: { xs: 'block', md: 'none' } }}>
      <Tabs
        value={value}
        onChange={handleChange}
        scrollButtons="on"
        indicatorColor="secondary"
        textColor="white"
        aria-label="project roles tab"
        style={{ backgroundColor:'#eee', color: 'black', width: '360px', marginLeft: '20px', marginTop: '30px' }}
      >
        <Tab label="Plan Dash" {...a11yProps(0)} />
        <Tab label="Execution Dash" {...a11yProps(1)} />
        <Tab label="Defect Dash" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <PlanDashboard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExecutionDashboard />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DefectDashboard />
      </TabPanel>
    </Box>

<Box sx={{  display: { xs: 'none', md: 'block' } }}>
<Tabs
  value={value}
  onChange={handleChange}
  scrollButtons="on"
  indicatorColor="secondary"
  textColor="white"
  aria-label="project roles tab"
  style={{ backgroundColor:'#eee', color: 'black', width: '570px', marginLeft: '400px', marginTop: '30px' }}
>
  <Tab label="Planning Dashboard" {...a11yProps(0)} />
  <Tab label="Execution Dashboard" {...a11yProps(1)} />
  {/* <Tab label="Defect Dashboard" {...a11yProps(2)} /> */}
</Tabs>
<TabPanel value={value} index={0}>
<PlanDashboard />
</TabPanel>
<TabPanel value={value} index={1}>
  <ExecutionDashboard />
</TabPanel>
{/* <TabPanel value={value} index={2}>
  <DefectDashboard />
</TabPanel> */}
</Box>
</div>
    
  );
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);