/**
 * Created by prakumar11 on 09/09/21.
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const colors = scaleOrdinal(schemeCategory10).range();

const data = [
    {
        name: 'Release 1',
        uv: 4000
    },
    {
        name: 'Release 2',
        uv: 3000
    },
    {
        name: 'Release 3',
        uv: 2000
    },
    {
        name: 'Release 4',
        uv: 2780
    },
    {
        name: 'Release 5',
        uv: 1890
    },
    {
        name: 'Release 6',
        uv: 2390
    },
    {
        name: 'Release 7',
        uv: 3490
    },
];

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default class Chart4 extends PureComponent {

    render() {
        return (
                <BarChart
                    width={700}
                    height={300}
                    data={data}
                    margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 5,
          }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Bar dataKey="uv" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))}
                    </Bar>
                </BarChart>
        );
    }
}
