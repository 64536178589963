import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import { connect } from "react-redux";
import { updateGlobalObjectRequest } from "../../thunks/globalObject/thunks";
import { getSelectedGlobalObject } from "../../selectors/globalObject/selector";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-gherkin";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/gherkin";
import TextField from "@material-ui/core/TextField";
import { hasReadOnlyRole } from '../../thunks/common/auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  buttonGroupPosition: {
    position: "absolute",
    top: theme.spacing(10),
    right: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2)
  }
}));

const TabPanel = props => {
  const { children, value, index } = props;

  return (
    <Typography hidden={value !== index} id={`globalObject-${index}`}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const currentTab = index => {
  return {
    id: `globalObject-tab-${index}`
  };
};

const GlobalObjectTab = ({ updateGlobalObject, selectedGlobalObject}) => {
  const [value, setValue] = React.useState(0);
  const [locator, setLiveLocator] = React.useState("");
  const [content, setContent] = React.useState("");
  const [globalObjectName, setGlobalObjectName] = React.useState("");
  const [show, setShow] = React.useState('true');

  useEffect(() => {
    if (selectedGlobalObject) {
      if (selectedGlobalObject.locator !== null) {
        setLiveLocator(selectedGlobalObject.locator)
      }
       else {
        setLiveLocator("");
      }
      setGlobalObjectName(selectedGlobalObject.name);
    }
  }, [selectedGlobalObject]);

  const handleChange = ( newValue) => {
    setValue(newValue);
  };

  function isValidJSONString(str) {
    try {
      if(str !== ""){
      JSON.parse(str);
      }
    } catch (e) {
      console.log(e);
      return e;
    }
    return true;
  }

  const classes = useStyles();

  function SubmitButton(){
    if (isValidJSONString(locator) == true ){
      return  <Button
      color="primary"
      startIcon={<SaveIcon />}
      variant="contained"
      className={classes.button}
      onClick={() => {
          updateGlobalObject(
            selectedGlobalObject,
            globalObjectName,
            content,
            locator); }}
      disabled={hasReadOnlyRole()}
    > Update</Button>
    } else {
      return  <Button
      color="primary"
      startIcon={<SaveIcon />}
      variant="contained"
      className={classes.button}
      disabled>Update </Button>
    }
  };

  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box className={classes.root}>
          <TextField
            id="globalObjectName"
            label="Global Object Name"
            style={{ margin: 12 }}
            fullWidth
            value={globalObjectName}
            onChange={e => setGlobalObjectName(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Locators" {...currentTab(0)} />
            {isValidJSONString(locator) != true && 
         <Typography variant="caption" color="error" align="right" style= {{marginLeft: '50px', marginTop: '12px'}} > Invalid json format, please update the file and retry. </Typography>
          }
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={locator}
              onChange={
                newLocator => {
                  setLiveLocator(newLocator);
                }}
              name="locatorEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
          <div style={{ marginTop: '15px' }}>
            <Chip label="View File History" style={{ backgroundColor: '#f50057', color: 'white', marginRight: '10px' }} onClick={() => setShow(prev => !prev)} />
            {!show && <Typography variant="caption" color="Inherit" align="center" >
              Locator File: {selectedGlobalObject.name} was Created By <strong>{selectedGlobalObject.created_by}</strong> On {selectedGlobalObject.created_date_time} and Last Updated By <strong>{selectedGlobalObject.updated_by}</strong> On {selectedGlobalObject.updated_date_time}
            </Typography>}
          </div>
        </TabPanel>
        <ButtonGroup color="primary" className={classes.buttonGroupPosition}>
       <SubmitButton />
        </ButtonGroup>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box className={classes.root}>
          <TextField
            id="globalObjectName"
            label="Global Object Name"
            style={{ margin: 12 }}
            fullWidth
            value={globalObjectName}
            onChange={e => setGlobalObjectName(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <ButtonGroup color="primary" >
        <SubmitButton />
        </ButtonGroup>
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Locators" {...currentTab(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box>
            <AceEditor
              mode="json"
              theme="monokai"
              fontSize={14}
              style={{ height: '550px' }}
              value={locator}
              onChange={
                newLocator => {
                  setLiveLocator(newLocator);
                }}
              name="locatorEditor"
              width="100%"
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2
              }}
            />
          </Box>
          <div style={{ marginTop: '15px' }}>
            <Chip label="View File History" style={{ backgroundColor: '#f50057', color: 'white', marginRight: '10px' }} onClick={() => setShow(prev => !prev)} />
            {!show && <Typography variant="caption" color="Inherit" align="center" >
              Locator File: {selectedGlobalObject.name} was Created By <strong>{selectedGlobalObject.created_by}</strong> On {selectedGlobalObject.created_date_time} and Last Updated By <strong>{selectedGlobalObject.updated_by}</strong> On {selectedGlobalObject.updated_date_time}
            </Typography>}
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedGlobalObject: getSelectedGlobalObject(state)
});

const mapDispatchToProps = dispatch => ({
  updateGlobalObject: (
    globalObject,
    globalObjectName,
    content,
    locator
  ) => {
    dispatch(
      updateGlobalObjectRequest(
        globalObject.id,
        globalObjectName,
        content,
        locator,
        globalObject.application.id
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalObjectTab);
