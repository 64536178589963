import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import {
  listBusinessScenarioRequest,
  setSelectedBusinessScenarioRequest
} from "../../thunks/businessScenario/thunks";
import AddBusinessScenario from "./AddBusinessScenario";
import { map } from "lodash";
import {
  getBusinessScenarios,
  getBusinessScenarioCount,
  getPage,
  getSize
} from "../../selectors/businessScenario/selector";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import DisplayDate from "../common/DisplayDate";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import BusinessScenarioTab from "./BusinessScenarioTab";
import BusinessScenarioAlertDialog from "./BusinessScenarioDialog"
import { hasReadWriteRole, hasAdminRole, hasProjectAdminRole } from '../../thunks/common/auth';
import { getSelectedApplication } from "../../selectors/application/selector";

const BusinessScenarioList = ({
  loadBusinessScenarioFromServer,
  setSelectedBusinessScenario,
  businessScenarios = [],
  count = 0,
  page = 0,
  size = 10,
  applicationId,
  applicationName
}) => {
  useEffect(() => {
    loadBusinessScenarioFromServer(page, size, applicationId);
  }, [loadBusinessScenarioFromServer, page, size, applicationId]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [deleteBusinessScenario, setDeleteBusinessScenario] = React.useState(0);

  const handleListItemClick = index => {
    setSelectedIndex(index);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    purple: {
      backgroundColor: '#3f51b5'
    }
  }));

  const classes = useStyles();

  const onChangePage = (event, pageSelected) => {
    loadBusinessScenarioFromServer(pageSelected, size, applicationId);
    setSelectedIndex(0);
  };

  return (
    <div>
    <Box  sx={{  display: { xs: 'none', md: 'block' } }}>
      <Grid container>
        <Grid item xs={4}>
          <Box mt={1} mr={2}>
            <List className={classes.root} component="nav">
              {map(businessScenarios, (businessScenario, index) => (
                <ListItem
                  divider
                  button
                  selected={selectedIndex === index}
                  onClick={event => {
                    handleListItemClick(index);
                    setSelectedBusinessScenario(businessScenarios[index]);
                    setDeleteBusinessScenario(businessScenarios[index]);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.purple}>
                      <BusinessCenterIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={businessScenario.name}
                    secondary={
                      <DisplayDate
                        dateString={businessScenario.created_date_time}
                        prefixString="Created on "
                      />
                    }
                  />
                  <Grid item xs={12} sm={2} style={{paddingRight: '140px'}} >
                        <Chip label={applicationName} style={{backgroundColor: '#f50057', color: 'white'}}/>
                      </Grid>
                  {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                    <Grid item xs={12} sm={1}>
                      <BusinessScenarioAlertDialog dialogLabel='Business Scenario' selectedBusinessScenarioId={deleteBusinessScenario.id} businessScenarioName={deleteBusinessScenario.name} />
                    </Grid>
                  )}
                </ListItem>
              ))}
            </List>
            <Box display="flex" flexDirection="row-reverse">
              <AddBusinessScenario />
              <TablePagination
                rowsPerPageOptions={[size]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onChangePage={onChangePage}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box mt={2} mr={2}>
            <BusinessScenarioTab />
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box  sx={{  display: { xs: 'block', md: 'none' } }}>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={1} mr={2}>
            <List className={classes.root} component="nav">
              {map(businessScenarios, (businessScenario, index) => (
                <ListItem
                  divider
                  button
                  selected={selectedIndex === index}
                  onClick={event => {
                    handleListItemClick(index);
                    setSelectedBusinessScenario(businessScenarios[index]);
                    setDeleteBusinessScenario(businessScenarios[index]);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.purple}>
                      <BusinessCenterIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant="body2" color="inherit" style={{ width: '400px' }}>{businessScenario.name}
                   </Typography>}
                    secondary={<Typography variant="body2" color="textSecondary" style={{ width: '400px' }}> <DisplayDate
                    dateString={businessScenario.created_date_time} />
                   </Typography>}
                  />
                  <Grid item xs={12} sm={2} style={{paddingLeft: '200px', paddingRight: '20px'}} >
                        {/* <Chip label={applicationName} style={{backgroundColor: '#f50057', color: 'white'}}/> */}
                      </Grid>
                  {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                    <Grid item xs={12} sm={1}>
                      <BusinessScenarioAlertDialog dialogLabel='Business Scenario' selectedBusinessScenarioId={deleteBusinessScenario.id} businessScenarioName={deleteBusinessScenario.name} />
                    </Grid>
                  )}
                </ListItem>
              ))}
            </List>
            <Box display="flex" flexDirection="row-reverse">
              <AddBusinessScenario />
              <TablePagination
                rowsPerPageOptions={[size]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onChangePage={onChangePage}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mr={2}>
            <BusinessScenarioTab />
          </Box>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  businessScenarios: getBusinessScenarios(state),
  count: getBusinessScenarioCount(state),
  page: getPage(state),
  size: getSize(state),
  applicationId: getSelectedApplication(state).id,
  applicationName: getSelectedApplication(state).name
});

const mapDispatchToProps = dispatch => ({
  loadBusinessScenarioFromServer: (page, size, applicationId) => {
    dispatch(listBusinessScenarioRequest(page, size, applicationId));
  },
  setSelectedBusinessScenario: businessScenario => {
    dispatch(setSelectedBusinessScenarioRequest(businessScenario));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessScenarioList);
