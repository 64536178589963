import React, { useEffect } from "react";
import GlobalObjectList from "./GlobalObjectList";
import EmptyGlobalObject from "./EmptyGlobalObject";
import { connect } from "react-redux";
import { getGlobalObjectCount } from "../../selectors/globalObject/selector";
import { getGlobalObjectCountRequest } from "../../thunks/globalObject/thunks";
import { getSelectedApplication } from "../../selectors/application/selector";

const GlobalObject = ({ loadCountFromServer, count, applicationId }) => {
  useEffect(() => {
    loadCountFromServer(applicationId);
  }, [loadCountFromServer, applicationId]);

  const globalObjectComponent = () => {
    if (count !== 0) {
      return <GlobalObjectList />;
    }
    return <EmptyGlobalObject />;
  };

  return globalObjectComponent();
};

const mapStateToProps = state => ({
  count: getGlobalObjectCount(state),
  applicationId : getSelectedApplication(state).id  
});

const mapDispatchToProps = dispatch => ({
  loadCountFromServer: (applicationId) => {
    dispatch(getGlobalObjectCountRequest(applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalObject);
  