import React, { Component } from 'react'
import Cron from 'react-cron-generator'
import '../../styles.css'
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class CronePicker extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (<div>
      <Box ml={2} >
        <FormControlLabel
          label=""
          control={
            <FormControl>
              <InputLabel id="script-select-label">
                Select Run Environment
              </InputLabel>
              <Cron>
                onChange={(e) => { this.setState({ value: e }); }}
                value={this.state.value}
                showResultText={true}
                showResultCron={true}
              </Cron>
            </FormControl>
          }
        />
      </Box>

    </div>)
  }
}

export default CronePicker;