import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import ToolTip from "@material-ui/core/Tooltip";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from "react-redux";
import { deleteApplicationRequest } from "../../thunks/application/thunks";
import { hasAdminRole, hasReadWriteRole, isNotProjectAdmin } from '../../thunks/common/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    del: {
      margin: 5,
    }
  }));

const AlertDialog = ({ dialogLabel, selectedApplicationId, ApplicationName, deleteSelectedApplication }) => {
    const handleDialog = () => {
        setOpen(true);
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOk = (selectedApplicationId) => {
        deleteSelectedApplication(selectedApplicationId,ApplicationName);
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };
     
    const classes = useStyles();

    return (
        <div>
            {hasReadWriteRole() && hasAdminRole()  &&isNotProjectAdmin() &&(
              <ToolTip title="Delete Project">
            <Fab
          size="small"
          color="primary"
          aria-label="edit"
          className={classes.del}
          onClick={handleDialog}
        >
            <DeleteOutlineIcon /></Fab></ToolTip>)}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancel}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure, you want to delete the {dialogLabel} '{ApplicationName}' ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClickOk(selectedApplicationId)} color="primary" variant="outlined">
                        Ok
                </Button>
                    <Button onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        
    );
}


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    deleteSelectedApplication: (selectedApplicationId,ApplicationName) => {
        dispatch(deleteApplicationRequest(selectedApplicationId,ApplicationName));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
