import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { connect } from "react-redux";
import { getSelectedApplication } from "../../selectors/application/selector";
import ToolTip from "@material-ui/core/Tooltip";
import { downloadFileRequest } from '../../thunks/supportiveFiles/thunks';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DownloadFileDialog = ({ dialogLabel, selectedFileId, fileName, downloadSelectedFile, applicationId }) => {
    const handleDialog = () => {
        setOpen(true);
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOk = (selectedFileId) => {
         downloadSelectedFile(selectedFileId,applicationId,fileName);
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };


    return (
        <div>
            <ToolTip title="Download File">
            <GetAppOutlinedIcon onClick={handleDialog} />
            </ToolTip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancel}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure, you want to download the {dialogLabel} '{fileName}' ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClickOk(selectedFileId)} color="primary" variant="outlined">
                        Ok
                </Button>
                    <Button onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const mapStateToProps = state => ({
    applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
    downloadSelectedFile: (selectedFileId,applicationId,fileName) => {
        dispatch(downloadFileRequest(selectedFileId,applicationId,fileName));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadFileDialog);
