import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chart4 from './Chart4'
import Chart5 from './Chart5'
import Chart6 from './Chart6'
import RunTab from './RunTable'
import { map } from "lodash";
import {
  getRuns,
  getPage,
  getSize,
} from "../../selectors/run/selector";
import {listallRunRequest} from "../../thunks/run/thunks";
import { connect } from "react-redux";
import {getBusinessScenarios} from "../../selectors/businessScenario/selector";
import { getSelectedApplication } from "../../selectors/application/selector";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    marginLeft: '50px',
    marginRight: '20px',
  },
  details: {
    textAlign: 'center',
    border: '1px solid lightgrey'
  },
  box: {
    marginTop: "60px",
    marginLeft: '50px',
    marginRight: '20px',
    width: "flex"
  },
  header: {
    float: 'left',
    paddingBottom: '106px'
  },
  chart: {
    flexGrow: 1,
    padding: '20px',
  }
}));

const ExecutionDashboard = ({
  runs = [],
  avg_duration = 0,
  loadRunsFromServer,
  page = 0,
  size = 10,
  applicationId,
  release = [],
  releaseData =[],
  releaseName = [],
  totalScenarioCount = 0,
  total_scenario_count=[],
  totalScenarioPassCount = 0,
  totalBldCount = 0,
  totalScenarioFailCount=0
}) => {

  useEffect(() => {
    loadRunsFromServer(applicationId);
  }, [loadRunsFromServer, applicationId]);

  const classes = useStyles();

  map(runs, (run) => (
    avg_duration += run.run_duration / 1000000000,
    totalScenarioCount += run.scenario_count,
    totalScenarioPassCount += run.scenario_pass_count,
    totalScenarioFailCount += run.scenario_fail_count,
    totalBldCount = runs.length
  ))

  function createData(ReleaseName, ScenarioPassCount, ScenarioFailCount, ScenarioSkipCount) {
    return {ReleaseName,ScenarioPassCount, ScenarioFailCount,ScenarioSkipCount };
  }

  function passBuildCount() {
    const pass = runs.filter(function (run) { return run.status == "SUCCESS" });
    return pass.length
  }


  map(release, (rel) => (
    releaseName.push(rel.name)
  ))

  releaseName.forEach(function(rel) {
    releaseData.push(runs.filter(run => run.details === rel))});


  const passBldCount = passBuildCount();
console.log(releaseData);


releaseData.forEach(function(rel) { total_scenario_count.push ( rel.reduce(function (previousValue, currentValue) {
  return  previousValue + currentValue.scenario_count;
}, 0))})


  return (
    <div className={classes.root}>

      <Grid container spacing={3}>

        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{ backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit">
                  Average Build Execution Time
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  {(avg_duration/totalBldCount).toFixed(2)}<small>s</small>
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                  Average Build Pass Rate
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  {(passBldCount / totalBldCount).toFixed(2) * 100}%
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card >
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                 Overall Scenario Pass Rate
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  {(totalScenarioPassCount/totalScenarioCount * 100).toFixed(2)}%
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{ backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                Overall Scenario Fail Rate
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                {(totalScenarioFailCount/totalScenarioCount * 100).toFixed(2)}%
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>

        <Grid container >
          {/* <Grid item xs={6} style={{ paddingTop: '30px', paddingLeft: '18px' }} >
            <Card>
              <Chart6 className={classes.chart} />
              <div >
                <CardContent>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Execution status by release
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid> */}
          {/* <Grid item xs={6} style={{ paddingTop: '30px' }} >
            <Card>
              <Chart6 className={classes.chart} />
              <div >
                <CardContent>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Execution status by cycle
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.box}>
              <Chart5 className={classes.chart} />
              <div >
                <CardContent>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Release Execution Percentage
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.box}>
              <Chart4 className={classes.chart} />
              <div >
                <CardContent>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Latest Build Execution Status
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <Card className={classes.box}>
              <Chart5 className={classes.chart} />
              <div >
                <CardContent>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Latest Cycle Execution Status
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid> */}
        </Grid>
        <Grid item xs={12} style={{marginTop: '20px'}}>
          <RunTab/>
          </Grid>
      </Grid>
    </div>

  );
};


const mapStateToProps = state => ({
  runs: getRuns(state),
  release: getBusinessScenarios(state),
  applicationId: getSelectedApplication(state).id,
  page: getPage(state),
  size: getSize(state)
});

const mapDispatchToProps = dispatch => ({
  loadRunsFromServer: (applicationId) => {
    dispatch(listallRunRequest(applicationId));
  }
});


export default connect(mapStateToProps,mapDispatchToProps)(ExecutionDashboard);

