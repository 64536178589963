import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EditSharpIcon from '@material-ui/icons/EditSharp';
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { addOrEditApplicationRequest } from "../../thunks/application/thunks.js";
import { syncApplicationRequest } from "../../thunks/application/thunks.js";
import { getSelectedApplication } from "../../selectors/application/selector.js";
import { hasAdminRole, hasReadWriteRole,hasProjectAdminRole } from '../../thunks/common/auth';
import { GitHub } from "@material-ui/icons";
import SyncApplication from "./SyncApplication.js";
import PushApplication from "./PushApplication.js";


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0),
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const GitActions = () => {

  const [isHovered, setIsHovered] = React.useState(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  // const buttonStyle = {
  //   border: 'none',
  //   backgroundColor: '#3f51b5',
   
  // };


  const classes = useStyles();
  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) &&(
      <ToolTip title="Git Actions">
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          className={classes.button}
        
        >
          Git
          {/* <GitHub/> */}
        </Fab>
      </ToolTip>)}

      {isHovered && (
        <div>
          <button /*style ={buttonStyle} */ > <SyncApplication/> </button>
          <button /*style ={buttonStyle} */ > <PushApplication/> </button>
        </div>
      )}
    </Box>
  );
};

export default GitActions;
