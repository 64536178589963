import { BASE_URL, MEILISEARCH_BASE_URL } from "../../EnvVariables";
import {
  addFeature,
  getAllFeature,
  listFeature,
  getFeatureCount,
  setFeaturePage,
  setFeatureSize,
  updateFeature,
  setSelectedFeature,
  listIncludeFeature,
  getIncludeFeatureCount,
  setIncludeFeaturePage,
  setIncludeFeatureSize,
  listAssociatedFeature,
  listIncludeFile,
  getIncludeFileCount,
  setIncludeFilePage,
  setIncludeFileSize,
  listAssociatedFile,
  getSearchFeatureCount
} from "../../actions/feature/actions";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import { token } from "../common/auth";
import MeiliSearch from "meilisearch";

const client = new MeiliSearch({
  host: MEILISEARCH_BASE_URL,
  apiKey: 'masterKey',
});


export const addFeatureRequest = (
  name,
  featureType = "KARATE",
  applicationId = 0,
  content = "",
  xpath = ""
) => async dispatch => {
  try {
    dispatch(startLoading());
    const body = {
      name: name,
      content: content,
      xpath: xpath,
      feature_type: featureType,
      application_id: applicationId
    };
    const response = await fetch(`${BASE_URL}/api/feature/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token(),
        "Access-Control-Allow-Origin": "*"
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const feature = await response.json();
    if (response.status === 200) {
      dispatch(addFeature(feature));
      dispatch(getFeatureCountRequest(applicationId));
      dispatch(listFeatureRequest(0,10,"",applicationId));
      dispatch(displayMessage("Feature added successfully."));
    } else {
      dispatch(
        displayMessage("Could not add the feature. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not add the feature. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const allFeatureRequest = (
  applicationId = 0
) => async dispatch => {
  try {
    dispatch(startLoading());
    const allFeatureResp = await fetch(
      `${BASE_URL}/api/feature/all/${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const featureCount = await allFeatureResp.json();
    console.log("all features", featureCount);
    dispatch(getAllFeature(featureCount));
    //if (features.length > 0) {
      //dispatch(setSelectedFeature(features[0]));
    //}
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the features. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const getFeatureCountRequest = (
  applicationId = 0
) => async dispatch => {
  dispatch(startLoading());
  console.log("Get feature count request");
  try {
    const featureCountResponse = await fetch(
      `${BASE_URL}/api/feature/count?contains=&notIn=0&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const featureCount = await featureCountResponse.json();
    dispatch(getFeatureCount(featureCount.count));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch feature count. Try the operation again")
    );
    dispatch(stopLoading());
  }
};
export const getSearchFacetDistributions = () => async dispatch => {
  try{
    const index = client.getIndex("features");
    const filters = await index.getAttributesForFaceting();
    const facets = await index.search(null, {
      //  facetFilters: ['featureType:anoroc', 'applicationName:anoroc demo'],
      facetsDistribution: filters
    })
    return facets;
  }catch(e){

  }
};

//The below method is with Millisearch Integartion
/*export const listFeatureRequestMillisearch = (
  page = 0,
  size = 10,
  searchStr = "",
  filters = ""
) => async dispatch => {
  try {
    console.log("search strig"+ searchStr);
    dispatch(startLoading());
    const index = client.getIndex("features");
    const facettingAttributes = await index.getAttributesForFaceting();
    
    let facetFilters = "";
  if(filters === "" || filters.length === 0){
    facetFilters = {
      facetsDistribution: facettingAttributes
  }
  }else{
    facetFilters = {
      facetFilters: filters,
      facetsDistribution: facettingAttributes
  }
  }
    const search = await index.search(searchStr, facetFilters);
    if(search.nbHits === 0){
      dispatch(
        displayMessage("No Result found")
      );
    }else{
    dispatch(getFeatureCount(search.nbHits));
    dispatch(setFeaturePage(page));
    dispatch(setFeatureSize(size));
    const features = search.hits
    dispatch(listFeature(features));
    dispatch(searchParameters(search));
    if (features.length > 0) {
      dispatch(setSelectedFeature(features[0]));
    }
  }
    dispatch(stopLoading());
  } catch (e) {
    dispatch(
      displayMessage("Could not fetch the features. Try the operation again")
    );
    dispatch(stopLoading());
  }
};
*/

export const listFeatureRequest = (
  page = 0,
  size = 10,
  searchByName = "",
  applicationId = 0
) => async dispatch => {
  try {
    dispatch(startLoading());
    const featureCountResponse = await fetch(
      `${BASE_URL}/api/feature/count?contains=${searchByName}&notIn=0&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const featureCount = await featureCountResponse.json();
    dispatch(getSearchFeatureCount(featureCount.count));
    dispatch(setFeaturePage(page));
    dispatch(setFeatureSize(size));
    const response = await fetch(
      `${BASE_URL}/api/feature/list?page=${page}&size=${size}&name=${searchByName}&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const features = await response.json();
    dispatch(listFeature(features));
    if (features.length > 0) {
      dispatch(setSelectedFeature(features[0]));
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the features. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const listIncludeFeatureRequest = (
  page = 0,
  size = 10,
  searchText = "",
  selectedFeatureId = 0,
  applicationId = 0
) => async dispatch => {
  try {
    dispatch(startLoading());
    console.log("Get list of include feature request");
    const featureCountResponse = await fetch(
      `${BASE_URL}/api/feature/count?contains=${searchText}&notIn=${selectedFeatureId}&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const featureCount = await featureCountResponse.json();
    dispatch(getIncludeFeatureCount(featureCount.count));
    dispatch(setIncludeFeaturePage(page));
    dispatch(setIncludeFeatureSize(size));
    const response = await fetch(
      `${BASE_URL}/api/feature/list?page=${page}&size=${size}&notIn=${selectedFeatureId}&name=${searchText}&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const features = await response.json();
    dispatch(listIncludeFeature(features));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the features. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const listIncludeFileRequest = (
  page = 0,
  size = 10,
  searchText = "",
  applicationId = 0
) => async dispatch => {
  try {
    dispatch(startLoading());
    console.log("Get list of include file request");
    const featureCountResponse = await fetch(
      `${BASE_URL}/api/files/count?applicationId=${applicationId}&searchValue=${searchText}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const featureCount = await featureCountResponse.json();
    dispatch(getIncludeFileCount(featureCount.count));
    dispatch(setIncludeFilePage(page));
    dispatch(setIncludeFileSize(size));
    const response = await fetch(
      `${BASE_URL}/api/files/list?page=${page}&size=${size}&applicationId=${applicationId}&searchValue=${searchText}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const features = await response.json();
    dispatch(listIncludeFile(features));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the features. Try the operation again")
    );
    dispatch(stopLoading());
  }
};


export const saveFeatureFileRequest = (
  featureId = 0,
  includeFiles = []
) => async dispatch => {
  try {
    const body = {
      featureId: featureId,
      include_files: includeFiles
    };
    const response = await fetch(`${BASE_URL}/api/feature/linkFiles`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token(),
        "Access-Control-Allow-Origin": "*"
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const feature = await response.json();
    if (response.status === 200) {
      dispatch(displayMessage("Feature updated successfully."));
      dispatch(updateFeature(feature));
      dispatch(setSelectedFeature(feature));
    } else {
      dispatch(
        displayMessage("Could not update the feature. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not update the feature. Try the operation again")
    );
    dispatch(stopLoading());
  }
};


export const updateFeatureRequest = (
  id = 0,
  name = "",
  content = "",
  xpath = "",
  featureType = "KARATE",
  applicationId = 0,
  includeFeatures = []
) => async dispatch => {
  try {
    const body = {
      id: id,
      name: name,
      content: content,
      xpath: xpath,
      feature_type: featureType,
      application_id: applicationId,
      include_features: includeFeatures
    };
    const response = await fetch(`${BASE_URL}/api/feature/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token(),
        "Access-Control-Allow-Origin": "*"
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const feature = await response.json();
    if (response.status === 200) {
      dispatch(displayMessage("Feature updated successfully."));
      dispatch(updateFeature(feature));
      dispatch(setSelectedFeature(feature));
    } else {
      dispatch(
        displayMessage("Could not update the feature. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not update the feature. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const associatedFeaturesRequest = selectedFeatureId => async dispatch => {
  console.log("Get associated feature request");
  try {
    dispatch(startLoading());
    const response = await fetch(
      `${BASE_URL}/api/feature/associatedList/${selectedFeatureId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const associatedFeatures = await response.json();
    dispatch(listAssociatedFeature(associatedFeatures));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the features. Try the operation again")
    );
    dispatch(stopLoading());
  }
};


export const associatedFilesRequest = selectedFeatureId => async dispatch => {
  console.log("Get associated File request");
  try {
    dispatch(startLoading());
    const response = await fetch(
      `${BASE_URL}/api/files/associatedFilesList/${selectedFeatureId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token(),
          "Access-Control-Allow-Origin": "*"
        },
        method: "get"
      }
    );
    const associatedFiles = await response.json();
    dispatch(listAssociatedFile(associatedFiles));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not fetch the files. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const deleteFeatureRequest = (
  selectedFeatureId = 0,
  applicationId = 0
) => async dispatch => {
  try {
    const body = {
      id: selectedFeatureId
    };
    const response = await fetch(`${BASE_URL}/api/feature/delete`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token(),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
      },
      method: "post",
      body: JSON.stringify(body)
    });
    // const feature = await response.json();
    if (response.status === 200) {
      dispatch(listFeatureRequest(0,10,"",applicationId));
      dispatch(getFeatureCountRequest(applicationId));
      dispatch(displayMessage("Feature deleted successfully."));
    } else {
      dispatch(
        displayMessage("Could not delete the feature. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not delete the feature. Try the operation again")
    );
    dispatch(stopLoading());
  }
};

export const setSelectedFeatureRequest = feature => async dispatch => {
  dispatch(setSelectedFeature(feature));
};
