import React, { useEffect } from "react";
import BusinessScenarioList from "./BusinessScenarioList";
import EmptyBusinessScenario from "./EmptyBusinessScenario";
import { connect } from "react-redux";
import { getBusinessScenarioCount } from "../../selectors/businessScenario/selector";
import { getBusinessScenarioCountRequest } from "../../thunks/businessScenario/thunks";
import { getSelectedApplication } from "../../selectors/application/selector";

const BusinessScenario = ({ loadCountFromServer, count, applicationId }) => {
  useEffect(() => {
    loadCountFromServer(applicationId);
  }, [loadCountFromServer, applicationId]);

  const businessScenarioComponent = () => {
    if (count !== 0) {
      return <BusinessScenarioList />;
    }
    return <EmptyBusinessScenario />;
  };

  return businessScenarioComponent();
};

const mapStateToProps = state => ({
  count: getBusinessScenarioCount(state),
  applicationId : getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  loadCountFromServer: (applicationId) => {
    dispatch(getBusinessScenarioCountRequest(applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessScenario);
