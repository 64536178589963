import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNewRounded'
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { deleteFlagUpdateRequest, zipReportFolder } from "../../thunks/run/thunks";
import TablePagination from "@material-ui/core/TablePagination";
import { at, map } from "lodash";
import { useState, useCallback } from "react";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import {
  setSelectedRunRequest,
  listRunRequest,
} from "../../thunks/run/thunks";
import {
  getRuns,
  getRunCount,
  getPage,
  getSize,
  getSelectedRun
} from "../../selectors/run/selector";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import DisplayDate from "../common/DisplayDate";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LinearProgress from "@material-ui/core/LinearProgress";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { getSelectedApplication } from "../../selectors/application/selector";
import { REPORT_URL,SELENIUM_GRID_URL} from "../../EnvVariables";
import { Tooltip, withStyles } from '@material-ui/core';
import {token} from "../../thunks/common/auth";
import {
  startLoading,
  stopLoading,
  displayMessage
} from "../../actions/common/actions";
import { BASE_URL } from "../../EnvVariables";
const RunList = ({
  selectedRun,
  loadRunsFromServer,
  deleteFlagUpdate,
  zippedReports,
  setSelectedRun,
  runs = [],
  count = 0,
  page = 0,
  size = 10,
  applicationId
}) => {

  useEffect(() => {
    loadRunsFromServer(page, size, applicationId);
  }, [loadRunsFromServer, page, size, applicationId]);

  useEffect(() => {
    if (selectedRun) {
      setReportUrl(REPORT_URL + selectedRun.report_url);
    }
  }, [selectedRun]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reportUrl, setReportUrl] = React.useState("");
  const [autoRefresh, setAutoRefresh] = React.useState({
    status: false
  });

  const [intervalId, setIntervalId] = React.useState(0);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (e) => {
    console.log('check box state',e.target.checked)
    setChecked(e.target.checked);
    // deleteFlagUpdate('69', e.target.checked)
  };

  const handleListItemClick = index => {
    setSelectedIndex(index);
  };

  const handleAutoRefreshChange = event => {
    setAutoRefresh({ status: event.target.checked });
    if (!autoRefresh.status) {
      setIntervalId(setInterval(autoRefreshOnInterval, 5000));
    } else {
      clearInterval(intervalId);
    }
  };

  const autoRefreshOnInterval = () => {
    loadRunsFromServer(page, size, applicationId);
    setSelectedIndex(0);
  };

  const onChangePage = (event, pageSelected) => {
    loadRunsFromServer(pageSelected, size, applicationId);
    setSelectedIndex(0);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    red: {
      backgroundColor: red[500]
    },
    green: {
      backgroundColor: green[500]
    },
    autoRefreshBg: {
      color: "grey"
    },
    autoRefreshPosition: {
      position: "absolute",
      marginTop: '15px',
      height: '51px',
      marginLeft: '550px',
      backgroundColor: '#eee'
    }
  }));

  const classes = useStyles();

  const isGrid = (selectedRun) =>{
    return selectedRun.environment.settings_content.includes(SELENIUM_GRID_URL);
  }
  
  const downloadHandler = async (runId) => {
        zippedReports(runId);
  };

  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }} >
        <Grid container>
          <Grid item xs={4}>
            <Box mr={1} mt={1}>
              <Box>
                <List component="nav">
                  {map(runs, (run, index) => (
                    <ListItem
                      key={run.id}
                      divider
                      button
                      selected={selectedIndex === index}
                      onClick={event => {
                        handleListItemClick(index);
                        setSelectedRun(runs[index]);
                      }}
                    >
                      <ListItemAvatar>
                        {runs[index].status === "FAILURE" && (
                          <Avatar className={classes.red}>
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                        {runs[index].status === "SUCCESS" && (
                          <Avatar className={classes.green}>
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                        {runs[index].status === "RUNNING" && (
                          <Avatar>
                            <DirectionsRunIcon />
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                        {runs[index].status === "ERROR" && (
                          <Avatar>
                            <HighlightOffIcon />
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                      </ListItemAvatar>

                      <ListItemText
                        primary={<Box><Typography variant="body"  style={{ width: '400px' }}>{run.details}</Typography></Box>}
                        secondary={
                          <Box>
                          <DisplayDate
                            dateString={run.run_at}
                            prefixString="Run on "
                          />
                          Environment : {run.environment.name}


                          <div>
                              <JiraComponent status={run.jira_status} jiraDesc={run.jira_Desc} jiraId={run.jira_Id} />
                          </div>
                          </Box>
                                                    
                        }
                      />
                      {/* <Tooltip title="Select to Retain report">
                      <FormControlLabel
                        control={ <Checkbox checked={run.delete_flag} onChange={handleChange}/> }
                        style={{marginLeft: '10px', marginRight: '20px', color: '#f50057'}}
                      />
                      </Tooltip> */}
                      
                      <Grid item xs={12} sm={3}>
                        <Chip label={"Run ID: " + run.id} style={{ backgroundColor: '#f50057', color: 'white' }} />
                      </Grid>

                      <Grid>
                      <IconButton color="primary" aria-label="Open in Browser" href={REPORT_URL + run.report_url} target="_blank">
                        <OpenInNew />
                      </IconButton>

                      </Grid>
                      <Grid>
                      
                      <CloudDownloadIcon label={"Download : " + run.id} style={{ backgroundColor: '#3f51b5', color: 'white' }} 
                       onClick={() =>{downloadHandler(run.id);}}
                     />
                     
                    </Grid>
                     

                      
                    </ListItem>
      
                  ))}
                </List>
                {count && (
                  <Box
                    display="flex"
                    flexDirection="row-reverse"
                    bgcolor="background.paper"
                  >
                    <TablePagination
                      rowsPerPageOptions={[size]}
                      component="div"
                      count={count}
                      rowsPerPage={size}
                      page={page}
                      onChangePage={onChangePage}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <FormGroup row className={classes.autoRefreshPosition}>
              {autoRefresh.status && (
                <CircularProgress color="secondary" size={32} />
              )}
              <Box ml={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={autoRefresh.status}
                      onChange={handleAutoRefreshChange}
                      name="autoRefresh"
                      color="inherit"
                    />
                  }
                  label="Auto Refresh"
                  className={classes.autoRefreshBg}
                />
              </Box>
            </FormGroup>
            <Box ml={2} mt={2} mr={2}>
                {selectedRun &&
                  selectedRun.status !== "RUNNING" &&
                  selectedRun.status !== "ERROR" && selectedRun.report_url == null && (
                    <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    >
                    <Box mt={4} ml={2} mr={2}>
                      <img src="/error.svg" alt="Error" width="380" Height="250" />
                    </Box>
                    <Box mt={2}>
                      <Typography color="error">
                        {selectedRun.errorDescription}
                      </Typography>
                    </Box>
                  </Grid>
                  )}
              {selectedRun &&
                selectedRun.status !== "RUNNING" &&
                selectedRun.status !== "ERROR" && selectedRun.report_url != null && (
                  <iframe
                    src={reportUrl}
                    height="800"
                    width="100%"
                    frameBorder="0"
                    title="Reports"
                  />
                )}
              {selectedRun && selectedRun.status === "ERROR" && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box mt={4} ml={2} mr={2}>
                    <img src="/error.svg" alt="Error" width="380" Height="250" />
                  </Box>
                  <Box mt={2}>
                    <Typography color="error">
                      {selectedRun.errorDescription}
                    </Typography>
                  </Box>
                </Grid>
              )}
               {selectedRun && selectedRun.status === "RUNNING" && !isGrid(selectedRun) &&(
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box mt={4} ml={2} mr={2} >
                    <img src="/loading.svg" alt="Loading" width="700" />
                    <LinearProgress />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="h6" color="primary">
                      Execution in progress...
                    </Typography>
                  </Box>
                </Grid>
              )}
              {selectedRun && selectedRun.status === "RUNNING" && isGrid(selectedRun) &&(
                <iframe
                src={SELENIUM_GRID_URL}
                height="800"
                width="100%"
                frameBorder="0"
                title="Selenium-Grid"
              />
              )

              }
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Grid container>
          <Grid item xs={12}>
            <Box >
              <Box mt={1}>
                <List component="nav">
                  {map(runs, (run, index) => (
                    <ListItem
                      key={run.id}
                      divider
                      button
                      selected={selectedIndex === index}
                      onClick={event => {
                        handleListItemClick(index);
                        setSelectedRun(runs[index]);
                      }}
                    >
                      <ListItemAvatar>
                        {runs[index].status === "FAILURE" && (
                          <Avatar className={classes.red}>
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                        {runs[index].status === "SUCCESS" && (
                          <Avatar className={classes.green}>
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                        {runs[index].status === "RUNNING" && (
                          <Avatar>
                            <DirectionsRunIcon />
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                        {runs[index].status === "ERROR" && (
                          <Avatar>
                            <HighlightOffIcon />
                            {runs[index].run_type === "BUSINESS_SCENARIO" && (
                              <BusinessCenterIcon />
                            )}
                            {runs[index].run_type === "FEATURE" && (
                              <AssignmentIcon />
                            )}
                          </Avatar>
                        )}
                      </ListItemAvatar>

                      <ListItemText
                        primary={<Box><Typography variant="body2"  style={{ width: '400px' }}>{run.details}</Typography></Box>}
                        secondary={
                        <Box><Typography variant="body2" color="textSecondary" style={{ width: '400px' }}> <DisplayDate
                        dateString={run.run_at}
                        prefixString="Run on " />
                        Environment : {run.environment.name}
                      </Typography>
                      </Box>}
                      />
                      <Grid item xs={12}>
                        <Chip label={"Run ID: " + run.id} style={{ backgroundColor: '#f50057', color: 'white', marginLeft: '140px' }} />
                      </Grid>

                      <Grid>
                      <IconButton color="primary" aria-label="Open in Browser" href={REPORT_URL + run.report_url} target="_blank">
        <OpenInNew />
      </IconButton>

                      </Grid>
                      
                    </ListItem>
                  ))}
                </List>
                {count && (
                  <Box
                    display="flex"
                    flexDirection="row-reverse"
                    bgcolor="background.paper"
                  >
                    <TablePagination
                      rowsPerPageOptions={[size]}
                      component="div"
                      count={count}
                      rowsPerPage={size}
                      page={page}
                      onChangePage={onChangePage}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row className={classes.autoRefreshPosition}>
              {/* {autoRefresh.status && (
                <CircularProgress color="secondary" size={32} />
              )} */}
              <Box ml={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={autoRefresh.status}
                      onChange={handleAutoRefreshChange}
                      name="autoRefresh"
                      color="inherit"
                    />
                  }
                  label="Auto Refresh"
                  className={classes.autoRefreshBg}
                />
              </Box>
            </FormGroup>
            <Box ml={2} mt={2} mr={2}>
              {selectedRun &&
                  selectedRun.status !== "RUNNING" &&
                  selectedRun.status !== "ERROR" && selectedRun.report_url == null && (
                    <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    >
                    <Box mt={4} ml={2} mr={2}>
                      <img src="/error.svg" alt="Error" width="380" Height="250" />
                    </Box>
                    <Box mt={2}>
                      <Typography color="error">
                        {selectedRun.errorDescription}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              {selectedRun &&
                selectedRun.status !== "RUNNING" &&
                selectedRun.status !== "ERROR" && selectedRun.report_url != null && (
                  <iframe
                    src={reportUrl}
                    height="800"
                    width="100%"
                    frameBorder="0"
                    title="Reports"
                  />
                )}
              {selectedRun && selectedRun.status === "ERROR" && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box mt={4} ml={2} mr={2}>
                    <img src="/error.svg" alt="Error" width="300" Height="250" />
                  </Box>
                  <Box mt={2}>
                    <Typography color="error">
                      {selectedRun.errorDescription}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {selectedRun && selectedRun.status === "RUNNING" && (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box mt={4} ml={2} mr={2}>
                    <img src="/loading.svg" alt="Loading" width="300" />
                    <LinearProgress />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="h6" color="primary">
                      Execution in progress...
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  runs: getRuns(state),
  count: getRunCount(state),
  page: getPage(state),
  size: getSize(state),
  applicationId: getSelectedApplication(state).id,
  selectedRun: getSelectedRun(state)
});

const mapDispatchToProps = dispatch => ({
  loadRunsFromServer: (page, size, applicationId) => {
    dispatch(listRunRequest(page, size, applicationId));
  },
  setSelectedRun: run => {
    dispatch(setSelectedRunRequest(run));
  },
  deleteFlagUpdate: (flagRun, runId) => {
    dispatch(deleteFlagUpdateRequest(flagRun, runId));
  },
  zippedReports: (runId) => {
    dispatch(zipReportFolder(runId));
  }
});

const HtmlJiraTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);

function JiraComponent({ status, jiraDesc, jiraId }) {
  return (
    <div style={{ color: status === 'Success' ? 'green' : (status === 'Failure' ? 'red' : '') }}>
      <HtmlJiraTooltip title={jiraDesc}>
        <span>
          { status === 'Success' ?  `Jira Id: ${jiraId}` : (status === 'Failure' ? `Jira Id: ${jiraId}` : '') }
        </span>
      </HtmlJiraTooltip>
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(RunList);
