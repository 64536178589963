import React from "react";
import { Grid, Typography, Accordion, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FeatureSearch from "./SearchBar";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import connect from './Search.js';
import SearchBar from './SearchBar.js';
import DownloadFileDialog from "../file/DownloadFile";
import CustomActionZip from "../../resources/custom-actions.zip"
import catsLiteFwZip from "../../resources/cats-lite-fw.zip"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  table: {
    minWidth: 100,
    maxWidth: 1100
  },
}));


export default function Faq() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container >
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <h1 style={{ marginTop: '30px', marginBottom: '5px', textAlign: 'center', color: '#3f51b5', fontWeight: 'normal', fontSize: '40px', fontFamily: 'Roboto,Helvetica Neue,sans-serif' }} >
            FAQs
          </h1>
          <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px' }}>
            View the frequently asked question below, if you need additional help please contact CATS Core Team (CATSCore@publicissapient.com)
          </Typography>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <div style={{ marginLeft: '600px', marginBottom: '30px', width: '600px' }}> <FeatureSearch /></div>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <div style={{ marginLeft: '20px', marginBottom: '30px', width: '460px' }}> <FeatureSearch /></div>
          </Box> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ paddingTop: '40px', paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              PROJECTS
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>What are different type of projects with Cats Lite ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We can create four types of project in CATS Lite:
                  <li><b>KARATE</b> for API Testing</li>
                  <li><b>SELENIUM</b> for Web Testing</li>
                  <li><b>MOBILE</b> for Mobile Testing</li>
                  <li><b>PERFORMANCE</b> for Load Testing</li>
                  <li><b>ALL</b> for Having capability of all testing within single project</li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How can we assign a user access to any project ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Project admin can assign any user to the project by clicking on the assign user button in project list screen.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to create a New projects with Cats Lite ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to Project list page and Click on Add Project button. In the displayed New Project dialog, provide the Project Name and the respective desired project type.
                  <li>Selenium: All standard features for Web Testing are available.</li>
                  <li>Karate: Exclusive features for API/Web Service Testing are enabled.</li>
                  <li>Performance: Load testing tool for web applications, designed for DevOps and Continuous Integration</li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to Add or Remove the Users from the project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>Add:</b> Click on Assign User button in the Project card, in the User Management dialog, under the user field provide the email address, in the Roles dropdown, select the respective roles of your choice and click on Assign User roles<br>
                  </br>
                  <b>Remove:</b> Click on Assign User button in the Project card, and Select the respective User Roles Tab Header section and click on the delete button respective to the Users who need to be removed.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to delete the project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To Delete a project, simply click on the Delete button on the respective Project, only Super Admins will be able to perform such action.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              TEST CASE AND PLAN MANAGEMENT
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to add Test cases to a Project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to the Project list page and select the respective Project in which Test cases need to be created. Click on Add Feature button and add the respective details. Once Fetaure created Under Feature Tab, script the Test steps in the BDD approach.<br></br>
                  Note: All the ORM object should be enclosed with in the square brackets [ ] and the Test data will be enclosed with in the angular brackets &lt;&gt;<br></br>
                  <br></br><b><u>Example:</u></b><br></br>
                  <br></br>Feature: Search on google<br></br>
                  @run2 @run3<br></br>
                  Scenario Outline: Successful search for some terms on google<br></br>
                  Given that I OPEN [url] to do search<br></br>
                  And I ENTER &lt;search_term&gt; in the text box<br></br>
                  When I CLICK [search_button] on the search page<br></br>
                  Then I should SEE [search_results] on the result page<br></br>
                  <br></br>
                  Examples:<br></br>
                  | search_term |<br></br>
                  | hello |<br></br>
                  | hi |<br></br>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to add Object Locators and Map to Test case?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to Object Repository page in the top right corner. Click on Add Global Objects and in the Global Object dialog provide the Page Name or any reference value, after the new object is created start adding the locator values in the right panel section in Json format. Please note currently only Xpath identifies are only used.
                  <br></br>
                  <br></br>
                  <b><u>Example:</u></b><br></br>
                  <code>
                    &#123;
                    <br></br>
                    "search_term": "//input[@name='q']",<br></br>
                    "search_button": "//div[@class='FPdoLc tfB0Bf']//input[@name='btnK']",<br></br>
                    "search_results": "//span[@id='xjs']//div"<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to add Environment data and map to Test cases?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to the Environment page and click on Add Environment button and in the Environment dialog provide the Environment Name as a reference value. This is where all the run related Paraments such as Test data, URL and Browser details are added, the values should be entered with a Valid Json format.
                  <br></br>
                  <br></br>
                  <b><u>Example:</u></b><br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"http://localhost:4444/wd/hub",<br></br>
                    "webdriver.type":"CHROME",<br></br>
                    "arguments":["s","d"]<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to Integrate Report Portal with Project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to the Environment page and all the below Paraments in the Environment details
                  Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "rp.endpoint" : "Report Portal URL",<br></br>
                    "rp.apikey" : "This Key will be available under Report portal Profile section",<br></br>
                    "rp.project" : "Enter the Project name as available in Report portal"<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to create a Test Suite with Project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to Test Plan and click on New Test Suite button and add the respective details, In the created Test suite, add the Test cases under the section Manage Features
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to Run or Schedule a Test Suite?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To run the Suite, click on the Run button in the top right corner of the respective Test Suite, In order to run a Test Suite at a particular time you can Schedule a run using the Schedule button
                </Typography>
              </AccordionDetails>
            </Accordion>

          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              API TESTING
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>What is Karate Framework and its Importance</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li>Karate is a BDD testing framework instead of a TDD.</li>
                  <li>It is designed to be easy for non-programmers. This feature is a game-changer as it allows for more use and access by many people regardless of their technical background or capacity.</li>
                  <li>It makes use of the Cucumber feature file and the Gherkins language to write the test which is very easy to understand.</li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }} >Creating The First Basic Karate Test Script ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This section will help you get started with the creation of your very first Test Script, which will be helpful for you to convert APIs in the form of a Karate framework.
                  <br></br>
                  Example:
                  <br></br>
                  <code>
                    Feature: fetching User Details<br></br>
                    Scenario: testing the get call for User Details<br></br>

                    Given url 'https://reqres.in/api/users/2'<br></br>
                    When method GET<br></br>
                    Then status 200<br></br>
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Understanding the components in the Karate scripting?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li>Feature: Keyword explains the name of the feature we are testing.</li>
                  <li>Background: This is an optional section that is treated as a Pre-requisite section. This can be used to define what all is needed to test the API.</li>
                  <li>It contains HEADER, URL and PARAM options.</li>
                  <li>Scenario: Every feature file that you will see will have at least one feature (although it can give multiple scenarios). It is the description of the test case.</li>
                  <li>Given: It is the step that needs to be executed before any other test step is performed. It is a mandatory action to be performed.</li>
                  <li>When: It specifies the condition that should be met to perform the next test step.</li>
                  <li>Then: It tells us that what should happen in case the condition mentioned in the When is satisfied.</li>
                  <li>Note: All the above-mentioned keywords are from the Gherkins language. These are the standard way of writing the test scripts using Cucumber.</li>

                  <li>And some more words used in the feature file are:</li>

                  <li>200: It is the status/response code which we are expecting (Click here for the list of status codes)</li>
                  <li>GET: It is the API method like POST, PUT, etc..</li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Basics of Karate Framework</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Karate Framework is an open-source framework that combines automated API tests, performance testing, mocks, and even UI testing into a single framework. Peter Thomas from Intuit developed the framework, and it has a large community.<br></br>
                  <br></br>
                  Karate uses Gherkin syntax and BDD approach for coding test scripts, so you don't need to know any programming language such as Java or Python.<br></br>
                  <br></br>
                  Karate has predefined step definitions, and you are not responsible for defining the required steps. You only need to have a basic understanding of API testing and Gherkin knowledge to develop API tests with Karate Framework.<br></br>
                  <br></br><b><u>KARATE FUNCTIONS:</u></b>
                  <br></br>
                  <br></br><b><u>1.Base URL:</u></b>
                  <br></br>
                  <br></br>You can define the base URL in Karate with the url keyword. Just write the url then base URL after that.<br></br>
                  Given url https://publicissapient.com
                  <br></br>
                  <br></br>
                  <br></br><b><u>2.Path Parms:</u></b>
                  <br></br>
                  <br></br>After you define the URL, you need to define a path to send a request. You can handle path parameters with the path keyword in Karate.<br></br>
                  <br></br>And path &#39;blog&#39;
                  <br></br>
                  <br></br>
                  <br></br><b><u>3.Query Parms:</u></b>
                  <br></br>
                  <br></br>Some endpoints present query parameters to search for specific keywords. Here is the usage of the query params in Karate:<br></br>
                  <br></br>And path &#39;blog&#39;
                  <br></br>And param search = &#39;KaratePosts&#39;
                  <br></br>
                  <br></br>
                  <br></br><b><u>4.GET Request:</u></b>
                  <br></br>
                  <br></br>You can read the data from the API with a GET request.<br></br>
                  <br></br>Feature: GET
                  <br></br>Scenario: Get Request

                  <br></br>Given url &#39;http://localhost:9090/auth/admin/realms/master/users/&#39;
                  <br></br>When method GET
                  <br></br>Then status 200<br></br>
                  <br></br>In Karate you can easily verify the status code of the response with the status keyword.
                  <br></br>
                  <br></br><b><u>5.POST Request:</u></b>
                  <br></br>
                  <br></br>You can create new data on the services with a post request. In Karate, you can use the request keyword to attach the request body to your request and method post for sending requests.
                  <br></br>
                  <br></br>Feature: Test Login and match response<br></br>
                  Scenario: Post request to get Refresh Token<br></br>
                  Background: *url &#39;http://localhost:9090/auth/realms/master/protocol/openid-connect/token&#39;
                  <br></br>
                  <br></br># Here we have to define the User credentials, it should be in single quotes (&#39; &#39;)<br></br>
                  <br></br>And header Content-Type = &#39;application/x-www-form-urlencoded; charset=utf-8&#39;<br></br>
                  And form field username = &#39;admin&#39;<br></br>
                  And form field password = &#39;admin&#39;<br></br>
                  And form field grant_type = &#39;password&#39;<br></br>
                  And form field client_id = &#39;anoroc-app&#39;<br></br>
                  <br></br>
                  <br></br># Define method
                  When method POST
                  <br></br>
                  <br></br># To print the response we use &#39;print&#39; keyword
                  And print response
                  <br></br>
                  <br></br># Define expected condition with the help of &#39;status&#39; keyword
                  Then status 200
                  <br></br>
                  <br></br><b><u>6.PUT Request:</u></b><br></br>
                  <br></br>You can use the put method just like a post request and update the data.<br></br>
                  <br></br>Scenario: Put request<br></br>
                  <br></br>* def user =<br></br>
                  """<br></br>
                  &#123;<br></br>
                  "name": "Jane",<br></br>
                  "username": "jane_forest",<br></br>
                  "phone": 12546758485<br></br>
                  &#125;<br></br>
                  &#125;<br></br>
                  """"<br></br>
                  Given url &#39;https://www.kloia.com/&#39;<br></br>
                  And path &#39;user&#39;, 129<br></br>
                  And request user<br></br>
                  When method PUT<br></br>
                  Then status 200<br></br>
                  <br></br>
                  <br></br><b><u>7.DELETE Request:</u></b><br></br>
                  <br></br>You can remove data with a delete request, and the method delete keyword will handle this request.<br></br>
                  <br></br>Scenario: Delete request<br></br>
                  Given url &#39;https://www.kloia.com/&#39;<br></br>
                  And path &#39;user&#39;, 129<br></br>
                  When method DELETE<br></br>
                  Then status 204<br></br>
                  <br></br>
                  <br></br><b><u>8.ASSERTIONS:</u></b><br></br>
                  <br></br>
                  <b><u>Match Keyword:</u></b>
                  You can handle all verifications with &#39;match&#39; keywords.<br></br>
                  <code>
                    <br></br> Scenario: Matchers examples
                    <br></br>* def jsonBody =
                    <br></br>"""<br></br>
                    &#123;<br></br>
                    "category": &#123;<br></br>
                    "id": 1,<br></br>
                    "name": "cats"<br></br>
                    &#125;,<br></br>
                    "name": "kitty",<br></br>
                    "photoUrls": [<br></br>
                    "www.kitty.com"<br></br>
                    ],<br></br>
                    "tags": [<br></br>
                    &#123;<br></br>
                    "id": 0,<br></br>
                    "name": "sweet"<br></br>
                    &#125;<br></br>
                    ],<br></br>
                    "status": "available"<br></br>
                    &#125;<br></br>
                    """<br></br>
                    <br></br>Given url &#39;https://petstore.swagger.io/v2&#39;<br></br>
                    And path &#39;pet&#39;<br></br>
                    And request jsonBody<br></br>
                    When method POST<br></br>
                    Then status 200<br></br>
                    And match response.category == jsonBody.category<br></br>
                    And match response.id == &#39;#present&#39;<br></br>
                    And match response.name == jsonBody.name<br></br>
                    And match responseHeaders[&#39;Date&#39;] == &#39;#notnull&#39;<br></br>
                    And match each response.tags == &#123; &#39;id&#39;: &#39;#number&#39;, &#39;name&#39;: &#39;#string&#39; &#125;<br></br>
                  </code><br></br>
                  ‘#present’ is for verifying data is present or not.<br></br>
                  ‘#notnull’ is for checking if the data is null or not. You can also use &#39;#null&#39;<br></br>
                  ‘#number’, ‘#string’ will verify the data types.<br></br>
                  each will loop all the responses values if the response includes an array. So you can easily verify all of the values in the response body.<br></br>
                  <br></br>
                  <br></br><b><u>9.Reading JSON File:</u></b>
                  <br></br>
                  <br></br>One simple line of code will read JSON object.<br></br>
                  <br></br>
                  * def jsonModel = read('classpath: C:\\CATS-Lite\\SampleJSON\\modelFile.JSON)<br></br>
                  * print jsonModel<br></br>
                  <br></br>
                  We have printed a JSON file to the console, but primary task is not printing a JSON file to the console; we will use it for making API requests. Now, let's use the JSON file request body and create a new record on the API.<br></br>
                  <br></br>
                  <br></br>petData.JSON:<br></br>
                  <br></br>
                  <code>
                    &#123;<br></br>
                    "category": &#123;<br></br>
                    "id": 0,<br></br>
                    "name": "categoryName"<br></br>
                    &#125;,<br></br>
                    "name": "petName",<br></br>
                    "photoUrls": [<br></br>
                    "someURL"<br></br>
                    ],<br></br>
                    "tags": [<br></br>
                    &#125;<br></br>
                    "id": 0,<br></br>
                    "name": "tagName"<br></br>
                   }<br></br>
                    ],<br></br>
                    "status": "available"<br></br>
                }<br></br>
                  </code>
                  <br></br>
                  <br></br><b>POST Request with JSON:</b><br></br>
                  <br></br>
                  Scenario: Read JSON model from file and post it<br></br>
                  Given url 'https://petstore.swagger.io/v2'<br></br>
                  And path 'pet'<br></br>
                  And def jsonBody = read('classpath:data/petData.json') // or we can pass local path to the JSON <br></br>
                  And request jsonBody<br></br>
                  When method POST<br></br>
                  Then status 200<br></br>

                  <br></br><b><u>10.Associated Feature (CALLER Function)</u></b>
                  <br></br>
                  <br></br>There is a scenario that is responsible for one test case. And, one of the preconditions of the other scenario is the first scenario. How can you handle this problem? Do you write the first scenario inside of the second one? Well, that is not maintainable. Maybe you can think of putting both scenarios in one feature file and running these scenarios sequentially. But it will make your scenarios dependent on each other. So you need a structure that prevents code duplication, provides reusability, and makes your scenarios independent of each other. It is possible with the Associated feature capability of CATS Lite.<br></br>
                  <br></br>
                  Imagine that you have one test case, using one get request, and update the response with a new value. So we need to create a new feature to send a get request, use this request's response and send a put request. First, create a feature file for a get request:<br></br>
                  <br></br>
                  Feature: calllerGet<br></br>
                  <br></br>
                  <code>
                    Scenario: get scenario with static id<br></br>
                    Given url baseUrl<br></br>
                    And path 'pet', 250<br></br>
                    When method GET<br></br>
                    Then status 200<br></br>
                    And print 'after get', response<br></br>
                    <br></br>
                  </code>
                  Now create a new feature that will call the feature above.<br></br>
                  <br></br>
                  <code>
                    Scenario: caller feature usage<br></br>
                    Given url baseUrl<br></br>
                    And path 'pet'<br></br>
                    And def getFeature = call read(callerGet.feature)<br></br>
                    And getFeature.response.name = 'newName'<br></br>
                    And request getFeature.response<br></br>
                    When method PUT<br></br>
                    Then status 200<br></br>
                  </code>
                  <br></br>
                  The executed Karate script above read the other feature file first and did all of the operations in this caller feature, then ran the remaining script in the current feature. Caller feature sends a get request to the API. The main feature uses the caller’s response, updates one value inside of the response, and sends a put request to update the record with new value.<br></br>
                  <br></br>
                  It is an excellent structure. But if you notice, We have used static parameters, 250 as id in the get request. It is not a good way to use these callers. You should pass the parameters from one to another. Karate allows you to use parameters as well while calling other features. Let's use parameters and see how you can handle this problem.<br></br>
                  <br></br>
                  <code>
                    Scenario: get scenario with parametric id<br></br>
                    Given url baseUrl<br></br>
                    And path 'pet', id<br></br>
                    When method GET<br></br>
                    Then status 200<br></br>
                    And print 'after get', response<br></br>
                    <br></br>
                    Scenario: caller<br></br>
                    Given url baseUrl<br></br>
                    And path 'pet'<br></br>
                    And def getFeature = call read('classpath:callers/callerGet.feature')&#123; id:251 &#125;<br></br>
                    And getFeature.response.name = 'newName'<br></br>
                    And request getFeature.response<br></br>
                    When method PUT<br></br>
                    Then status 200<br></br>
                    And print 'after put', response<br></br>
                    <br></br>
                  </code>
                  We have modified the caller feature to use parameters as an id. Afterward, in the main feature, we called the get caller feature with an id and updated the caller’s response, and added this response as request body to send a put request.<br></br>
                  We can pass whatever parameter we want with this approach, and we can use the parameters in the caller features as well. This approach is convenient; it will increase framework reusability and also the quality of your code.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              PERFORMANCE TESTING
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>What type of Performance Testing we can do with Cats Lite ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We can run any Performance Testing with Karate API, although we require some configuration values.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>What are the configuration required to run Performance Testing with CATS Lite?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  In order to run Performance Tesing script, we have to add below configuration details in the Environment tab of CATS Lite.
                  NOTE : Default values for Inject and Protocol are given below, it can also be modified as per requirement from Settings.
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "gatling.inject":"rampUsers(10) during (5 seconds)",<br></br>
              "gatling.protocol":"\"/all\" -> Nil"<br></br>
                    &#125;<br></br>
                  </code>
                  <br></br>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              MOBILE TESTING
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to Integrate Browser Stack with Project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> In order to run the automation script on BrowserStack we have to mention below config details in Environment tab.
                  <br></br>
                  <br></br><b><u>FOR DESKTOP BROWSER AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url": "Browser stack Driver URL to be provided here",<br></br>
                    "webdriver.type": "BROWSERSTACK",<br></br>
                    These values need to be modified as required<br></br>
                    "capabilities": <br></br>
                    &#123; <br></br>
                    "browser": "firefox",<br></br>
                    "browser_version": "latest",<br></br>
                    "os": "Windows",<br></br>
                    "os_version": "10",<br></br>
                    "resolution": "1920x1080",<br></br>
                    "name": "BStack-[Java] Sample Test",<br></br>
                    "build": "BStack firefox 1"<br></br>
                    &#125;<br></br>
                    &#125;<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br><b><u>FOR MOBILE BROWSER AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url": "Browser stack Driver URL to be provided here",<br></br>
                    "webdriver.type": "BROWSERSTACK",<br></br>
                    These values need to be modified as required<br></br>
                    "capabilities": <br></br>
                    &#123; <br></br>
                    "device": "Device_Name",<br></br>
                    "os_version": "Mobile_OS_Version",<br></br>
                    "browserName":"android or iOS Browser Name",<br></br>
                    "realMobile":"true",<br></br>
                    &#125;<br></br>
                    &#125;<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br><b><u>FOR MOBILE APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>
                  In order to run the Mobile Application Automation, we have to provide below capabilities in the Environment tab.
                  If we want to run on Android device than make sure to set the app id for iOS application and if running on android than we have to provide the app id for android application.
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url": "Browser stack Driver URL to be provided here",<br></br>
                    "webdriver.type": "BROWSERSTACK",<br></br>
                    "app":"bs://23340a3bd89cc6ecf6e6b35ad74d9bafd7c6b52f",<br></br>
                    "config.browser":"APP",<br></br>
                    These values need to be modified as required<br></br>
                     "platformName": "Android",<br></br>
                    "capabilities": <br></br>
                    &#123; <br></br>
                    "device": "Device_Name",<br></br>
                    "os_version": "Mobile_OS_Version",<br></br>
                    "platformName": "Android",<br></br>
                    "appPackage":"com.snapdeal.main",<br></br>
                    &#125;<br></br>
                    &#125;<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to run a Test case with pCloudy ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> In order to run trigger the Mobile Automation on pCloudy public cloud, we have to provide the below capabilities in Environment tab: <br></br>
                  <br></br>
                  <b><u>FOR ANDROID APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"https://device.pcloudy.com/appiumcloud/wd/hub",<br></br>
                    "webdriver.type":"PCLOUDY",<br></br>
                    "capabilities": <br></br>
                    &#123; <br></br>
                    "pCloudy_Username": "ENTER PCLOUDY USERNAME",<br></br>
                    "pCloudy_ApiKey": "ENTER PCLOUDY API KEY",<br></br>
                    "pCloudy_EnableVideo" : "true",<br></br>
                    "pCloudy_EnableDeviceLogs" : "true",<br></br>
                    "pCloudy_DeviceManafacturer" : "Samsung",<br></br>
                    "platformName": "android",<br></br>
                    "appPackage" : "Enter App Package Name",<br></br>
                    "pCloudy_appActivity" : "Enter App Activity Name",<br></br>
                    "pCloudy_ApplicationName" : "Enter App Name to Install",<br></br>
                    "uiautomator2ServerLaunchTimeout" : 120000,<br></br>
                    "uiautomator2ServerInstallTimeout" : 90000<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                  <b><u>FOR IOS APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"https://device.pcloudy.com/appiumcloud/wd/hub",<br></br>
                    "webdriver.type":"PCLOUDY",<br></br>
                    "capabilities": <br></br>
                    &#123; <br></br>
                    "pCloudy_Username": "ENTER PCLOUDY USERNAME",<br></br>
                    "pCloudy_ApiKey": "ENTER PCLOUDY API KEY",<br></br>
                    "pCloudy_EnableVideo" : "true",<br></br>
                    "pCloudy_EnableDeviceLogs" : "true",<br></br>
                    "pCloudy_DeviceManafacturer" : "Apple",<br></br>
                    "platformName": "ios",<br></br>
                    "appPackage" : "Enter Bundle ID",<br></br>
                    "pCloudy_ApplicationName" : "Enter App Name to Install",<br></br>
                    "uiautomator2ServerLaunchTimeout" : 120000,<br></br>
                    "uiautomator2ServerInstallTimeout" : 90000<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to run a Test case with Publicis Sapient Mobile Test Lab?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> In order to run trigger the Mobile Automation on Publicis Sapient Mobile Lab, we have to provide the below capabilities in Environment tab: <br></br>
                  <br></br>
                  <b><u>FOR ANDROID APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"https://mobilecloud.sapient.com/appiumcloud/wd/hub",<br></br>
                    "webdriver.type":"PCLOUDY",<br></br>
                    &#123; <br></br>
                    "pCloudy_Username": "ENTER PCLOUDY USERNAME",<br></br>
                    "pCloudy_ApiKey": "ENTER PCLOUDY API KEY",<br></br>
                    "pCloudy_EnableVideo" : "true",<br></br>
                    "pCloudy_EnableDeviceLogs" : "true",<br></br>
                    "pCloudy_DeviceManafacturer" : "Samsung",<br></br>
                    "platformName": "android",<br></br>
                    "appPackage" : "Enter App Package Name",<br></br>
                    "pCloudy_appActivity" : "Enter App Activity Name",<br></br>
                    "pCloudy_ApplicationName" : "Enter App Name to Install",<br></br>
                    "uiautomator2ServerLaunchTimeout" : 120000,<br></br>
                    "uiautomator2ServerInstallTimeout" : 90000<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                  <b><u>FOR IOS APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"https://mobilecloud.sapient.com/appiumcloud/wd/hub",<br></br>
                    "webdriver.type":"PCLOUDY",<br></br>
                    &#123; <br></br>
                    "pCloudy_Username": "ENTER LAB USERNAME",<br></br>
                    "pCloudy_ApiKey": "ENTER API KEY",<br></br>
                    "pCloudy_EnableVideo" : "true",<br></br>
                    "pCloudy_EnableDeviceLogs" : "true",<br></br>
                    "pCloudy_DeviceManafacturer" : "Apple",<br></br>
                    "platformName": "ios",<br></br>
                    "appPackage" : "Enter Bundle ID",<br></br>
                    "pCloudy_ApplicationName" : "Enter App Name to Install",<br></br>
                    "uiautomator2ServerLaunchTimeout" : 120000,<br></br>
                    "uiautomator2ServerInstallTimeout" : 90000<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to Integrate SauceLabs with Project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> In order to run the automation script on SauceLabs we have to mention below config details in Environment tab.
                  <br></br>
                  <br></br><b><u>FOR DESKTOP BROWSER AUTOMATION :</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "<b>remote.webdriver.url</b>": "https://ondemand.eu-central-1.saucelabs.com:443/wd/hub", (<i>SauceLabs URL to be provided here</i>) <br></br>
                    "<b>webdriver.type</b>": "SAUCELABS",<br></br>
                    <i>These values need to be modified as required</i><br></br>
                    "<b>capabilities</b>": <br></br>
                    &#123; <br></br>
                    "<b>browserName</b>": "Chrome",<br></br>
                    "<b>platform</b>": "Windows 10",<br></br>
                    "<b>version</b>": "latest",<br></br>
                    "<b>build</b>": "Enter any build name .",<br></br>
                    "<b>name</b>": "Enter any name ",<br></br>
                    "<b>username</b>": " Your SauceLabs Usename",<br></br>
                    "<b>accessKey</b>": "Your SauceLabs accessKey"<br></br>
                    &#125;<br></br>
                    &#125;<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br><b><u>FOR MOBILE BROWSER AUTOMATION (Example : Web Android):</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "<b>remote.webdriver.url</b>": "https://ondemand.eu-central-1.saucelabs.com:443/wd/hub", (<i>SauceLabs URL to be provided here</i>) <br></br>
                    "<b>webdriver.type</b>": "SAUCELABS",<br></br>
                    "<b>config.browser</b>": "APP",<br></br>
                    "<b>platformName</b>": "android",<br></br>
                   <i>These values need to be modified as required</i><br></br>
                    "<b>capabilities</b>": <br></br>
                    &#123; <br></br>
                    "<b>platformName</b>": "android",<br></br>
                    "<b>browserName</b>": "Chrome",<br></br>
                
                    "<b>appium:deviceName</b>":"Samsung Galaxy Tab Active 2 GoogleAPI Emulator",<br></br>
                    "<b>appium:platformVersion</b>":"10.0",<br></br>
                    "<b>appium:automationName</b>": "UiAutomator2",<br></br>
                    "<b>sauce:options</b>": <br></br>
                    &#123; <br></br>
                    "<b>appiumVersion</b>": "android",<br></br>
                    "<b>username</b>": " Your SauceLabs Usename",<br></br>
                    "<b>accessKey</b>": "Your SauceLabs accessKey"<br></br>
                    "<b>build</b>": "Enter any build name .",<br></br>
                    "<b>name</b>": "Enter any name ",<br></br>
                    "<b>deviceOrientation</b>":"PORTRAIT"<br></br>

      
                    &#125;<br></br>
                    &#125;<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br><b><u>FOR MOBILE APPLICATION AUTOMATION (Example : Native Android):</u></b>
                  <br></br>
                  <br></br>
                  In order to run the Mobile Application Automation, we have to provide below capabilities in the Environment tab.
                  If we want to run on Android device than make sure to set the app id for iOS application and if running on android than we have to provide the app id for android application.
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "<b>remote.webdriver.url</b>": "https://ondemand.eu-central-1.saucelabs.com:443/wd/hub", (<i>SauceLabs URL to be provided here</i>) <br></br>
                    "<b>webdriver.type</b>": "SAUCELABS",<br></br>
                    "<b>config.browser</b>": "APP",<br></br>
                    "<b>platformName</b>": "android",<br></br>
                    <i>These values need to be modified as required</i><br></br>
                    "capabilities": <br></br>
                    &#123; <br></br>
                    "<b>platformName</b>": "android",<br></br>
                    "<b>appium:app</b>": "storage:filename=snapdeal.apk",<br></br>
                    "<b>appium:deviceName</b>":"Samsung Galaxy Tab Active 2 GoogleAPI Emulator",<br></br>
                    "<b>appium:platformVersion</b>":"10.0",<br></br>
                    "<b>appium:automationName</b>": "UiAutomator2",<br></br>
                    "<b>sauce:options</b>": <br></br>
                    &#123; <br></br>
                    "<b>appiumVersion</b>": "android",<br></br>
                    "<b>username</b>": " Your SauceLabs Usename",<br></br>
                    "<b>accessKey</b>": "Your SauceLabs accessKey"<br></br>
                    "<b>build</b>": "Enter any build name .",<br></br>
                    "<b>name</b>": "Enter any name ",<br></br>
                    "<b>deviceOrientation</b>":"PORTRAIT"<br></br>

                  
                    &#125;<br></br>
                    &#125;<br></br>
                    &#125;
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to run a Test case on local mobile device with Appium?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> In order to run trigger the Mobile Automation on local mobile device with appium, we have to provide the below capabilities in Environment tab: <br></br>
                  <br></br>
                  <b><u>FOR ANDROID APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"http://localhost:4723/wd/hub",<br></br>
                    "webdriver.type":"MOBILE",<br></br>
                    "capabilities": <br></br>
                    &#123;
                    <br></br>
                    "platformName":"Android",<br></br>
                    "platformVersion":"11.0",<br></br>
                    "deviceName":"emulator-5554",<br></br>
                    "platformName":"Android",<br></br>
                    "appPackage":"com.app.main",<br></br>
                    "appActivity":"com.mobile.LaunchActivity"<br></br>
            }<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                  <b><u>FOR IOS APPLICATION AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"http://localhost:4723/wd/hub",<br></br>
                    "webdriver.type":"MOBILE",<br></br>
                    "capabilities": <br></br>
                    &#123;
                    <br></br>
                    "platformName":"ios",<br></br>
                    "platformVersion":"11.0",<br></br>
                    "deviceName":"emulator-5554",<br></br>
                    "platformName":"XCUITest",<br></br>
                    "appPackage":"com.app.main",<br></br>
            }<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                  <b><u>FOR MOBILE BROWSER AUTOMATION:</u></b>
                  <br></br>
                  <br></br>Example:<br></br>
                  <code>
                    &#123;
                    <br></br>
                    "remote.webdriver.url":"http://localhost:4723/wd/hub",<br></br>
                    "webdriver.type":"MOBILE",<br></br>
                    "capabilities": <br></br>
                    &#123;
                    <br></br>
                    "platformName":"Android",<br></br>
                    "platformVersion":"11.0",<br></br>
                    "deviceName":"emulator-5554",<br></br>
                    "platformName":"Android/iOS",<br></br>
                    "browserName":"Chrome",<br></br>
            }<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to Install Android Emulator for Mobile Elements Inspection ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li> Install Appium, Node JS and Android SDK</li>
                  <li> Open SDK manager and install the below packages</li>
                  <li> Android SDK Tools</li>
                  <li> Android SDK Platform-tools</li>
                  <li> Android SDK Build-tools</li>
                  <li> Google USB Driver</li>
                  <li> Add new environment variable ANDROID_HOME and value should be SDK location pointing
                    to the folder to (drive location\)Android\android-sdk</li>
                  <li> Append the environment PATH %ANDROID_HOME %/platform-tool</li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to install iOS Simulator ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  IOS Simulator Installation
                  <br></br>
                  Xcode:
                  <br></br>
                  <li>Download Xcode from https://developer.apple.com/xcode/downloads/</li>
                  <li>Open Xcode after installation, go to Devices menu, check your required simulators are
available. If not please download the necessary simulators by clicking on New Simulator >>
                    Get simulator</li>
                  <li>Next, install Node (npm will be installed with node): https://nodejs.org/download/</li>
                  <li>Install Appium (https://bitbucket.org/appium/appium.app/downloads/)</li>
                  <br></br>
                  Android SDK:
                  <br></br>
                  <li>Download the installable from here https://developer.android.com/sdk/index.html#Other</li>
                  <li>After installing, in the Android SDK Manager, select the packages you want to use, and
                    download them. Same as windows installation.</li>
                  <li>Install Appium (https://bitbucket.org/appium/appium.app/downloads/)</li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to add Test Steps for Mobile Testing?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Navigate to the Project list page and select the respective Project in which Test cases need to be created. Click on Add Feature button and add the respective details. Once Fetaure created Under Feature Tab, script the Test steps in the BDD approach,
                  <br></br>
                  Note: All the ORM object should be enclosed with in the square brackets [ ] and the Test data will be enclosed with in the angular brackets &lt;&gt;
                  <br></br>
                  <br></br>Feature: Mobile Execution on Snapdeal application
                  <br></br>Scenario: Open Snapdeal application in mobile and perfrom actions
                  <br></br>Given I APP_ACTIVATE
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_CLICK [s_english1]
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_CLICK [s_apply1]
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_CLICK [s_searchBox]
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_ENTER_DATA &lt;s_searchBox1&gt;
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_ENTER_PRESS
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_CLICK [s_addToCart]
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And MOBILE_CLICK [s_size]
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>And APP_UNINSTALL
                  <br></br>And MOBILE_WAIT &lt;scnds&gt;
                  <br></br>
                  <br></br>Examples:
                  <br></br> |scnds||s_searchBox1|
                  <br></br>| 5   ||   jeans   |
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              JIRA API INTEGRATION
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How can we Encrypt a Password ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li>Open this url : <b> http://catslite.publicissapient.com/api/swagger-ui.html#/</b></li>
                  <li>Click on <b>Encrypt Data Controller</b></li>
                  <li>Click on <b>GET</b></li>
                  <li>In this controller, Click on <b>Try It Out</b></li>
                  <li>In Authorization box, Replace <b>token</b> with your token value . To generate a token, Right click on the same window. Click on Inspect. Go in Application Tab and copy the value of <b>anoroc-token</b></li>
                  <li>In Input box, Enter your Password</li>
                  <li>Click on <b>Execute</b></li>
                  <li>Your Encrypted password will get generated below in Response Body box</li>


                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>What are the types of use cases after Integrating Jira with CATS-LITE ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li>We can create a New Test Plan with cats lite</li>
                  <li>We can create a New Test cycle with cats lite</li>
                  <li>We can update a particular existing Test Cycle</li>

                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How can we create a New Test Plan in Jira with cats lite ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  In order to create a New Test Plan with Cats Lite we have to mention below Key details in settings of Environment tab.
                  <br></br>
                  <br></br>Example:<br></br>
                  <br></br>
                  &#123;
                  <br></br>
                  <b>"UPDATE_TEST_MANAGEMENT":</b>"YES",<br></br>
                  <b>"TEST_MANAGEMENT":</b>"JIRA",<br></br>
                  <b>"TM_URL":</b>"https://tools.publicis.sapient.com/jira/rest/atm/1.0",<br></br>
                  <b>"TM_ACCESS_TOKEN":</b>" ",<br></br>  ---> <i>(Go to Jira Profile Menu and generate Personal Access Token) </i><br></br>
                  <b>"UPDATE_PLAN":</b>"NO",<br></br>
                  <b>"TM_PROJECT_NAME":</b>"CATS",  ---> <i>(Enter your Project Key)</i> <br></br>
                  <b>"PLAN_NAME":</b>"CATS_planksl2",  ---> <i>(Enter your plan Name)</i> <br></br>
                  <b>"PLAN_FOLDER":</b>"/CATSKSL"  ---> <i>(This is be optional.This key is required if plan should be created in the given existing Folder in Jira)</i> <br></br>
                  &#125;
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How can we create a new Test cycle ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  In order to create a New Test Cycle with Cats Lite we have to mention below Key details in settings of Environment tab.
                  <br></br>
                  <br></br>Example:<br></br>
                  <br></br>
                  &#123;
                  <br></br>
                  <b>"UPDATE_TEST_MANAGEMENT":</b>"YES",<br></br>
                  <b>"TEST_MANAGEMENT":</b>"JIRA",<br></br>
                  <b>"TM_URL":</b>"https://tools.publicis.sapient.com/jira/rest/atm/1.0",<br></br>
                  <b>"TM_ACCESS_TOKEN":</b>" ",<br></br>  ---> <i>(Go to Jira Profile Menu and generate Personal Access Token) </i><br></br>
                  <b>"UPDATE_PLAN":</b>"YES",<br></br>
                  <b>"TM_PROJECT_NAME":</b>"CATS",  ---> <i>(Enter your Project Key)</i> <br></br>
                  <b>"PLAN_ID":</b>"CATS-P197",  ---> <i>(This is optional, it is required if test cycle should be created inside a given existing plan)</i> <br></br>
                  <b>"UPDATE_CYCLE":</b>"NO",<br></br>
                  <b>"CYCLE_FOLDER":</b>"/CATSF"  ---> <i>(This Key is optional.This key is required if cycle should be created in the given existing Folder in Jira)</i> <br></br>
                  <b>"CYCLE_NAME":</b>"CATS_CKSL",  ---> <i>(Enter your Test cycle name)</i> <br></br>
                  <b>"TC_ID":</b>"CATS-T772",  ---> <i>(Enter your Test Case Id.There can be multiple Test Case Id seperated by ;)</i> <br></br>
                  &#125;
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How can we update a Test Cycle ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  In order to Update a Test Cycle with Cats Lite we have to mention below Key details in settings of Environment tab.
                  <br></br>
                  <br></br>Example:<br></br>
                  <br></br>
                  &#123;
                  <br></br>
                  <b>"UPDATE_TEST_MANAGEMENT":</b>"YES",<br></br>
                  <b>"TEST_MANAGEMENT":</b>"JIRA",<br></br>
                  <b>"TM_URL":</b>"https://tools.publicis.sapient.com/jira/rest/atm/1.0",<br></br>
                  <b>"TM_ACCESS_TOKEN":</b>" ",<br></br>  ---> <i>(Go to Jira Profile Menu and generate Personal Access Token) </i><br></br>
                  <b>"UPDATE_PLAN":</b>"YES",<br></br>
                  <b>"UPDATE_CYCLE":</b>"YES",<br></br>
                  <b>"CYCLE_ID":</b>"CATS-C50" ---> <i>(Enter your Test Cycle Id)</i> <br></br>
                  <b>"TC_ID":</b>"CATS-T772",  ---> <i>(Enter your Test Case Id.There can be multiple Test Case Id seperated by ;)</i> <br></br>
                  &#125;
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              GPT and PSCHAT Integration        </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>What are the usecases after integrating GPT and PSCHAT with Cats Lite</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>

                  <li>We can create Web Scenario Script</li>
                  <li>We can create Api Scenario Script</li>

                  <br></br>

                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to add tokens for GPT or PSCHAT with Cats Lite</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>

                  Before creating script , you must have your token created ready with PSCHAT or GPT  whichever you want to use.You need to add the
                    token in environemnt Settings <br></br><br></br>
                    <li> To add A GPT token use below key in environment setting.<br></br></li>
                    "<b>GPT_TOKEN</b>" : "Your-token-value"
                    <br></br><br></br>
                    <li> To add A PSCHAT token use below key environment setting.<br></br></li>
                    "<b>PSCHAT_TOKEN</b>" : "Your-token-value"

              

                  <br></br>

                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How can we create a scripts using ChatGPT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To create a script using ChatGPT, you must use the keyword <b>gpt:</b> before your requirement and end with <b>ENDGPT</b> .<br></br>
                  To create a script using PSCHAT, you must use the keyword <b>pschat:</b> before your requirement and end with <b>ENDCHAT</b> .
                  After writing your requirement , please wait for 5-10 seconds for the display of results from ChatGPT.
                  <br></br>
                  <br></br>
                  <li>Example for Creating a Web Scenario script </li>
                  <ul><b>pschat:</b> Login into a Banking application <b>ENDCHAT</b></ul>
                  <ul><b>gpt:</b> Login into a Banking application <b>ENDGPT</b></ul>
                  <li>Example for Creating a Karate API Scenario script </li>
                  <ul><b>pschat:</b> GET Api call in a url <b>ENDCHAT</b></ul>
                  <ul><b>gpt:</b> GET Api call in a url <b>ENDGPT</b></ul>

                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              Jenkins Integration        </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to run a Feature file with Jenkins?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li>Navigate to Jenkins Dashboard and click on New Item</li>
                  <li>Select Project as "Freestyle Project"</li>
                  <li>Under the Configre check "Discard old builds" option</li>
                  <li>Under Build Steps select "Invoke top-level Maven targets"</li>
                  <li>Select the Maven version as per the machine config</li>
                  <li>Under Goals give the following value : -B "-D ciCATSLiteURL=http://catslite.publicissapient.com" "-D ciClientId=cats-lite-api" "-D ciClientSecret=[Project Secret Key]" "-D ciFeatureId=[Feature Id To Run]" "-D ciApplicationId=[Application ID of the Project]" "-D ciEnvironmentId=[Environment ID of the Project]" "-D ciRunScenarioTags=[Run Tags]" -q clean test -Dmaven.clean.failOnError=false</li>
                  <li>Select Advance options and give the following value in POM Text box field "/home/azureuser/JenkinsCICDJavaMaven/pom.xml" where this is the location of the project</li>
                  <li>We need to clone a project in Jenkins machine to trigger the CICD pileline</li>
                  <br></br>
                  <h4>NOTE: Please reach out to the CATS LITE team to get the Secret key and access to the Jenkins Project</h4>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              TROUBLESHOOT INSTRUCTIONS          </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Common failures and resolutions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Try the following suggestions when your automation scripts fail. If they don't work either, open a Support ticket in Jira board to ask experts for help.
                  Your automation script passes but the actual action was not performed
                  Add the following step:
                  <li>•	Command: waitForElementPresent</li>
                  <li>•	Target: Same target as the failing step.</li>
                  <li>•	Value: none.</li>
                  before the failing step.
                  If that doesn't work, then add the following step:
                  <li>•	Command: wait.</li>
                  <li>•	Target: none.</li>
                  <li>•	Value: 5 (seconds).</li>
                  before the failing step.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Your automation script fails because of element not found?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Locators are methods that CATSLite use to find elements on websites. If your automation script fails because of an element not found, use basic wait functions or use a different locator for the failed step.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
                      <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
                      EMAIL REPORTING       </h1>
                      <Accordion style={{ backgroundColor: '#eee' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Email Reporting through SMTP server</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          You can send the mail through SMTP server after running your scripts.
                          To send email messages with reports attached you have to mention below Key details in settings of Environment tab.
                          <br></br>
                          <br></br>Example:<br></br>
                          <br></br>
                          &#123;
                          <br></br>
                          <b>"SEND_EMAIL_ALWAYS":</b>"YES", ---> <i>(value can be YES or NO)</i><br></br>
                          <b>"SEND_EMAIL_ON_FAILURE":</b>"YES", ---> <i>(Required when you want report in case of failure.)</i><br></br>
                          <b>"EMAIL_TO":</b>"kaushal.bharti@publicissapient.com", ---> <i>(Enter Sender Mail.To send email to multiple users, enter mail ids seperated by comma(<b> , </b>) </i><br></br>
                          <b>"EMAIL_SUBJECT":</b>" ",  ---> <i>(Enter Email Subject)</i> <br></br>
                          <b>"EMAIL_BODY":</b>" ", ---> <i>(Enter Email Body) </i><br></br>
                          <b>"EMAIL_SMTP_USERNAME":</b>"",<br></br>
                          <b>"EMAIL_SMTP_PASSWORD":</b>""<br></br>
                          &#125;


                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                    </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              CUSTOM ACTION         </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to do setup for Custom Action Project</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To start with a Custom Action,We will provide you a sample Custom action maven project. To download
                  a project ,Click on Download button given below . File will be downloaded as zip "<b>custom-actions.zip</b>"
                  <br></br>
                  <br></br>
                  Click on this <b>Dowload Button</b> :
                  <Grid item xs={12} sm={2} style={{ fontWeight: 'bold', color: '#3f51b5' }}>

                    <a href={CustomActionZip}>
                      <button style={{ fontWeight: 'bold', color: '#3f51b5', border: 'none' }}>
                        Click to download
                      </button>
                    </a>


                  </Grid>
                  <br></br>
                  <li>After downloading the Project . Open the folder <b>anoroc-custom-action/</b>" in Intellij IDEA ."</li>
                  <li>After downloading,To build the Project for the first time run the bat file "<b>build-custom-jar.bat</b>" present at path "<b>anoroc-custom-action/</b>" </li>
                  <li>Create your Custom java file in a package "<b>com.publicissapient.anaroc.command</b>" present in a project</li>
                  <li>Before creating your own Custom java file ,Refer to "<b>CustomActionTemplate</b>"</li>
                  <li>Create your own Custom java file . You can also copy "<b>CustomActionTemplate</b>" and just change class name to your Custom Class name .</li>
                  <li>You can also refer "<b>CustomActionTemplateExample</b>" file for the methods which can be used in your Custom action while doing development .</li>
                  <li>After writing your Custom Action class, build the project . Open the terminal at path "<b>/anoroc-custom-action </b>" and write the command "<b>mvn clean install</b>" </li>
                  <li>After successfull build, BUILD SUCCESS message should come on terminal and you jar "<b>anoroc-custom-action.jar</b>"
                    should get created at <b>\anoroc-custom-action\target</b> path .</li>
                  <br></br>


                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to execute Custom Action Project?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To Execute a Custom Action Project in a CATS lite follow the below steps :
                  <br></br>
                  <br></br>
                  <li>In your feature file : write  <b>And User CALL_CUSTOM &#123;YourCustomClassName&#125; </b>
                    <br></br>
                    Example : <b>And User CALL_CUSTOM &#123;CustomActionDemo&#125;</b></li>
                  <li>You can call multiple custom class in a feature file </li>
                  <li> After writing in Feature File, You need to <b>Upload</b> and <b>Link</b> "<b>anoroc-custom-action.jar</b>" and all the external jar which is used in your Custom action java file</li>
                  <ul>To <b>Upload</b> any file , Go in your project, Click on "<b>File List</b>", Click on "<b>+</b>"
                    icon and upload a jar.
                  </ul>
                  <ul>To <b>Link</b> any file with a feature, Go in your Feature, Click on "<b>Include Files</b>",Select the uploaded Files .
                  </ul>


                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              Test Plan and Schedule        </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to create a Test Plan and how to Schedule them?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                In test planner we can aggregate and bundle along different type of feature files where we can execute them 
                in a sequence or you can have them scheduled .<br></br>
    
                  <br></br>
                  <li>Click on Test Plan tab and "<b>+</b>" Icon button to create a Test plan</li>
                  <li>After creating a Test Plan, you can add multiple features in a Test Plan by clicking on "<b>MANAGE FEATURES</b>" button. </li>
                  <li>You can schedule the by clicking on "<b>Add Schedule</b>" button . </li>
                  <li>You can also modify the same schedule by clicking on "<b>Modify Schedule</b>" button.</li>
                  <li>While adding and modifying the schedule , you can also use Cron Expression</li>
                  <li>To know more about Cron expression , you can refer to link "<b>https://crontab.cronhub.io/</b>"</li>
                  
                  <br></br>


                </Typography>
              </AccordionDetails>
            </Accordion>
          
          </div>


          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
            LOCAL EXECUTOR       </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to do setup for Local Executor Project</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                To start with a Local Executor ,We will provide you a zip file as a framework ,Click on Download button given below to proceed. File will be downloaded as "<b>cats-lite-fw.zip</b>"
                <br></br>
                <br></br>
                Click on this <b>Dowload Button</b> :
                  <Grid item xs={12} sm={2} style={{ fontWeight: 'bold',  color: '#3f51b5' }}>

                      <a href={catsLiteFwZip}>
                      <button style={{ fontWeight: 'bold',  color: '#3f51b5', border: 'none' }}>
                           Click to download
                      </button>
                      </a>


                  </Grid>
                    <br></br>
                    <li>Pre-Requisites - Install <b> Java 8, Maven, Node, and Postgres SQL </b>in your system</li>
                    <li>While Installing  <b>Postgres SQL</b> make user to give username and password as: <b>postgres</b> </li>
                    <li>Once <b>Postgres SQL </b> is installed ,create a database named: <b>CATSLITE</b> </li>
                    <li>After downloading the framework , firstly build the project"</li>
                    <li>To build the Project, run a bat file "<b>build-local-executor.bat</b>" present at path "<b>/cats-lite-fw/core/anoroc-local-executor/</b>" </li>
                    <li>After building , you can start the backend and frontend with the given bat file "<b>start-backend.bat</b>" and "<b>start-frontend.bat</b>" present at path "<b>/cats-lite-fw/</b>"</li>

                    <br></br>


                </Typography>
              </AccordionDetails>
            </Accordion>

          </div>
          
          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              WINDOWS APP AUTOMATION       </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Pre-Requisites to follow before Automating Windows App</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Before Staring you have to download and install <b>Windows App Driver</b> where windows application will listen and <b>Windows SDK</b> through which you can find 
                  "inspect.exe" for xpaths and locators. Details for installation are provided below:
                  <br></br>
                  <br></br>

                  <li>Download Windows Application Driver installer from : <b>https://github.com/Microsoft/WinAppDriver/releases</b></li>
                  <li>Run the <b>installer</b> on a Windows machine .After Successfull Installation , you can find "WinAppDriver.exe" from the installation directory (E.g. C:\Program Files (x86)\Windows Application Driver)</li>
                  <li>Enable <b>Developer Mode</b> in Windows settings</li>
                  <br></br>
                  <br></br>
                  <li>For referring locators / xpaths you can download windows sdk from : <b>https://developer.microsoft.com/en-us/windows/downloads/windows-10-sdk/ </b> .
                      After downloadng you can find "<b>inspect.exe</b>" file at path( Eg : "C:\Program Files (x86)\Windows Kits\10\bin\10.0.22621.0\x64\inspect.exe") which you can refer for xpath </li>
                 
                  <br></br>


                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>How to perform Automation for Windows Application</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography>
                  In order to perform Automation, we have to mention below Key details in settings of Environment tab. Also make sure that before running 
                  the script double click on "<b>WinAppDriver.exe</b>" at installed path: <b>C:\Program Files (x86)\Windows Application Driver</b> . After 
                  Clicking Windows Application Driver will start listening at port 4723 by default.
                  <br></br>
                  <br></br>
                  &#123;
                  <br></br>
                  <b>"remote.webdriver.url":"https://30ce-103-199-200-229.ngrok-free.app"</b>,  ---> <i>(Here you have to mention ngrok port forwarded url.
                    Since by default Windows Application Driver listens at port 4723, I have done port frowarding at 4723 using ngrok( command: ngrok http 4723 ) </i> <br></br>
                  <b>"webdriver.type":"WINDOWS"</b>,<br></br>
                  <b>"capabilities":</b>" ",<br></br>
                  &#123;
                  <br></br>
                  <b>"app":"C:\\Windows\\System32\\notepad.exe"</b>, ---> <i>(Application Id or path or Application.Example given for notepad app) </i><br></br>
                  <b>"appArguments":</b>"  ",<br></br>
                  <b>"appWorkingDir":</b>"  ",<br></br>
                  &#125;
                  <br></br>
                  <b>*Note</b> : You can add other valid windows capabilities as well.
                  <br></br>
                  &#125;

                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              REPORT DISPLAY      </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Report Display Enhancement</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                
                  <li>Processed Environment Test Data will be shown in Reports . Locators will remail as it is. </li>
                  <br></br>
                  <li>In case if you want to not Dislpay the particular steps in report, just start the stepdefinition instruction with exclamation <b>!</b> . Note : Step will be
                    displayed in report if that particular step gets FAILED even if you start with<b> !</b> . <br></br>
                    <b>Example</b> : And <b>!</b>User CLICK on [locator] </li>
                  <br></br>  
                  <li> You can enable soft asserion in fearure files using <b>"ENABLE_SOFT_ASSERT":"YES" </b>in Environment settings. So Execution will continue and will execute next steps even if 
                    particular step gets FAILED . Please note that currently by default it waits for 50 seconds if  particular step FAILED and xpath is not found 
                    for any reason. You can reduce this by adding this property <b>"Explicit.wait.time":10</b> . Now it will for 10 seconds  </li>
              
                

                 
                  <br></br>


                </Typography>
              </AccordionDetails>
            </Accordion>
            
          </div>
          

          <div style={{ paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >REFERENCES</h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Browser Options-> Chrome And Firefox</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography> Below are the required configuration details required to setup Chrome and Firefox options:<br></br>
                  <br></br>
                  <b><u>FOR CHROME BROWSER:</u></b>
                  <br></br>
                  <br></br>
                  <b>1. Passing Arguments in Environment tab as below:</b>
                  <code>
                    <br></br>
                    <br></br>
                    "arguments":["ignore-certificate-errors", "start-maximized", "incognito", "headless"]
                    <br></br>
                  </code>
                  <br></br>
                  Below are the list of available and most commonly used arguments for ChromeOptions class
                  <br></br>
                  <br></br>
                  <b>start-maximized:</b> Opens Chrome in maximize mode<br></br>
                  <b>incognito:</b> Opens Chrome in incognito mode<br></br>
                  <b>headless:</b> Opens Chrome in headless mode<br></br>
                  <b>disable-extensions:</b> Disables existing extensions on Chrome browser<br></br>
                  <b>disable-popup-blocking:</b> Disables pop-ups displayed on Chrome browser<br></br>
                  <b>make-default-browser:</b> Makes Chrome default browser<br></br>
                  <b>version:</b> Prints chrome browser version<br></br>
                  <b>disable-infobars:</b> Prevents Chrome from displaying the notification ‘Chrome is being controlled by automated software<br></br>
                  <b>headless:</b> Headless browser runs in the background. You will not see the browser GUI or the operations been operated on it.
                  <br></br>
                  <br></br>
                  <b>2. Passing Desired Capabilities in Environment tab as below:</b>
                  <br></br>
                  <br></br>
                  <code>
                    "capabilities":<br></br>
                    &#123;<br></br>
                    "ACCEPT_SSL_CERTS":"true",<br></br>
                    "PLATFORM_NAME":"",<br></br>
                    "BROWSER_NAME":"",<br></br>
                    "VERSION":""<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                  Below are the most commonly used pre-defined capability types:
                  <br></br>
                  <br></br>
                  <b>ACCEPT_SSL_CERTS-</b> This property tells the browser to accept SSL Certificates by default<br></br>
                  <b>PLATFORM_NAME-</b> This property is used to set the operating system platform used to access the web site<br></br>
                  <b>BROWSER_NAME-</b> This property is used to set the browser name for a web driver instance<br></br>
                  <b>VERSION-</b> This property to used to set the browser version
                  <br></br>
                  <br></br>
                  <b><u>FOR FIREFOX BROWSER:</u></b><br></br>
                  <br></br>
                  <b>Passing Arguments in Environment tab as below:</b>
                  <code>
                    <br></br>
                    <br></br>
                    "arguments":["ignore-certificate-errors", "start-maximized", "incognito", "headless"]
                  </code>
                  <br></br>
                  Below are the list of available and most commonly used arguments for ChromeOptions class
                  <br></br>
                  <br></br>
                  --headless -   To launch firefox in headless mode<br></br>
                  --disable-gpu - To disable GPU<br></br>
                  --start-fullscreen - To start browser with full screen<br></br>
                  --disable-extensions - To disable extensions<br></br>
                  --disable-popup-blocking - To disable pop-up blocking<br></br>
                  --disable-notifications - To disable notifications<br></br>
                  --window-size=1920,1080 - To launch browser in specific pixel size<br></br>
                  start-maximized - To start browser with maximized screen<br></br>
                  enable-automation - To enable automation in browser<br></br>
                  disable-features=NetworkService - To disable network services<br></br>
                  <br></br>
                  <br></br>
                  <b>3. Passing Experimental Option Environment tab as below:</b>
                  <br></br>
                  <br></br>
                  <code>
                    "prefs":<br></br>
                    &#123;<br></br>
                    "download.default_directory":"your\\path\\to\\download",<br></br>
                    "safebrowsing.enabled":""<br></br>
                    &#125;
                  </code>
                  <br></br>
                  <br></br>
                  Above is the experimental option to download a file at particular local path.<br></br>
                  *Note : This above Experimental Option will work to download file to your local path if you are running on your local machine browser
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Creative Pixel Pattern</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Pixel pattern listed below are appended to the expected object which will be compared against the actual and evaluated accordingly. These pixel patterns are mainly applicable to following creative actions <b>VerifyLayoutLeft, VerifyLayoutRight, VerifyLayoutBelow, VerifyLayoutAbove</b>
                </Typography>
              </AccordionDetails>
              <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                <Table border="2" className={classes.table} aria-label="simple table">
                  <TableHead bgcolor="green">
                    <TableRow>
                      <TableCell align="left">DESCRIPTION</TableCell>
                      <TableCell align="left">EXAMPLE/PIXEL PATTERN</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Fixed Pixel</TableCell>
                      <TableCell align="left">10px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Pixel with acceptable(default) deviation</TableCell>
                      <TableCell align="left">~10px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Pixel with overridden deviation</TableCell>
                      <TableCell align="left">10px~3px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Pixel range</TableCell>
                      <TableCell align="left">10-20px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Pixel range with acceptable(default) deviation</TableCell>
                      <TableCell align="left">~10-20px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Pixel range with overridden deviation</TableCell>
                      <TableCell align="left">10-20px~5px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Greater than given pixel</TableCell>
                      <TableCell align="left">gt10px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Greater than or equals to given pixel</TableCell>
                      <TableCell align="left">gte10px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Lesser than given pixel</TableCell>
                      <TableCell align="left">lt10px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Lesser than or equals to given pixel</TableCell>
                      <TableCell align="left">lte10px</TableCell>
                    </TableRow>
                    <TableRow key='ac'>
                      <TableCell component="th" scope="row">Pixel will be calculated using ObjectRef height or width</TableCell>
                      <TableCell align="left">25%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Creative Distance Measure</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Distance between two elements can be verified using different mode, to alter the distance calculation user needs to mention the type of distance to be calculated in the test data. These are applicable to applicable to following creative actions <b>VerifyLayoutLeft, VerifyLayoutRight, VerifyLayoutBelow, VerifyLayoutAbove</b>.
                  <p><b>Test Data</b></p>
                  <p><b>margin:</b> Distance between two elements will be calculated from their margins</p>
                  <p><b>border:</b> Distance between two elements will be calculated from their borders excluding margin</p>
                  <p><b>padding:</b> Distance between two elements will be calculated from their padding excluding margin and borders</p>
                  <p><b>actual:</b> Distance between two elements will be calculated from their actual content excluding margin, borders and padding</p>
                  <p><b>default:</b> When the test data is left blank distance between two elements will be calculated from their borders excluding margins</p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>NGROK Tunnel Creation and Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography><b>Below are the steps to create NGROK tunnel and use it with CATS Lite:</b>
                  <br></br>
                  1. Open https://ngrok.com/ in web browser and sign-up.<br></br>
                  2. After Sign-in go to Setup & Installation tab (left pane)<br></br>
                  3. Follow the given 3 steps to setup the ngrok tunnel:<br></br>
                  <li>Unzip to install</li>
                  <li>Connect your account</li>
                  <li>Fire it up (Here port should be your grid port)</li>
                  <br></br>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>Selenium Grid Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography><b></b>.
                  <code>
                    &#123;<br></br>
                    "remote.webdriver.url":"http://catslite.publicissapient.com:4444/wd/hub",<br></br>
                    "webdriver.type":"CHROME"<br></br>
                    &#125;<br></br>
                    Note: Browser can be FIREFOX, CHROME, SAFARI, BROWSERSTACK, MOBILE, PCLOUDY
                  </code>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ paddingTop: '40px', paddingBottom: '20px', marginRight: '20px', marginLeft: '20px' }}>
            <h1 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Roboto' }} >
              UI TESTING COMMANDS
            </h1>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ACCEPT_ALERT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to accept the notification/alert visible on the screen.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ACCEPT_ALERT</TableCell>
                          <TableCell align="left">Then ACCEPT_ALERT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Accept the notification or alert.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_ACCEPT_NOTIFICATION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to accept the notification on mobile application.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_ACCEPT_NOTIFICATION</TableCell>
                          <TableCell align="left">Then APP_ACCEPT_NOTIFICATION</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Accept the notification or alert on application.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_ACTIVATE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action activates the app matching package / bundle id given in the environment and brings that app to foreground if already installed.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_ACTIVATE</TableCell>
                          <TableCell align="left">Then APP_ACTIVATE </TableCell>
                          <TableCell align="left">App package name or BundleId, please refer capabilities section in Mobile Testing section</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will activate the app whose package name or bundle id is mentioned in the Environment.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_CANCEL_NOTIFICATION_BY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to Cancel or dismiss the notification on the application.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_CANCEL_NOTIFICATION_BY</TableCell>
                          <TableCell align="left">Then APP_CANCEL_NOTIFICATION_BY</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Cancel/Dismiss or reject the notification on mobile application.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_CAPTURE_SCREENSHOT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to capture the current screen.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_CAPTURE_SCREENSHOT</TableCell>
                          <TableCell align="left">Then APP_CAPTURE_SCREENSHOT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will capture the current screen.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_CLEAR</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to clear the referenced element location.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_CLEAR</TableCell>
                          <TableCell align="left">Then APP_CLICK [Locator]
                            <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will clear the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click at the referenced element location.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_CLICK</TableCell>
                          <TableCell align="left">Then APP_CLICK [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will click at the given element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_CLOSE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to close the application on the device.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_CLOSE</TableCell>
                          <TableCell align="left">Then APP_CLOSE</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will close the currently opened application on the device.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_DOUBLE_TAP_ON_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will double tap on the center of the element. If test data is provided it will be treated as X offset and Y offset separated by ";". The offset is calculated from X;Y co-ordinates of top left corner of the element. If the third parameter is provided its treated as tap-count, if not its considered as 1.</p>
                  <p><b>Required</b>: Element Reference, </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_DOUBLE_TAP_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_DOUBLE_TAP_ON_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will double tap at the center of the element.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_DOUBLE_TAP_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_DOUBLE_TAP_ON_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">5;30;3</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will double tap 3 times on co-ordinate X+5 ;Y+30 position where X;Y is a co-ordinate of top left corner of the element.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_DOUBLE_TAP_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_DOUBLE_TAP_ON_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will double tap once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_DOUBLE_TAP_ON_SCREEN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will double tap on the center of the screen. If test data is provided it will be treated as X offset and Y offset seperated by ";". The offset is calculated from X;Y co-ordinates of top left corner of the screen. If the third parameter is provided its treated as tap-count, if not its considered as 1.</p>
                  <p><b>Required</b>: Argument </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_DOUBLE_TAP_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_DOUBLE_TAP_ON_SCREEN [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will double tap at the center of the screen.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_DOUBLE_TAP_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_DOUBLE_TAP_ON_SCREEN [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">5;30;3</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will double tap 3 times on co-ordinate X+5 ;Y+30 position where X;Y is a co-ordinate of top left corner of the screen.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_DOUBLE_TAP_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_DOUBLE_TAP_ON_SCREEN [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will double tap once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the screen.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_ELEMENT_DOUBLE_TAP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to double tap on the referenced element.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_ELEMENT_DOUBLE_TAP</TableCell>
                          <TableCell align="left">Then APP_ELEMENT_DOUBLE_TAP [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Double tap on the referenced element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_ENTER_DATA</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to double tap on the mobile screen.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_ENTER_DATA</TableCell>
                          <TableCell align="left">Then APP_ENTER_DATA [Locator] &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Sanjeet</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will enter 'Sanjeet' at given element location. </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_ELEMENT_TAP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to tap on the referenced element in application.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_ELEMENT_TAP</TableCell>
                          <TableCell align="left">Then APP_ELEMENT_TAP [Locator]
                            <br></br>* Locator is an xpath described in the Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Tap on the given element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_INSTALL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to install application on mobile device.</p>
                  <p><b>Required</b>: Capabilities in Environment Tab </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTIOn </TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_INSTALL</TableCell>
                          <TableCell align="left">Then APP_INSTALL </TableCell>
                          <TableCell align="left">Capabilities should be given in Environment tab.</TableCell>
                          <TableCell align="left">For more details please refer to the MOBILE TESTING Section.</TableCell>
                          <TableCell align="left">Install application based on the capabilities described in Environment.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_KILL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to kill the application on the device.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_KILL</TableCell>
                          <TableCell align="left">Then APP_KILL</TableCell>
                          <TableCell align="left">Capabilities should be given in Environment tab.</TableCell>
                          <TableCell align="left">For more details please refer to the MOBILE TESTING Section.</TableCell>
                          <TableCell align="left">Kill the application based on the given capabilities in Environment tab.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_LAUNCH</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to launch the application on the device.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="center">Action </TableCell>
                          <TableCell align="center">Syntax</TableCell>
                          <TableCell align="center">Capabilities</TableCell>
                          <TableCell align="center">Outcome</TableCell>
                          <TableCell align="center">Example</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_LAUNCH</TableCell>
                          <TableCell align="left">Then APP_LAUNCH </TableCell>
                          <TableCell align="left">Capabilities should be given in Environment tab.</TableCell>
                          <TableCell align="left">For more details please refer to the MOBILE TESTING Section.</TableCell>
                          <TableCell align="left">Launch the application based on the capabilities given in Environment tab.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_LONG_PRESS_ON_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will perform a long press on the center of the element. If test data is provided it will be treated as X offset and Y offset seperated by ";". The offset is calculated from X;Y co-ordinates of top left corner of the element.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_LONG_PRESS_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_LONG_PRESS_ON_ELEMENT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> This action will long press at the center of the element.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_LONG_PRESS_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_LONG_PRESS_ON_ELEMENT &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will long press once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_LONG_PRESS_ON_SCREEN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will perform a long press on the center of the screen. If test data is provided it will be treated as X offset and Y offset seperated by ";". The offset is calculated from X;Y co-ordinates of top left corner of the screen.</p>
                  <p><b>Required</b>: Test Data</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_LONG_PRESS_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_LONG_PRESS_ON_SCREEN</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> This action will long press at the center of the screen.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_LONG_PRESS_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_LONG_PRESS_ON_SCREEN &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will long press once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the screen.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_NAVIGATE_BACK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to navigate back in the browser.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>:MOBILE</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_NAVIGATE_BACK</TableCell>
                          <TableCell align="left">Then APP_NAVIGATE_BACK</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will navigate back to the last page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_PRESS_ON_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will perform a press on the center of the element. If test data is provided it will be treated as X offset and Y offset separated by ";". The offset is calculated from X;Y co-ordinates of top left corner of the element.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_PRESS_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_PRESS_ON_ELEMENT [Locator]  <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will press at the center of the element.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_PRESS_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_PRESS_ON_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will press once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_PRESS_ON_SCREEN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action press on the screen center, if argument is provided it will be treated as coordinates.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_PRESS_ON_SCREEN &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Then APP_PRESS_ON_SCREEN</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will press at the center of the screen.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_PRESS_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_PRESS_ON_SCREEN [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will press once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the screen.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_QUIT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action quits the session and driver.</p>
                  <p><b>Required</b>: Action Name </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_QUIT</TableCell>
                          <TableCell align="left">Then APP_QUIT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will quit the current application.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_REMOVE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to remove the application from the device.</p>
                  <p><b>Required</b>: Capabilities </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_REMOVE</TableCell>
                          <TableCell align="left">Then APP_REMOVE </TableCell>
                          <TableCell align="left">For more details please refer to the MOBILE TESTING Section.</TableCell>
                          <TableCell align="left">Application Capabilities</TableCell>
                          <TableCell align="left">Kill the application based on the given capabilities in Environment tab.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_RESET</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to reset the current session of the application.</p>
                  <p><b>Required</b>: Action Name </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_RESET</TableCell>
                          <TableCell align="left">Then APP_RESET</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action wil reset the current application.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
             <Accordion style={{ backgroundColor: '#eee' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SCROLL_TO_BOTTOM</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                              <p><b>Description</b>:This action is used to scroll the app to the bottom.</p>
                              <p><b>Required</b>: Test Data </p>
                              <p><b>Applicable To</b>: Mobile </p>
                              <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                <Table border="2" className={classes.table} aria-label="simple table">
                                  <TableHead bgcolor="green">
                                    <TableRow>
                                      <TableCell align="left">ACTION</TableCell>
                                      <TableCell align="left">SYNTAX</TableCell>
                                      <TableCell align="left">TEST DATA</TableCell>
                                      <TableCell align="left">EXPECTED RESULT</TableCell>
                                      <TableCell align="left">OUTCOME</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key='ac'>
                                      <TableCell component="th" scope="row">APP_SCROLL_TO_BOTTOM</TableCell>
                                      <TableCell align="left">Then APP_SCROLL_TO_BOTTOM </TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left">This action is used to scroll the app to the bottom.</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </AccordionDetails>
                        </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SCROLL_TO_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to scroll the page for the given maximum scroll number till xpath is found .If maximum scroll
                  number is not given it will by default scroll for 3 times.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SCROLL_TO_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_SCROLL_TO_ELEMENT &#123; options &#125; on on [locator] <br></br>* Where &#123; options &#125; should be maximum scroll number declared in UI Testing section of Environment.
                            * [locator] is described in the Object repository</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action is used to scroll the page for the given maximum scroll number till xpath is found.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SCROLL_TO_LEFT</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                                          <p><b>Description</b>:This action is used to scroll the app to the left.</p>
                                          <p><b>Required</b>: Test Data </p>
                                          <p><b>Applicable To</b>: Mobile </p>
                                          <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                            <Table border="2" className={classes.table} aria-label="simple table">
                                              <TableHead bgcolor="green">
                                                <TableRow>
                                                  <TableCell align="left">ACTION</TableCell>
                                                  <TableCell align="left">SYNTAX</TableCell>
                                                  <TableCell align="left">TEST DATA</TableCell>
                                                  <TableCell align="left">EXPECTED RESULT</TableCell>
                                                  <TableCell align="left">OUTCOME</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow key='ac'>
                                                  <TableCell component="th" scope="row">APP_SCROLL_TO_LEFT</TableCell>
                                                  <TableCell align="left">Then APP_SCROLL_TO_LEFT </TableCell>
                                                  <TableCell align="left"></TableCell>
                                                  <TableCell align="left">This action is used to scroll the app to the left.</TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
              <Accordion style={{ backgroundColor: '#eee' }}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                      <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SCROLL_TO_RIGHT</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                                                        <p><b>Description</b>:This action is used to scroll the app to the right.</p>
                                                        <p><b>Required</b>: Test Data </p>
                                                        <p><b>Applicable To</b>: Mobile </p>
                                                        <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                                          <Table border="2" className={classes.table} aria-label="simple table">
                                                            <TableHead bgcolor="green">
                                                              <TableRow>
                                                                <TableCell align="left">ACTION</TableCell>
                                                                <TableCell align="left">SYNTAX</TableCell>
                                                                <TableCell align="left">TEST DATA</TableCell>
                                                                <TableCell align="left">EXPECTED RESULT</TableCell>
                                                                <TableCell align="left">OUTCOME</TableCell>
                                                              </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                              <TableRow key='ac'>
                                                                <TableCell component="th" scope="row">APP_SCROLL_TO_RIGHT</TableCell>
                                                                <TableCell align="left">Then APP_SCROLL_TO_RIGHT </TableCell>
                                                                <TableCell align="left"></TableCell>
                                                                <TableCell align="left">This action is used to scroll the app to the left.</TableCell>
                                                              </TableRow>
                                                            </TableBody>
                                                          </Table>
                                                        </TableContainer>
                                                      </div>
                                                    </AccordionDetails>
                                                  </Accordion>
             <Accordion style={{ backgroundColor: '#eee' }}>
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SCROLL_TO_TOP</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                                          <p><b>Description</b>:This action is used to scroll the app to the top.</p>
                                          <p><b>Required</b>: Test Data </p>
                                          <p><b>Applicable To</b>: Mobile </p>
                                          <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                            <Table border="2" className={classes.table} aria-label="simple table">
                                              <TableHead bgcolor="green">
                                                <TableRow>
                                                  <TableCell align="left">ACTION</TableCell>
                                                  <TableCell align="left">SYNTAX</TableCell>
                                                  <TableCell align="left">TEST DATA</TableCell>
                                                  <TableCell align="left">EXPECTED RESULT</TableCell>
                                                  <TableCell align="left">OUTCOME</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow key='ac'>
                                                  <TableCell component="th" scope="row">APP_SCROLL_TO_TOP</TableCell>
                                                  <TableCell align="left">Then APP_SCROLL_TO_TOP </TableCell>
                                                  <TableCell align="left"></TableCell>
                                                  <TableCell align="left">This action is used to scroll the app to the top.</TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SEND_KEYS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action sends the keystokes to the given element.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SEND_KEYS</TableCell>
                          <TableCell align="left">Then APP_SEND_KEYS [Locator] + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">T-Shirts</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will send the given test data (T-Shirts) at the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SET_ATTRIBUTE_VARIABLE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to get any attribute value of the element and store it into the variable which can be further accessed in subsequent steps in the same suite.</p>
                  <p><b>Required</b>: Element Reference, Test Data, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SET_ATTRIBUTE_VARIABLE</TableCell>
                          <TableCell align="left">Then APP_SET_ATTRIBUTE_VARIABLE [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">fontFamily</TableCell>
                          <TableCell align="left">Arial</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will set the attribute value and use in it as a referenced argument.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

             <Accordion style={{ backgroundColor: '#eee' }}>
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SET_ELEMENT_TEXT</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                                          <p><b>Description</b>: This action set the element text into a variable which will be your test data and can be used within entire feature file</p>
                                          <p><b>Required</b>: Locator and Test Data </p>
                                          <p><b>Applicable To</b>: Desktop </p>
                                          <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                            <Table border="2" className={classes.table} aria-label="simple table">
                                              <TableHead bgcolor="green">
                                                <TableRow>
                                                  <TableCell align="left">ACTION</TableCell>
                                                  <TableCell align="left">SYNTAX</TableCell>
                                                  <TableCell align="left">TEST DATA</TableCell>
                                                  <TableCell align="left">EXPECTED RESULT</TableCell>
                                                  <TableCell align="left">OUTCOME</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow key='ac'>
                                                  <TableCell component="th" scope="row">SET_ELEMENT_TEXT</TableCell>
                                                  <TableCell align="left">Then SET_ELEMENT_TEXT [locator] + &lt;TestData&gt; <br></br>* Where test data will be variable name in which element text will be stored.</TableCell>
                                                  <TableCell align="left">Variable Name</TableCell>
                                                  <TableCell align="left"></TableCell>
                                                  <TableCell align="left">This action set the element text into a variable which used in later steps</TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SET_VARIABLE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action gets the text of the element and store in the variable which can be accessed in later actions or steps.</p>
                  <p><b>Required</b>: Element Reference & Argument </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SET_VARIABLE</TableCell>
                          <TableCell align="left">Then APP_SET_VARIABLE [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">OrderNumber</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will set the attribute value and can be used in later steps</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SUBMIT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to submit the form if the element belongs to the form.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SUBMIT</TableCell>
                          <TableCell align="left">Then APP_SUBMIT [Locator] <br></br>* Where locator should be described in locators page</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will submit the form based on the given element reference.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SWIPE_DOWN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action swipes to down from 10 to 85 percent, if Test Data is provided then it will swipe based on the given coordinate.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_DOWN</TableCell>
                          <TableCell align="left">Then APP_SWIPE_DOWN + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action swipes from top to down 15 to 85 percent.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_DOWN</TableCell>
                          <TableCell align="left">Then APP_SWIPE_DOWN + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">20;50;20;85</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will swipe from (5, 50) to (20, 85).</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SWIPE_LEFT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action swipes to left from 85 to 10 percent, if Test Data is provided then it will swipe based on the given coordinate.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_LEFT</TableCell>
                          <TableCell align="left">Then APP_SWIPE_LEFT + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action swipes from right to left 15 to 85 percent.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_LEFT</TableCell>
                          <TableCell align="left">Then APP_SWIPE_LEFT + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">20;50;20;85</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will swipe from (5, 50) to (20, 85).</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SWIPE_RIGHT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action swipes to right from 10 to 85 percent, if Test Data is provided then it will swipe based on the given coordinate.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_RIGHT</TableCell>
                          <TableCell align="left">Then APP_SWIPE_RIGHT + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action swipes from left to right 15 to 85 percent.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_RIGHT</TableCell>
                          <TableCell align="left">Then APP_SWIPE_RIGHT + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">20;50;20;85</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will swipe from (5, 50) to (20, 85).</TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SWIPE_UP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action swipes to up from 85 to 10 percent, if Test Data is provided then it will swipe based on the given coordinate.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_UP</TableCell>
                          <TableCell align="left">Then APP_SWIPE_UP + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action swipes from down to top 15 to 85 percent.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWIPE_UP</TableCell>
                          <TableCell align="left">Then APP_SWIPE_UP + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">20;50;20;85</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will swipe from (5, 50) to (20, 85).</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_SWITCH_ORIENTATION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action switches the orientation from Landscape to Portrait and vice-versa, if Argument is given then it will change the orientation accordingly.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWITCH_ORIENTATION</TableCell>
                          <TableCell align="left">Then APP_SWITCH_ORIENTATION </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will rotate to Landscape or Portrait.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_SWITCH_ORIENTATION</TableCell>
                          <TableCell align="left">Then APP_SWITCH_ORIENTATION + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">LANDSCAPE</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will rotate the screen to landscape mode.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_TAP_ON_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will tap on the center of the element. If Argument is provided it will be treated as X offset and Y offset separated by ";". The offset is calculated from X;Y co-ordinates of top left corner of the element. If the third parameter is provided its treated as tap-count, if not its considered as 1.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TAP_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_TAP_ON_ELEMENT [Locator]</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will tap at the center of the element.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TAP_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_TAP_ON_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">5;30;3</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will tap 3 times on co-ordinate X+5 ;Y+30 position where X;Y is a co-ordinate of top left corner of the element.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TAP_ON_ELEMENT</TableCell>
                          <TableCell align="left">Then APP_TAP_ON_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will tap once on co-ordinate X+50 ;Y+50 position where X;Y is a co-ordinate of top left corner of the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_TAP_ON_SCREEN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action tap on the screen center, if argument is provided it will be treated as coordinates.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TAP_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_TAP_ON_SCREEN [Locator]</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will tap at the center of the screen.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TAP_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_TAP_ON_SCREEN [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">5;30;3</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will tap at Screen coordinate 5, 30 and taps 3 times.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TAP_ON_SCREEN</TableCell>
                          <TableCell align="left">Then APP_TAP_ON_SCREEN [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50;50</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> This action will tap at Screen coordinate 50, 50.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_TERMINATE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to terminate the current application.</p>
                  <p><b>Required</b>: Action Name </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_TERMINATE</TableCell>
                          <TableCell align="left">Then APP_TERMINATE</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will terminate the current application opened on the device.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_UNINSTALL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to uninstall the mobile application.</p>
                  <p><b>Required</b>: Capabilities in Environment tab</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_UNINSTALL</TableCell>
                          <TableCell align="left">Then APP_UNINSTALL</TableCell>
                          <TableCell align="left">Capabilities should be given in Environment tab.</TableCell>
                          <TableCell align="left">For more details please refer to the MOBILE TESTING Section.</TableCell>
                          <TableCell align="left">Uninstall the application based on the given capabilities in Environment tab.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_APP_INSTALLED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies whether app is installed or not.</p>
                  <p><b>Required</b>: Capabilities in Environment tab </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_APP_INSTALLED</TableCell>
                          <TableCell align="left">Then APP_VERIFY_APP_INSTALLED</TableCell>
                          <TableCell align="left">Capabilities should be given in Environment tab.</TableCell>
                          <TableCell align="left">For more details please refer to the MOBILE TESTING Section.</TableCell>
                          <TableCell align="left">It will verify the application based on the given capabilities in Environment tab.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_APP_STATE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies the app state. The valid values are NOT_INSTALLED, NOT_RUNNING, RUNNING_IN_BACKGROUND_SUSPENDED,RUNNING_IN_BACKGROUND, RUNNING_IN_FOREGROUND.</p>
                  <p><b>Required</b>: Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_APP_STATE</TableCell>
                          <TableCell align="left">Then APP_VERIFY_APP_STATE &#123;ExpectedResult&#125; <br></br>* Where &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">RUNNING_IN_FOREGROUND</TableCell>
                          <TableCell align="left">It will verify that the given application is RUNNING_IN_FOREGROUND of the device.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_ATTRIBUTE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies the attribute value with the expected value.</p>
                  <p><b>Required</b>: Element Reference, Test Data, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_ATTRIBUTE</TableCell>
                          <TableCell align="left">Then APP_VERIFY_ATTRIBUTE [Locator] + &lt;TestData&gt; + #123; guide &#125; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; / &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">id</TableCell>
                          <TableCell align="left">guide</TableCell>
                          <TableCell align="left">This action will pass when attribute id value is matching with the expected value (guide)</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_DEVICE_LOCK_STATUS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies whether devices is locked or not.</p>
                  <p><b>Required</b>: Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_DEVICE_LOCK_STATUS</TableCell>
                          <TableCell align="left">Then APP_VERIFY_DEVICE_LOCK_STATUS &#123; true or false &#125; <br></br>* Where &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">true or false</TableCell>
                          <TableCell align="left">This will verify that device is locked or not based upon the expected result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_IS_BROWSER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies whether the app is browser or native app.</p>
                  <p><b>Required</b>: Action Name </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_IS_BROWSER</TableCell>
                          <TableCell align="left">Then APP_VERIFY_IS_BROWSER</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will pass if the current instance is a browser.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_IS_DISPLAYED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to double tap on the mobile screen.</p>
                  <p><b>Required</b>: Element Reference, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_IS_DISPLAYED</TableCell>
                          <TableCell align="left">Then APP_VERIFY_IS_DISPLAYED [Locator] &#123; expected_result &#125; <br></br>* where [Locator] described in Object Repository and &#123; expected_result &#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">true or false</TableCell>
                          <TableCell align="left">This will verify the element is displayed and provide result based upon the Expected Result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_IS_ENABLED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies whether the element is enabled or not.</p>
                  <p><b>Required</b>: Element Reference, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_IS_ENABLED</TableCell>
                          <TableCell align="left">Then APP_VERIFY_IS_ENABLED [Locator] &#123; expected_result &#125; <br></br>* where [Locator] described in Object Repository and &#123; expected_result &#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">true of false</TableCell>
                          <TableCell align="left">This will verify the element is enabled and provide result based upon the expected_result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_IS_SELECTED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verifies whether the element is selected or not.</p>
                  <p><b>Required</b>: Element Reference, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_IS_SELECTED</TableCell>
                          <TableCell align="left">Then APP_VERIFY_IS_SELECTED [Locator] &#123; expected_result &#125; <br></br>* where [Locator] described in Object Repository and &#123; expected_result &#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">true of false</TableCell>
                          <TableCell align="left">This will verify the element is enabled and provide result based upon the expected_result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>APP_VERIFY_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to double tap on the mobile screen.</p>
                  <p><b>Required</b>:Element Reference, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">APP_VERIFY_TEXT</TableCell>
                          <TableCell align="left">Then APP_VERIFY_TEXT [Locator] + &#123; expected_result &#125; <br></br>* Where locator should be described in locators page and &lt;TestData&gt;/&#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Bold Elements Bell Sleeve</TableCell>
                          <TableCell align="left">This action will pass if the element text matches expected result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>AXE_CHECK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to run the accessibility check for the current page.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">AXE_CHECK</TableCell>
                          <TableCell align="left">Then AXE_CHECK</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action runs the accessibility check for the current page and generates the Accessibility Report.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CALL_CUSTOM</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to call Custom Action Class which are created in your Custom Action Project</p>
                  <p><b>Required</b>: Options, Element Reference(Optional), Test Data(Optional) </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CALL_CUSTOM</TableCell>
                          <TableCell align="left">Then CALL_CUSTOM &#123;YourCustomClassName&#125; [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Test Data Used in you Custom Class</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will call your Custom Action Class</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CAPTURE_SCREENSHOT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to capture the current screen.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CAPTURE_SCREENSHOT</TableCell>
                          <TableCell align="left">Then CAPTURE_SCREENSHOT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will capture the current screen.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLEAR_FIELD</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to clear the text from a referenced element location.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLEAR_FIELD</TableCell>
                          <TableCell align="left">Then CLEAR_FIELD [Locator] <br></br>* Where locator is an xpath described in Object Repository</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Clear the text from referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click on a referenced element location.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK</TableCell>
                          <TableCell align="left">Then CLICK [Locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click at the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_AT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: Mouse cursor will be moved at the element and the element is clicked.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_AT</TableCell>
                          <TableCell align="left">Then CLICK_AT [Locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click at the search_button based on the offset value.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_JS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click on a JS element.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_JS</TableCell>
                          <TableCell align="left">Then CLICK_JS [Locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will click at the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_AND_ENTER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click and enter data using Selenium Action class.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_AND_ENTER</TableCell>
                          <TableCell align="left">Then CLICK_AND_ENTER [Locator] and enter &lt;TestData&gt; </TableCell>
                          <TableCell align="left"> date to be entered </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will move mouse to the element click at it and enter the data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_ON_EXACT_SUB_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click on the exact sub-element of a given element reference or a list of data.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_ON_EXACT_SUB_ELEMENT</TableCell>
                          <TableCell align="left">Then CLICK_ON_EXACT_SUB_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Master Card</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click at the data which is described in test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_ON_INDEX_SUB_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click on a index value given in example table + element reference.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_ON_INDEX_SUB_ELEMENT</TableCell>
                          <TableCell align="left">Then CLICK_ON_INDEX_SUB_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">5</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click on the index_Value given in the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_ON_LINK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to click on a link described in test data.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_ON_LINK</TableCell>
                          <TableCell align="left">Then CLICK_ON_LINK [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Add To Cart</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click at the link given in the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_ON_PARTIAL_LINK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to click on a partial link text given in the test data.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_ON_PARTIAL_LINK</TableCell>
                          <TableCell align="left">Then CLICK_ON_PARTIAL_LINK [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">partial_link_text</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click at the link_text given in the test data</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_ON_SUB_ELEMENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to click on a sub-element of a given referenced element.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_ON_SUB_ELEMENT</TableCell>
                          <TableCell align="left">Then CLICK_ON_SUB_ELEMENT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Value</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Click at the subelement given in the reference data</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLICK_RANDOM</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used on the element reference that matches more than one element for the given property. This action is mainly used to perform the random click on the product, color, size etc., which is dynamically gets generated during runtime.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLICK_RANDOM</TableCell>
                          <TableCell align="left">Then CLICK_RANDOM [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Randomly clicks at the given element reference.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>CLOSE_TAB</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to close the browser window based on the window title.</p>
                  <p><b>Required</b>: Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">CLOSE_TAB</TableCell>
                          <TableCell align="left">Then CLOSE_TAB + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">DemoQA</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Close the browser tab DemoQA.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>DELAY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to delay the execution based on the given delay_value (seconds) if the element is not present at the given element reference.</p>
                  <p><b>Required</b>:Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">DELAY</TableCell>
                          <TableCell align="left">Then DELAY [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Execution will delay for 10 seconds if element is not present at the given location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>DELETE_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to delete the text value from given element reference.</p>
                  <p><b>Required</b>:Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">DELETE_TEXT</TableCell>
                          <TableCell align="left">Then DELETE_TEXT [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">text</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will delete the give text value from the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>DIV_SCROLLBAR</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll a div at any direction like, up, down, left, right, top, bottom, end, leftend, rightend.</p>
                  <p><b>Required</b>: Element Reference and Scroll direction </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">DIV_SCROLLBAR</TableCell>
                          <TableCell align="left">Then DIV_SCROLLBAR by &#123;up&#125; at [locator]</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll up for given div.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">DIV_SCROLLBAR</TableCell>
                          <TableCell align="left">Then DIV_SCROLLBAR by &#123;left&#125; at [locator]</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll left for given div.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> DOUBLE_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to double click at the given element location.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">DOUBLE_CLICK</TableCell>
                          <TableCell align="left">Then DOUBLE_CLICK [locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will double click at the given element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ELEMENT_IS_CLICKABLE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify that the element is clickable or not.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ELEMENT_IS_CLICKABLE</TableCell>
                          <TableCell align="left">Then ELEMENT_IS_CLICKABLE [locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will if the given element is clickable.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ELEMENT_IS_ENABLED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify that the element is enabled or not.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ELEMENT_IS_ENABLED</TableCell>
                          <TableCell align="left">Then ELEMENT_IS_ENABLED [locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will Pass if the given element will be enabled.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ELEMENT_IS_DISABLED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify that the element is disabled or not.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ELEMENT_IS_DISABLED</TableCell>
                          <TableCell align="left">Then ELEMENT_IS_DISABLED [locator] <br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will Pass if the given element will be disabled.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ENTER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to enter the given value at the reference element location.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ENTER</TableCell>
                          <TableCell align="left">Then ENTER [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">value</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will enter the given value at the element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ENTER_JS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to enter the given value in JS element location.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ENTER_JS</TableCell>
                          <TableCell align="left">Then ENTER_JS [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">value</TableCell>
                          <TableCell align="left">value</TableCell>
                          <TableCell align="left">It will enter the given value at the element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> ENTER_APPEND</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to enters given TestData into the field without clearing it. It will appends the value with existing text.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ENTER_APPEND</TableCell>
                          <TableCell align="left">Then ENTER_APPEND [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Sapient</TableCell>
                          <TableCell align="left">It will append the given 'Sapient' at the element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> ENTER_DATA_JS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to enters given TestData into the Java Script field.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ENTER_DATA_JS</TableCell>
                          <TableCell align="left">Then ENTER_DATA_JS [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Sapient</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will enter the given value ('Sapient') at the element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ENTER_KEY_PRESS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to send Enter key at the reference element location.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ENTER_KEY_PRESS</TableCell>
                          <TableCell align="left">Then ENTER_KEY_PRESS [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will send the Enter key-press at given element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>ENTER_TEXT_IN_TO_ALERT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to send Enter key into the Alert visible on the screen.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">ENTER_TEXT_IN_TO_ALERT</TableCell>
                          <TableCell align="left">Then ENTER_TEXT_IN_TO_ALERT [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Yes</TableCell>
                          <TableCell align="left">It will send the Enter given test data into the alert.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>EXECUTE_JAVA_SCRIPT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to execute the Java Script.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">EXECUTE_JAVA_SCRIPT</TableCell>
                          <TableCell align="left">Then EXECUTE_JAVA_SCRIPT [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left">JavaScript</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will execute the JS value given in the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> EXPLICIT_WAIT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action wait until the element is present on the DOM of a page, visible and also has a height and width that is greater than 0.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">EXPLICIT_WAIT</TableCell>
                          <TableCell align="left">Then EXPLICIT_WAIT [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will Waits unto 10 seconds or until the element visible.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> EXPLICIT_WAIT_JS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to explicitly wait for the JS element to be visible at the given element location.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">EXPLICIT_WAIT_JS</TableCell>
                          <TableCell align="left">Then EXPLICIT_WAIT_JS [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will wait for 10 seconds if JS element is not visible at the given element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>FLUENT_WAIT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to wait for the given  element to be visible and check it in every 2 seconds interval till the given value (seconds) reached.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">FLUENT_WAIT</TableCell>
                          <TableCell align="left">Then FLUENT_WAIT [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will wait for 10 seconds and check in every 2 seconds for given element to be visible.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>FRAME_DRAG_AND_DROP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to drag and drop the element from one frame to another frame. Current driver window should the srouce element frame, during this action the frame will be switched to target frame and then drop the dragged on the target element. Any action to be performed on the source window post this action requires explicit switch frame / window action to be called.</p>
                  <p><b>Required</b>: Element Reference, Test Data, Expected Result </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">FRAME_DRAG_AND_DROP</TableCell>
                          <TableCell align="left">Then FRAME_DRAG_AND_DROP [locator] +  &lt;TestData&gt; + &#123;Expected_Result&#125; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">\\div[@id='selfieImage']</TableCell>
                          <TableCell align="left">\\iframe[@id="uploadwindow"]</TableCell>
                          <TableCell align="left">Element is dragged from the source, active window switched to frame mentioned in the expected result and drop the dragged element to the target location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>GET_ATTRIBUTE_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to drag and drop the element from one frame to another frame. Current driver window should the srouce element frame, during this action the frame will be switched to target frame and then drop the dragged on the target element. Any action to be performed on the source window post this action requires explicit switch frame / window action to be called.</p>
                  <p><b>Required</b>: Element Reference, Test Data, Expected Result </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">GET_ATTRIBUTE_VALUE</TableCell>
                          <TableCell align="left">Then GET_ATTRIBUTE_VALUE [locator] +  &lt;TestData&gt; + &#123;Expected_Result&#125; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">\\div[@id='selfieImage']</TableCell>
                          <TableCell align="left">\\iframe[@id="uploadwindow"]</TableCell>
                          <TableCell align="left">Element is dragged from the source, active window switched to frame mentioned in the expected result and drop the dragged element to the target location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>GET_CURRENT_URL</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                              <p><b>Description</b>: This action is used to get the current URL</p>
                              <p><b>Required</b>: </p>
                              <p><b>Applicable To</b>: Desktop</p>
                              <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                <Table border="2" className={classes.table} aria-label="simple table">
                                  <TableHead bgcolor="green">
                                    <TableRow>
                                      <TableCell align="left">ACTION</TableCell>
                                      <TableCell align="left">SYNTAX</TableCell>
                                      <TableCell align="left">TEST DATA</TableCell>
                                      <TableCell align="left">EXPECTED RESULT</TableCell>
                                      <TableCell align="left">OUTCOME</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key='ac'>
                                      <TableCell component="th" scope="row">GET_CURRENT_URL</TableCell>
                                      <TableCell align="left">Then GET_CURRENT_URL </TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left">This will print the current URL.</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </AccordionDetails>
                        </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> HEIGHT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is compare the height of the element with the given test data.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">HEIGHT</TableCell>
                          <TableCell align="left">Then HEIGHT [locator]  + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">height_value</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will compare the given value (Height) with the actual height of the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>HIGHLIGHT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action will highlight the given element location.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">HIGHLIGHT</TableCell>
                          <TableCell align="left">Then HIGHLIGHT [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will highlight the given element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> HOVER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to hover the mouse at given reference element.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">HOVER</TableCell>
                          <TableCell align="left">Then HOVER [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will hover mouse cursor at the given element .</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> HOVER_JS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to hover the mouse at given reference element(Uses JavaScript).</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">HOVER_JS</TableCell>
                          <TableCell align="left">Then HOVER_JS [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will hover mouse cursor at the given element (Uses JavaScript).</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> HOVER_N_CLICK_SUB_ITEM</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action selects the value from the drown down, by moving the cursor on the element first then click on the link text. This is applicable when drop down or context menu or overlay with links displayed only on mouse over.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">HOVER_N_CLICK_SUB_ITEM</TableCell>
                          <TableCell align="left">Then HOVER_N_CLICK_SUB_ITEM [locator]  + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">value</TableCell>
                          <TableCell align="left">This action selects the value from the drown down, by moving the cursor on the element first then click on the link text. This is applicable when drop down or context menu or overlay with links displayed only on mouse over.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>IF_CONDITION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action validates If condition and skips remaining steps on failure, It supports options like lt, gt, eq, stringequals, stringequalsignorecase, stringcontains</p>
                  <p><b>Required</b>: Element Reference, Option, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">OPTIONS</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">IF_CONDITION</TableCell>
                          <TableCell align="left">Then IF_CONDITION checks the &lt;ExpectedResult&gt; With &#123;lt&#125; on [locator]</TableCell>
                          <TableCell align="left">"lessthan"<br></br></TableCell>
                          <TableCell align="left">"perform less than conditional check for given two number"</TableCell>
                          <TableCell align="left">If an expected-value is less than actual-value, then it will Continue Else it will skip the remaining scenarios.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">IF_CONDITION</TableCell>
                          <TableCell align="left">Then IF_CONDITION checks the &lt;ExpectedResult&gt; With &#123;stringequals&#125; on [locator]</TableCell>
                          <TableCell align="left">"stringequals"<br></br></TableCell>
                          <TableCell align="left">"perform equals operation for given two strings"</TableCell>
                          <TableCell align="left">If an expected-value is equal to actual-value, then it will Continue Else it will skip the remaining scenarios.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>JWT_DECODER_FUNCTION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action Decodes the JSON web token.</p>
                  <p><b>Required</b>: Element Reference, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">JWT_DECODER_FUNCTION</TableCell>
                          <TableCell align="left">Then JWT_DECODER_FUNCTION</TableCell>
                          <TableCell align="left">json-web-token</TableCell>
                          <TableCell align="left">result</TableCell>
                          <TableCell align="left">Decodes JWT and returns a decoded String which is stored in string.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MATH_OPERATIONS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: To Perform Math operations on variables</p>
                  <p><b>Required</b>:Variable Name, Query</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MATH_OPERATIONS</TableCell>
                          <TableCell align="left">Then MATH_OPERATIONS + &lt;VariableName&gt; with query &#123;Query&#125;</TableCell>
                          <TableCell align="left">"sum":"sum;1,2,3,4"</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Perform the given math operation.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to Click on the mobile element. For links in IOS, that open in different tab/window pass 'X;Y' co-ordinates in Test Data.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_CLICK</TableCell>
                          <TableCell align="left">Then MOBILE_CLICK [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will click on the given element reference.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_CLICK</TableCell>
                          <TableCell align="left">Then MOBILE_CLICK [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">125;432</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">In iOS mobile, point 125;432 is clicked, which is expected to open in new tab/window.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_CLICK_BY_LINK_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to Click on the mobile link whose innerText is matching with the test data.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_CLICK_BY_LINK_TEXT</TableCell>
                          <TableCell align="left">Then MOBILE_CLICK_BY_LINK_TEXT + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Checkout</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will click on the link whose innerText is 'Checkout'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_CLICK_ELEMENT_BY_INDEX</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to click on the element matching with the given index value.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_CLICK_ELEMENT_BY_INDEX</TableCell>
                          <TableCell align="left">Then MOBILE_CLICK_ELEMENT_BY_INDEX [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">2</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">If the Locator matches more than one element then it will click on the index [2].</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_CLICK_N_CLICK_LINK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to Select the value from the dropdown, by clicking on the dropdown first then click on the link text in mobile.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_CLICK_N_CLICK_LINK</TableCell>
                          <TableCell align="left">Then MOBILE_CLICK_N_CLICK_LINK [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Publicis Sapient</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will click on the dropdown then will click on the link text 'Publicis Sapient' of the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_CLOSE_APP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to close the current opened application in the device.</p>
                  <p><b>Required</b>: Action Name </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_CLOSE_APP</TableCell>
                          <TableCell align="left">Then MOBILE_CLOSE_APP</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This wil close the currently opened application in the device.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_ENTER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to enter the values at the given element referece location.</p>
                  <p><b>Required</b>: Action Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_ENTER</TableCell>
                          <TableCell align="left">Then MOBILE_ENTER [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Sapient</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will send the value 'Sapient' at the given element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_ENTER_DATA_BY_KEYPAD</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to enter the given Argument data into the field through device native keyboard.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_ENTER_DATA_BY_KEYPAD</TableCell>
                          <TableCell align="left">Then MOBILE_ENTER_DATA_BY_KEYPAD [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Publicis Sapient</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will send 'Publicis Sapient' to the target element using mobile Keypad.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_ENTER_PRESS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to send the ENTER key to the current element from keypad.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_ENTER_PRESS</TableCell>
                          <TableCell align="left">Then MOBILE_ENTER_PRESS [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will send the Enter key from the device keypad at referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_LONG_PRESS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to long press at the referenced element location.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_LONG_PRESS</TableCell>
                          <TableCell align="left">Then MOBILE_LONG_PRESS [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will long press at the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_QUIT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to Quit the current mobile app or browser in the device.</p>
                  <p><b>Required</b>: Action Name </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_QUIT</TableCell>
                          <TableCell align="left">Then MOBILE_QUIT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will Quit the currently opened mobile application or browser in the device.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_SELECT_CHECKBOX</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to select the check box if it is not selected.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_SELECT_CHECKBOX</TableCell>
                          <TableCell align="left">Then MOBILE_SELECT_CHECKBOX [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will select the check box if it is not selected.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_SELECT_ELEMENT_BY_LINK_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to select the item from the dropdown based on the LinkText passed in the test data.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="center">Action </TableCell>
                          <TableCell align="center">Syntax</TableCell>
                          <TableCell align="center">Argument</TableCell>
                          <TableCell align="center">Outcome</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_SELECT_ELEMENT_BY_LINK_TEXT</TableCell>
                          <TableCell align="left">Then MOBILE_SELECT_ELEMENT_BY_LINK_TEXT [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left">Mumbai</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will select the value based on the LinkText (Mumbai) given in the Argument.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_SELECT_ELEMENT_BY_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to select the item from the dropdown based on the item value passed in the test data.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_SELECT_ELEMENT_BY_VALUE</TableCell>
                          <TableCell align="left">Then MOBILE_SELECT_ELEMENT_BY_VALUE [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Delhi</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will select the value (Delhi) based on the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_SELECT_ELEMENTS_BY_INDEX</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to select the item from the dropdown based on the index value passed in the test data.</p>
                  <p><b>Required</b>: Element Reference, Argument </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_SELECT_ELEMENTS_BY_INDEX</TableCell>
                          <TableCell align="left">Then MOBILE_SELECT_ELEMENTS_BY_INDEX [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">2</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will select the value from the dropdown based on the index value given in the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_SET_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to set the value at the given element location.</p>
                  <p><b>Required</b>: Element Reference, Argument </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_SET_VALUE</TableCell>
                          <TableCell align="left">Then MOBILE_SET_VALUE [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">value</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will set the value at the element location based on the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_SWITCH_CONTEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to switch the mobile context of hybrid application between webview and nativeapp. Valid test data must be passed, either nativeapp or mobileview</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_SWITCH_CONTEXT</TableCell>
                          <TableCell align="left">Then MOBILE_SWITCH_CONTEXT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">mobileview or nativeapp</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will switch to mobileview or nativeapp based on the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_TAP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to tap at the specified element location.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_TAP</TableCell>
                          <TableCell align="left">Then MOBILE_TAP [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will tap at the referenced element location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_UNSELECT_CHECKBOX</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to unselect the check box if it is selected.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_UNSELECT_CHECKBOX</TableCell>
                          <TableCell align="left">Then MOBILE_UNSELECT_CHECKBOX [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will unselect the checkbox if it is selected.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_VERIFY_LINK_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify if the specified link exist on the mobile page.</p>
                  <p><b>Required</b>: Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_VERIFY_LINK_EXIST</TableCell>
                          <TableCell align="left">Then MOBILE_VERIFY_LINK_EXIST &#123;ExpectedResult&#125; <br></br>* Where &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Welcome</TableCell>
                          <TableCell align="left">It will PASS if the current page contains the text 'Welcome'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_VERIFY_PART_TEXT_PRESENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify the element text against the expected result.</p>
                  <p><b>Required</b>: Element Reference, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_VERIFY_PART_TEXT_PRESENT</TableCell>
                          <TableCell align="left">Then MOBILE_VERIFY_PART_TEXT_PRESENT [Locator] +  &#123;ExpectedResult&#125; <br></br>* Where locator should be described in UI Testing section and &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Welcome Back</TableCell>
                          <TableCell align="left">It will PASS if the partial text of the referenced element is 'Welcome Back'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_VERIFY_PARTIAL_LINK_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify whether the link text contains a specific text in the mobile page/app.</p>
                  <p><b>Required</b>: Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_VERIFY_PARTIAL_LINK_EXIST</TableCell>
                          <TableCell align="left">Then MOBILE_VERIFY_PARTIAL_LINK_EXIST &#123;ExpectedResult&#125; <br></br>* Where &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Publicis</TableCell>
                          <TableCell align="left">This action will verify if the link text contains 'Publicis' in the mobile page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_VERIFY_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify the element text against the expected result by matching exact case.</p>
                  <p><b>Required</b>: Element Reference, Expected Result</p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_VERIFY_TEXT</TableCell>
                          <TableCell align="left">Then MOBILE_VERIFY_TEXT [Locator] + &#123;ExpectedResult&#125; <br></br>* Where locator should be described in locators page and &#123;ExpectedResult&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Welcome</TableCell>
                          <TableCell align="left">This step will PASS if the text of the ObjectRef is Welcome' but not 'WELCOME'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_VERIFY_TEXT_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to check whether text exist on anywhere on mobile app : text as expected result.</p>
                  <p><b>Required</b>: Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_VERIFY_TEXT_EXIST</TableCell>
                          <TableCell align="left">Then MOBILE_VERIFY_TEXT_EXIST + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">Publicis Sapient</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will verify if 'Publicis Sapient' text is available on the current page or not.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_VERIFY_TEXT_IGNORE_CASE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to verify the referenced element text against the expected result ignoring case.</p>
                  <p><b>Required</b>: Element Reference, Expected Result </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_VERIFY_TEXT_IGNORE_CASE</TableCell>
                          <TableCell align="left">Then MOBILE_VERIFY_TEXT_IGNORE_CASE [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Sapient</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This step will PASS if the text of the referenced element is 'Sapient'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>MOBILE_WAIT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to put the wait during the execution.</p>
                  <p><b>Required</b>: Argument </p>
                  <p><b>Applicable To</b>: Mobile </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">MOBILE_WAIT</TableCell>
                          <TableCell align="left">Then MOBILE_WAIT + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will sleep the execution for 10 seconds.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>NAVIGATE_BACK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to navigate back in the browser.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">NAVIGATE_BACK</TableCell>
                          <TableCell align="left">Then NAVIGATE_BACK</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will navigate back to the last page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>NAVIGATE_FORWARD</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to navigate forward in the browser.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">NAVIGATE_FORWARD</TableCell>
                          <TableCell align="left">Then NAVIGATE_FORWARD</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will navigate forward.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>NAVIGATE_TO</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action allows user to change the URL of the page existing active driver browser without clearing the cookies or creating new instance.</p>
                  <p><b>Required</b>: Test Data</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">NAVIGATE_TO</TableCell>
                          <TableCell align="left">Then NAVIGATE_TO</TableCell>
                          <TableCell align="left">totalwine.com</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Browser is navigated to totalwine.com without opening a new instance of the browser or clearing the cookies.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> NEW_TAB</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to open a new tab in the browser.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">NEWTAB</TableCell>
                          <TableCell align="left">Then NEWTAB </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will open a new tab in the browser.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> OPEN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to open a URL in the browser.</p>
                  <p><b>Required</b>: Object Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">OPEN</TableCell>
                          <TableCell align="left">Then OPEN [url] <br></br>* Where URL is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will open the given url in browser.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> PAGE_REFRESH</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is refresh the current webpage.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">PAGE_REFRESH</TableCell>
                          <TableCell align="left">Then PAGE_REFRESH  </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will refresh the current webpage.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>PAUSE_IN_SEC</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to pause or sleep the execution for the given time seconds.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">PAUSE_INSEC</TableCell>
                          <TableCell align="left">Then PAUSE_INSEC + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will pause or sleep the execution for 10 seconds.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>RIGHT_MOUSE_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action performs right click on the element.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">RIGHT_MOUSE_CLICK</TableCell>
                          <TableCell align="left">Then RIGHT_MOUSE_CLICK [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Performs right click on the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SCROLL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll the webpage "UP", "DOWN", "TOP", "BOTTOM".</p>
                  <p><b>Required</b>: Test Data ("UP", "DOWN", "TOP", "BOTTOM")</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL</TableCell>
                          <TableCell align="left">Then SCROLL + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">"UP", "DOWN", "TOP", "BOTTOM"</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll the current page to the bottom.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SCROLL_BOTTOM</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll the webpage to the bottom.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL_BOTTOM</TableCell>
                          <TableCell align="left">Then SCROLL_BOTTOM </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll the current page to the bottom.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SCROLL_DOWN</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll the webpage till the given element reference.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL_DOWN</TableCell>
                          <TableCell align="left">Then SCROLL_DOWN </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll the current page till the given element reference.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SCROLL_FIND</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll and find the given element reference.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL_FIND</TableCell>
                          <TableCell align="left">Then SCROLL_FIND [Locator] <br></br>* Where Locator is an xpath described in Object Repository. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll the current page and find the element reference and scroll till that.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SCROLL_INTO_VIEW </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action Scrolls to the particular element.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>\
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL_INTO_VIEW</TableCell>
                          <TableCell align="left">Then SCROLL_INTO_VIEW [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll to the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SCROLL_TOP </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll the current page to the top.</p>
                  <p><b>Required</b>:</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL_TOP</TableCell>
                          <TableCell align="left">Then SCROLL_TOP</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll the current page to the top.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SCROLL_UP </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to scroll the current page upside till the given element reference found.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SCROLL_UP</TableCell>
                          <TableCell align="left">Then SCROLL_UP</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will scroll up the current page till the given element reference.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SEE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to check the given element is present or not.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SEE</TableCell>
                          <TableCell align="left">Then SEE [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will check the element is displayed on the webpage.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SELECT_CHECKBOX </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action select the check box if already not selected.</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SELECT_CHECKBOX</TableCell>
                          <TableCell align="left">Then SELECT_CHECKBOX [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Select the check box if already not selected</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SELECT </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to select a given referenced value from a list of elements.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SELECT</TableCell>
                          <TableCell align="left">Then SELECT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">google</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will select the data_value from the element reference (list).</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SELECT_ELEMENT_BY_INDEX </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action select the item from the dropdown based on the item index passed in the TestData.</p>
                  <p><b>Required</b>: Element Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SELECT_ELEMENT_BY_INDEX</TableCell>
                          <TableCell align="left">Then SELECT_ELEMENT_BY_INDEX [Index_value] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">2</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will select item based on index value given in test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SELECT_ELEMENT_BY_TEXT </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action select the item from the dropdown based on the item text passed in the TestData.</p>
                  <p><b>Required</b>: Element Reference, Test Data  </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SELECT_ELEMENT_BY_TEXT</TableCell>
                          <TableCell align="left">Then SELECT_ELEMENT_BY_TEXT [Locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Alabama</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will select based on the text value given in test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SELECT_ELEMENT_BY_VALUE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action select the item from the dropdown based on the item value passed in the TestData.</p>
                  <p><b>Required</b>: Element Reference, Test Data  </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SELECT_ELEMENT_BY_VALUE</TableCell>
                          <TableCell align="left">Then SELECT_ELEMENT_BY_VALUE [Value] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">AL</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will select based on the value passed in test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SELECT_NODE_FROM_TREE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to select a specific node (value) from a Tree structure.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SELECT_NODE_FROM_TREE</TableCell>
                          <TableCell align="left">Then SELECT_NODE_FROM_TREE [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">google</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will select the google value from the element reference (tree).</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SEND_TAB </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:-This action send TAB key to the element. This method is not supported in chrome browser. (For chrome use SEND_TAB_ACTION).</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SEND_TAB</TableCell>
                          <TableCell align="left">Then SEND_TAB [locator] Where [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"> </TableCell>
                          <TableCell align="left"> </TableCell>
                          <TableCell align="left"> It sends TAB key to the element.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SET_COOKIES</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                              <p><b>Description</b>: This action set the variable value to a variable named present in options which can be used in later steps</p>
                              <p><b>Required</b>: Test Data </p>
                              <p><b>Example</b>: And User SET_COOKIES in &lt;document.cookie&gt; </p>
                               <p><b>Example in environment Settings</b>"document.cookie":"ac_demoCookie=gMSN0LjM; expires=Thu, 31 Dec 2024 12:00:00 UTC; path=/" </p>
                              <p><b>Applicable To</b>: Desktop </p>
                              <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                <Table border="2" className={classes.table} aria-label="simple table">
                                  <TableHead bgcolor="green">
                                    <TableRow>
                                      <TableCell align="left">ACTION</TableCell>
                                      <TableCell align="left">SYNTAX</TableCell>
                                      <TableCell align="left">TEST DATA</TableCell>
                                      <TableCell align="left">EXPECTED RESULT</TableCell>
                                      <TableCell align="left">OUTCOME</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key='ac'>
                                      <TableCell component="th" scope="row">SET_COOKIES</TableCell>
                                      <TableCell align="left">Then SET_COOKIES  &lt;TestData&gt; <br></br>*where &lt;TestData&gt; should be declared in Environment Settings.</TableCell>
                                      <TableCell align="left">Cookie value</TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left">This action will set cookies to your browser</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </AccordionDetails>
                        </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SET_VARIABLE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action set the variable value to a variable named present in options which can be used in later steps</p>
                  <p><b>Required</b>: Option and Test Data </p>
                  <p><b>Applicable To</b>: Desktop </p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SET_VARIABLE</TableCell>
                          <TableCell align="left">Then SET_VARIABLE &#123;Option&#125; + &lt;TestData&gt; <br></br>* Where Option should be written in curly braces which will be variable name and &lt;TestData&gt; should be declared in UI Testing section of Environment or in Example section.</TableCell>
                          <TableCell align="left">Variable value</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will set the variable value and can be used in later steps</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SEND_TAB_ACTION </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:-This action will insert a new TAB in the current browser. ObjectRef is opened in the new tab, this can be overridden by passing the TestData.</p>
                  <p><b>Required</b>: Element Reference & Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key='ac'>
                                      <TableCell component="th" scope="row">SEND_TAB_ACTION</TableCell>
                                      <TableCell align="left">Then SEND_TAB_ACTION [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                                      <TableCell align="left">URL</TableCell>
                                      <TableCell align="left"> </TableCell>
                                      <TableCell align="left"> It will open the given URL in new tab</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </AccordionDetails>
                        </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action clicks on any element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Options</b>: right left above center below </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_CLICK</TableCell>
                          <TableCell align="left">And User SK_CLICK on [image name and extention] </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action clicks on element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_CLICK</TableCell>
                          <TableCell align="left">And User SK_CLICK on [image name and extention] with &#123;left&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">left</TableCell>
                          <TableCell align="left">This action clicks on left of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_CLICK</TableCell>
                          <TableCell align="left">And User SK_CLICK on [image name and extention] with &#123;right;20,30&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">right;20,30</TableCell>
                          <TableCell align="left">This action clicks on right by 20,30 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_CLICK</TableCell>
                          <TableCell align="left">And User SK_CLICK on [image name and extention] with &#123;below;120,-40&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">below;120,-40</TableCell>
                          <TableCell align="left">This action clicks on below by 120,-40 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_CLICK</TableCell>
                          <TableCell align="left">And User SK_CLICK on [image name and extention] with &#123;245,89&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">245,89</TableCell>
                          <TableCell align="left">This will click on rectangular region having top left corner pixel with X co-ordinate value increased by 245 pixels i.e X+245 and Y co-ordinate value increased by 89 pixels i.e Y+89 of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_CLICK</TableCell>
                          <TableCell align="left">And User SK_CLICK on [image name and extention] with &#123;50%,50%&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">50%,50%</TableCell>
                          <TableCell align="left">This will click on rectangular region having top left corner pixel with X co-ordinate value increased by 50% and Y co-ordinate value increased by 50% of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_DOUBLE_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action double clicks on any element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Options</b>: right left above center below </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DOUBLE_CLICK</TableCell>
                          <TableCell align="left">And User SK_DOUBLE_CLICK on [image name and extention] </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action double clicks on element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DOUBLE_CLICK</TableCell>
                          <TableCell align="left">And User SK_DOUBLE_CLICK on [image name and extention] with &#123;left&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">left</TableCell>
                          <TableCell align="left">This action double clicks on left of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DOUBLE_CLICK</TableCell>
                          <TableCell align="left">And User SK_DOUBLE_CLICK on [image name and extention] with &#123;right;20,30&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">right;20,30</TableCell>
                          <TableCell align="left">This action double clicks on right by 20,30 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DOUBLE_CLICK</TableCell>
                          <TableCell align="left">And User SK_DOUBLE_CLICK on [image name and extention] with &#123;below;120,-40&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">below;120,-40</TableCell>
                          <TableCell align="left">This action double clicks on below by 120,-40 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DOUBLE_CLICK</TableCell>
                          <TableCell align="left">And User SK_DOUBLE_CLICK on [image name and extention] with &#123;245,89&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">245,89</TableCell>
                          <TableCell align="left">This will double click on rectangular region having top left corner pixel with X co-ordinate value increased by 245 pixels i.e X+245 and Y co-ordinate value increased by 89 pixels i.e Y+89 of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DOUBLE_CLICK</TableCell>
                          <TableCell align="left">And User SK_DOUBLE_CLICK on [image name and extention] with &#123;50%,50%&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">50%,50%</TableCell>
                          <TableCell align="left">This will double click on rectangular region having top left corner pixel with X co-ordinate value increased by 50% and Y co-ordinate value increased by 50% of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_RIGHT_CLICK</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action right clicks on any element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Options</b>: right left above center below </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_RIGHT_CLICK</TableCell>
                          <TableCell align="left">And User SK_RIGHT_CLICK on [image name and extention] </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action right clicks on element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_RIGHT_CLICK</TableCell>
                          <TableCell align="left">And User SK_RIGHT_CLICK on [image name and extention] with &#123;left&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">left</TableCell>
                          <TableCell align="left">This action right clicks on left of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_RIGHT_CLICK</TableCell>
                          <TableCell align="left">And User SK_RIGHT_CLICK on [image name and extention] with &#123;right;20,30&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">right;20,30</TableCell>
                          <TableCell align="left">This action right clicks on right by 20,30 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_RIGHT_CLICK</TableCell>
                          <TableCell align="left">And User SK_RIGHT_CLICK on [image name and extention] with &#123;below;120,-40&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">below;120,-40</TableCell>
                          <TableCell align="left">This action right clicks on below by 120,-40 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_RIGHT_CLICK</TableCell>
                          <TableCell align="left">And User SK_RIGHT_CLICK on [image name and extention] with &#123;245,89&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">245,89</TableCell>
                          <TableCell align="left">This will right click on rectangular region having top left corner pixel with X co-ordinate value increased by 245 pixels i.e X+245 and Y co-ordinate value increased by 89 pixels i.e Y+89 of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_RIGHT_CLICK</TableCell>
                          <TableCell align="left">And User SK_RIGHT_CLICK on [image name and extention] with &#123;50%,50%&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">50%,50%</TableCell>
                          <TableCell align="left">This will right click on rectangular region having top left corner pixel with X co-ordinate value increased by 50% and Y co-ordinate value increased by 50% of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_HOVER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action hover on any element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Options</b>: right left above center below </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_HOVER</TableCell>
                          <TableCell align="left">And User SK_HOVER on [image name and extention] </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action hovers on element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_HOVER</TableCell>
                          <TableCell align="left">And User SK_HOVER on [image name and extention] with &#123;left&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">left</TableCell>
                          <TableCell align="left">This action hovers on left of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_HOVER</TableCell>
                          <TableCell align="left">And User SK_HOVER on [image name and extention] with &#123;right;20,30&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">right;20,30</TableCell>
                          <TableCell align="left">This action hovers on right by 20,30 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_HOVER</TableCell>
                          <TableCell align="left">And User SK_HOVER on [image name and extention] with &#123;below;120,-40&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">below;120,-40</TableCell>
                          <TableCell align="left">This action hovers on below by 120,-40 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_HOVER</TableCell>
                          <TableCell align="left">And User SK_HOVER on [image name and extention] with &#123;245,89&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">245,89</TableCell>
                          <TableCell align="left">This will hover on rectangular region having top left corner pixel with X co-ordinate value increased by 245 pixels i.e X+245 and Y co-ordinate value increased by 89 pixels i.e Y+89 of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_HOVER</TableCell>
                          <TableCell align="left">And User SK_HOVER on [image name and extention] with &#123;50%,50%&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">50%,50%</TableCell>
                          <TableCell align="left">This will hover on rectangular region having top left corner pixel with X co-ordinate value increased by 50% and Y co-ordinate value increased by 50% of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_ENTER</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action enters on any element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension, Test Data</p>
                  <p><b>Options</b>: right left above center below </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_ENTER</TableCell>
                          <TableCell align="left">And User SK_ENTER on [image name and extention] with &lt;Test Data&gt; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action enter on element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_ENTER</TableCell>
                          <TableCell align="left">And User SK_ENTER on [image name and extention] with &lt;Test Data&gt; at &#123;left&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">left</TableCell>
                          <TableCell align="left">This action enter on left of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_ENTER</TableCell>
                          <TableCell align="left">And User SK_ENTER on [image name and extention] with &lt;Test Data&gt; at &#123;right;20,30&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">right;20,30</TableCell>
                          <TableCell align="left">This action enter on right by 20,30 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_ENTER</TableCell>
                          <TableCell align="left">And User SK_ENTER on [image name and extention] with &lt;Test Data&gt; at &#123;below;120,-40&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">below;120,-40</TableCell>
                          <TableCell align="left">This action enter on below by 120,-40 pixel of the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_ENTER</TableCell>
                          <TableCell align="left">And User SK_ENTER on [image name and extention] with &lt;Test Data&gt; at &#123;245,89&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">245,89</TableCell>
                          <TableCell align="left">This will enter on rectangular region having top left corner pixel with X co-ordinate value increased by 245 pixels i.e X+245 and Y co-ordinate value increased by 89 pixels i.e Y+89 of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_ENTER</TableCell>
                          <TableCell align="left">And User SK_ENTER on [image name and extention] with &lt;Test Data&gt; at &#123;50%,50%&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">50%,50%</TableCell>
                          <TableCell align="left">This will enter on rectangular region having top left corner pixel with X co-ordinate value increased by 50% and Y co-ordinate value increased by 50% of top left corner pixel of the region pointed by the element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_KEY_PRESS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will press the key on any element which is present on the screen. For keycodes refer http://doc.sikuli.org/keys.html</p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_KEY_PRESS</TableCell>
                          <TableCell align="left">And User SK_KEY_PRESS on [image name and extention] with &lt;Key Value&gt; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">TAB</TableCell>
                          <TableCell align="left">This action will press TAB key on element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_KEY_RELEASE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will release pressed key on the screen. </p>
                  <p><b>Required</b> </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_KEY_RELEASE</TableCell>
                          <TableCell align="left">And User SK_KEY_RELEASE </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will release pressed key on the screen. </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_GET_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to fetch the text of the element which is present on the screen and store in a variable. </p>
                  <p><b>Required</b>: Image Name with extension , Variable Name</p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_GET_TEXT</TableCell>
                          <TableCell align="left">And User SK_GET_TEXT of [image name and extention] in &lt;VariableName&gt;</TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">Variable Name</TableCell>
                          <TableCell align="left">This action is used to fetch the text of the element which is present on the screen and store it in given variable. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_DRAG_AND_DROP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will drag and drop to a different element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_DRAG_AND_DROP</TableCell>
                          <TableCell align="left">And User SK_DRAG_AND_DROP on [source image name and extention] and &lt;Targer Image name and extension&gt; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will drag and drop to a different element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_SCROLL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will scroll up/down on any element which is present on the screen. </p>
                  <p><b>Required</b>: Image Name with extension , Scroll Steps </p>
                  <p><b>Options</b>: up down </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OPTION</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_SCROLL</TableCell>
                          <TableCell align="left">And User SK_SCROLL on [image name and extention] at &#123;up&#125; </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">up</TableCell>
                          <TableCell align="left">This action will scroll up 3 times on element which is present on the screen. </TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_SCROLL</TableCell>
                          <TableCell align="left">And User SK_SCROLL on [image name and extention] at &#123;down&#125; with &lt;5&gt;</TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">down</TableCell>
                          <TableCell align="left">This action will scroll down 5 times on the element which is present on the screen. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_VERIFY_ELEMENT_EXISTS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action will verify presence of any element on the screen. </p>
                  <p><b>Required</b>: Image Name with extension </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_VERIFY_ELEMENT_EXISTS</TableCell>
                          <TableCell align="left">And User SK_VERIFY_ELEMENT_EXISTS on [image name and extention] </TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will verify presence of any element on the screen.  </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_VERIFY_TEXT_CONTAINS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verify if text is present on the element on the screen. </p>
                  <p><b>Required</b>: Image Name with extension , Test Data </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_VERIFY_TEXT_CONTAINS</TableCell>
                          <TableCell align="left">And User SK_VERIFY_TEXT_CONTAINS on [image name and extention] with &lt;Test Data&gt;</TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">CATS</TableCell>
                          <TableCell align="left">This action will verify if "CATS" is present on element. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SK_VERIFY_TEXT_EQUALS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action verify if text is equal to the element on the screen.</p>
                  <p><b>Required</b>: Image Name with extension, Test Data </p>
                  <p><b>Options</b>: right left above center below </p>
                  <p><b>Applicable To</b>: Windows </p>
                 
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">LOCATOR</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SK_VERIFY_TEXT_EQUALS</TableCell>
                          <TableCell align="left">And User SK_VERIFY_TEXT_EQUALS on [image name and extention] with &lt;Test Data&gt;</TableCell>
                          <TableCell align="left">Image name with extention</TableCell>
                          <TableCell align="left">LITE</TableCell>
                          <TableCell align="left">This action will verify if "LITE" is equal to the text on element. </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SWITCH_IFRAME</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to switch iframe based on the Element reference.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SWITCH_IFRAME</TableCell>
                          <TableCell align="left">Then SWITCH_IFRAME [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will switch to the iframe.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SWITCH_IFRAME_LOCATOR </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action switches the Frame using Object Reference element properties. If the Frame switch is successful all the actions performed beyond this step will be on the newly switched frame.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SWITCH_IFRAME_LOCATOR</TableCell>
                          <TableCell align="left">Then SWITCH_IFRAME_LOCATOR [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will switch to the iframe based on the xpath of the iframe.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>SWITCH_TO_DEFAULT_CONTENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This method is used to switch the focus back to the default content of the web page after interacting with elements inside an iframe.</p>
                  <p><b>Required</b>: NA</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SWITCH_TO_DEFAULT_CONTENT</TableCell>
                          <TableCell align="left">Then SWITCH_TO_DEFAULT_CONTENT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This will switch the focus back to the default content of the web page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> SWITCH_WINDOW </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action switches the window using tab tile.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">SWITCH_WINDOW</TableCell>
                          <TableCell align="left">Then SWITCH_WINDOW + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">Google</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will switch to the window based on the tab tile given in the test data.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>TAB_KEY_PRESS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to send Enter key at the reference element location.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop, Mobile, WINDOWS</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">TAB_KEY_PRESS</TableCell>
                          <TableCell align="left">Then TAB_KEY_PRESS [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will send the Enter key-press at given element reference location.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>


            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> UI_DISTANCE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the distance between ObjectRef and the element matching xpath specified in the TestData. Distance is verified against Expected Result specified. That     can be in x or y coordinates.</p>
                  <p><b>Required</b>: Element Reference, Test Data, Expected Result </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">UI_DISTANCE</TableCell>
                          <TableCell align="left">Then UI_DISTANCE  [Locator] + &lt;TestData&gt; + &#123;Expected_Result&#125;<br></br>* Where locator should be described in locators page and &lt;TestData&gt; and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">30</TableCell>
                          <TableCell align="left"> This step will PASS if the distance between these two elements is 30px</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> UNSELECT </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to unselect the give value from the referenced element location.</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">UNSELECT</TableCell>
                          <TableCell align="left">Then UNSELECT [locator]+ + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">google</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will unselect the given value from the list.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> UNSELECT_CHECKBOX </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action unselect the check box if already selected.</p>
                  <p><b>Required</b>: Element Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">UNSELECT_CHECKBOX</TableCell>
                          <TableCell align="left">Then UNSELECT_CHECKBOX [locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will unselect the checkbox as identified by the object reference.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> VALIDATE_TITLE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to validate the tab title against the given test data.</p>
                  <p><b>Required</b>: Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VALIDATE_TITLE</TableCell>
                          <TableCell align="left">Then VALIDATE_TITLE + &lt;TestData&gt; <br></br>* Where &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">google</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will validate the title given in the test data with the actual title.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used verify that the given referenced data is diplayed at the referenced element location.</p>
                  <p><b>Required</b>: Element Reference & Data Reference </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY</TableCell>
                          <TableCell align="left">Then VERIFY [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">google</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will pass if the data is displayed at the given element reference.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ALERT_PRESENT </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used verify that Alert is available at the referenced element location.</p>
                  <p><b>Required</b></p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY</TableCell>
                          <TableCell align="left">Then VERIFY_ALERT_PRESENT</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will pass if the Alert is present.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ATTRIBUTE_VALUE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the Attribute value of the element is correct or not.</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">OPTIONS</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                        <TableCell component="th" scope="row">VERIFY_ATTRIBUTE_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ATTRIBUTE_VALUE on [locator] for &#123;options&#125; with value &lt;test data&gt; <br></br>* Where Locator is an xpath described in Object Repository, option is element attribute name and test data is element attribute value</TableCell>
                          <TableCell align="left">placeholder</TableCell>
                          <TableCell align="left">Value of place holder</TableCell>
                          <TableCell align="left">It will pass if Attribute value for the corresponding Attribute Name is correct at a particular locator</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
    <Accordion style={{ backgroundColor: '#eee' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_CURRENT_URL</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                              <p><b>Description</b>: This action is used to verify the current URL</p>
                              <p><b>Required</b>: Test Data </p>
                              <p><b>Applicable To</b>: Desktop</p>
                              <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                <Table border="2" className={classes.table} aria-label="simple table">
                                  <TableHead bgcolor="green">
                                    <TableRow>
                                      <TableCell align="left">ACTION</TableCell>
                                      <TableCell align="left">SYNTAX</TableCell>
                                      <TableCell align="left">TEST DATA</TableCell>
                                      <TableCell align="left">EXPECTED RESULT</TableCell>
                                      <TableCell align="left">OUTCOME</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key='ac'>
                                      <TableCell component="th" scope="row">VERIFY_CURRENT_URL</TableCell>
                                      <TableCell align="left">Then VERIFY_CURRENT_URL </TableCell>
                                      <TableCell align="left">URL value To Compare with the Current URL</TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left">This will verify the current URL with the expected URL given in the test data.</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </AccordionDetails>
                        </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_CSS_VALUE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies elements CSS value</p>
                  <p><b>Required</b>: Element Reference, Data Reference & Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_CSS_VALUE [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">value</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It passes if CSS value of element matches with expected value.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENTS_ATTRIBUTE_VALUE </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the Attribute value of the element against the Expected Result. This comparison is non-case sensitive. Elements attribute like value, class, length, innerHTML, innerText, onclick, type, height, width, etc., to be specified in the TestData (any valid element attributes).</p>
                  <p><b>Required</b> Element Ref, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_ATTRIBUTE_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_ATTRIBUTE_VALUE [Locator] <br></br>* Where Locator is an xpath described in Object Repository.</TableCell>
                          <TableCell align="left">innerHTML</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will pass if Attribute value is same as expected result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENTS_COUNT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verified the number of elements matching the ObjectRef property against Expected Result.</p>
                  <p><b>Required</b> Element Ref, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_COUNT</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_COUNT [Locator] &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">3</TableCell>
                          <TableCell align="left">If ObjectRef has "xpath=//img" then it will compare if there are three imgs are available. If yes it will PASS.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENTS_CSS_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:-  This action finds all the elements matching the ObjectRef and verifies the style mentioned in test data and its value given in the expected result. More than one CSSkey:CSSvalue pairs seperated by ";" can be passed in Expected Result for multiple verifications.
                    Any CSS property measured through pixel value can have the different pixel variations. Refer creative distance measure attribute from References section.</p>
                  <p><b>Required</b> Element Ref, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_CSS_VALUE [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator described in locators page </TableCell>
                          <TableCell align="left">fontSize</TableCell>
                          <TableCell align="left">14px</TableCell>
                          <TableCell align="left">This action will pass if all the elements matching ObjectRef and font-size of all the matching element is 14px.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_CSS_VALUE [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">margin-left</TableCell>
                          <TableCell align="left">~5px</TableCell>
                          <TableCell align="left">This action will pass if left margin of each element in the ObjectRef collection is 5px with acceptable deviation.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_CSS_VALUE [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">height</TableCell>
                          <TableCell align="left">5-10px</TableCell>
                          <TableCell align="left">This action will pass if the height of each element in the ObjectRef is between 5 and 10 pixels.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_CSS_VALUE [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">padding</TableCell>
                          <TableCell align="left">20px~3</TableCell>
                          <TableCell align="left">This action will pass if padding of each element in the ObjectRef is 20px with 3px deviation.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_CSS_VALUE [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">color:rgba(51, 51, 51, 1); font-size:36px	</TableCell>
                          <TableCell align="left">This action will pass if color is rgba(51, 51, 51, 1) and font-size is 36px.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENTS_MATH_OPERATION</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action performs math operation on the elements matching the ObjectRef property and verifies the out come of the operation against the expected result.</p>
                  <p><b>Valid Operations</b> SUM, SUBTRACT, DIVIDE, MULTIPLY, MIN, MAX</p>
                  <p><b>Required</b> Element Ref, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_MATH_OPERATION</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_MATH_OPERATION [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">sum</TableCell>
                          <TableCell align="left">2000</TableCell>
                          <TableCell align="left">This action verifies if sum of all the matching element is 2000, if yes it will pass.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_MATH_OPERATION</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_MATH_OPERATION [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">max</TableCell>
                          <TableCell align="left">47.49</TableCell>
                          <TableCell align="left">This action will verify if the maximum value of the element is 47.49 if yes then it will pass.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_MATH_OPERATION</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_MATH_OPERATION [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">min</TableCell>
                          <TableCell align="left">10.47</TableCell>
                          <TableCell align="left">This action will verify if the minimum value of the element is 10.47 if yes then it will pass.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENTS_STRING_OPERATIONS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:-This action performs an operation on tle list of strings and verifies the result with the string passed in the expected result field.The operations include GetSubString or Mid which verifies the specified number of charcters from a string,Lower or Lcase which verifies the lowerCase of characters in a string,Upper or Ucase which verifies the upperCase of characters in a string , left which verifies the specified characters from left side of a string , right which verifies the specified charcters from right of a string , length which verifies the length of a given string , ltrim which verifies spaces on the left side of a string , rtrim which verifies spaces on the right side of a string , trim which verifies spaces on both the sides a given string , replace verifies the replaced string from the list of strings.</p>
                  <p><b>Required</b> Element Ref, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125;* Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">mid;1;2</TableCell>
                          <TableCell align="left">MO</TableCell>
                          <TableCell align="left">This action will perform mid operation on the list of strings and verify the string MO in the expected result field with the result of the operation.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">lower</TableCell>
                          <TableCell align="left">televisions</TableCell>
                          <TableCell align="left">This string will verify the string passed in the expected result field with the lowercase of the strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">upper</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This string will verify the string passed in the expected result field with the uppercase of strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">length</TableCell>
                          <TableCell align="left">13</TableCell>
                          <TableCell align="left">This action will be verify the length passed in the expected result field with the length of strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">left;5</TableCell>
                          <TableCell align="left">TELEV</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of left operation, which returns specified characters from left side of a string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">right;5</TableCell>
                          <TableCell align="left">BLETS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of left operation, which returns specified characters from right side of a string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">ltrim</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of the ltrim operation, which verifies spaces on the left side of the string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">rtrim</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of the rtrim operation, which verifies spaces on the right side of the string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">trim</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of the trim operation, which verifies spaces on the both sides of the string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">replace;HEADPHONES</TableCell>
                          <TableCell align="left">TABLETS</TableCell>
                          <TableCell align="left">This action verifies the replaced string from the list of strings.Here the string to be replaced is passed along with the action name separated by a semicolon in the test data.The string in the expected result field is the new string which is verified against the strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">startswith;Sapient</TableCell>
                          <TableCell align="left">true</TableCell>
                          <TableCell align="left">This action will verify the start text of the element as specified in the test data column with the boolean value as specified in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">endswith;Sapient</TableCell>
                          <TableCell align="left">true</TableCell>
                          <TableCell align="left">This action will verify the end text of the element as specified in the test data column with the boolean value as specified in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_STRING_OPERATIONS</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_STRING_OPERATIONS [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">charat;I</TableCell>
                          <TableCell align="left">I</TableCell>
                          <TableCell align="left">This action will verify the character from the element as specified by the index in the test data column with the text in the expected result column.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENTS_TEXT_VALUES</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- Verifies multiple texts for a given set of elements.</p>
                  <p><b>Required</b> Element Ref, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENTS_TEXT_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENTS_TEXT_VALUES [Locator] &lt;TestData&gt; &#123;Expected_Result&#125; *Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">text</TableCell>
                          <TableCell align="left">Android;iOS;Windows;Blackberry</TableCell>
                          <TableCell align="left">Verifies weather the elements match or does not match the expected text values.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENT_HEIGHT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the rendered height of the element against the Expected Result.</p>
                  <p><b>Required</b> Element Ref, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENT_HEIGHT</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENT_HEIGHT [Locator] &#123;Expected_Result&#125;*Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">height</TableCell>
                          <TableCell align="left">Verifies weather the elements height match the expected values.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENT_NOT_PRESENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies if the element does not present on the page.</p>
                  <p><b>Required</b> Element Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENT_NOT_PRESENT</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENT_NOT_PRESENT [Locator] * Where locator should be described in locators page. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will verify if the element is not present on the page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENT_PRESENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies if the element present on the page.</p>
                  <p><b>Required</b> Element Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENT_PRESENT</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENT_PRESENT [Locator] * Where locator should be described in locators page. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action will verify if the element is present on the page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENT_TEXT_LENGTH</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the length of the text of the element against Expected Result.</p>
                  <p><b>Required</b> Element Reference, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENT_TEXT_LENGTH</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENT_TEXT_LENGTH [Locator] &#123;Expected_Result&#125; * Where locator should be described in locators page and &#123;Expected_Result&#125; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">24</TableCell>
                          <TableCell align="left">This step will PASS if the text of the ObjectRef element is 24.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENT_VALUE_LENGTH</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the length of the value of the element against Expected Result.</p>
                  <p><b>Required</b> Element Reference, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENT_VALUE_LENGTH</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENT_VALUE_LENGTH [Locator] &#123;Expected_Result&#125; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">4</TableCell>
                          <TableCell align="left">This step will PASS if the given element value is 4.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_ELEMENT_WIDTH</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the width attribute of the element against the Expected Result.</p>
                  <p><b>Required</b> Element Reference, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_ELEMENT_WIDTH</TableCell>
                          <TableCell align="left">Then VERIFY_ELEMENT_WIDTH [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">20</TableCell>
                          <TableCell align="left">This step will PASS if the given element width is 20 pixel.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_HEIGHT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the rendered height of the element against the Expected Result.</p>
                  <p><b>Required</b> Element Reference, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_HEIGHT</TableCell>
                          <TableCell align="left">Then VERIFY_HEIGHT [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left">This step will PASS if the given element height is 10 pixel.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_IMAGE_LOADED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies whether the image is loaded correctly or broken, using HTTP response.</p>
                  <p><b>Required</b> Element Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_IMAGE_LOADED</TableCell>
                          <TableCell align="left">Then VERIFY_IMAGE_LOADED [Locator] &lt;Test_Data&gt; * Where locator should be described in locators page and Test_Data should be described in UI Testing column in Environment. </TableCell>
                          <TableCell align="left">/cart/banners/coupon-1.jpg</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This step will PASS if the image exist with src mentioned in TestData.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_IMAGE_SRC_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies if the image with src specified in the Expected Result exist on the Page at least one instance.</p>
                  <p><b>Required</b> Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_IMAGE_SRC_EXIST</TableCell>
                          <TableCell align="left">Then VERIFY_IMAGE_SRC_EXIST [Locator] * Where locator should be described in locators page. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This step will PASS if given image reference is loaded.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
                     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                       <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_IS_SELECTED</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                       <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                         <p><b>Description</b>:- This action used to verify that the given element is selected.</p>
                         <p><b>Required</b> Element Reference</p>
                         <p><b>Applicable To</b>: Desktop</p>
                         <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                           <Table border="2" className={classes.table} aria-label="simple table">
                             <TableHead bgcolor="green">
                               <TableRow>
                                 <TableCell align="left">ACTION</TableCell>
                                 <TableCell align="left">EXAMPLE</TableCell>
                                 <TableCell align="left">DATA</TableCell>
                                 <TableCell align="left">EXPECTED_RESULT</TableCell>
                                 <TableCell align="left">OUTCOME</TableCell>
                               </TableRow>
                             </TableHead>
                             <TableBody>
                               <TableRow key='ac'>
                                 <TableCell component="th" scope="row">VERIFY_IS_SELECTED</TableCell>
                                 <TableCell align="left">Then VERIFY_IS_SELECTED [Locator] * Where locator should be described in the Object Repository tab. </TableCell>
                                 <TableCell align="left"></TableCell>
                                 <TableCell align="left"></TableCell>
                                 <TableCell align="left">This step will PASS if the given element is selected.</TableCell>
                               </TableRow>
                             </TableBody>
                           </Table>
                         </TableContainer>
                       </div>
                     </AccordionDetails>
                   </Accordion>

                   <Accordion style={{ backgroundColor: '#eee' }}>
                     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                       <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_IS_UNSELECTED</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                       <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                         <p><b>Description</b>:- This action used to verify that the given element is unselected.</p>
                         <p><b>Required</b> Element Reference</p>
                         <p><b>Applicable To</b>: Desktop</p>
                         <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                           <Table border="2" className={classes.table} aria-label="simple table">
                             <TableHead bgcolor="green">
                               <TableRow>
                                 <TableCell align="left">ACTION</TableCell>
                                 <TableCell align="left">EXAMPLE</TableCell>
                                 <TableCell align="left">DATA</TableCell>
                                 <TableCell align="left">EXPECTED_RESULT</TableCell>
                                 <TableCell align="left">OUTCOME</TableCell>
                               </TableRow>
                             </TableHead>
                             <TableBody>
                               <TableRow key='ac'>
                                 <TableCell component="th" scope="row">VERIFY_IS_UNSELECTED</TableCell>
                                 <TableCell align="left">Then VERIFY_IS_UNSELECTED [Locator] * Where locator should be described in the Object Repository tab. </TableCell>
                                 <TableCell align="left"></TableCell>
                                 <TableCell align="left"></TableCell>
                                 <TableCell align="left">This step will PASS if the given element is unselected.</TableCell>
                               </TableRow>
                             </TableBody>
                           </Table>
                         </TableContainer>
                       </div>
                     </AccordionDetails>
                   </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_LAYOUT_ABOVE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:-This action verifies if the Expected Object is above the ObjectRef element. If it is above then this action will pass. Expected action reference can have associated pixel, pixel pattern or equals in case of self-reference.
                    <br></br>The distance calculation between two elements can be altered through test data by providing the valid creative distance measure attribute (Refer the Reference Section).
                    <br></br>This action can also be used to verify the distance between page bottom and the element in the set view port</p>
                  <p><b>Required</b> Element Ref, Test Data, Expected Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">mid;1;2</TableCell>
                          <TableCell align="left">MO</TableCell>
                          <TableCell align="left">This action will perform mid operation on the list of strings and verify the string MO in the expected result field with the result of the operation.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">lower</TableCell>
                          <TableCell align="left">televisions</TableCell>
                          <TableCell align="left">This string will verify the string passed in the expected result field with the lowercase of the strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">upper</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This string will verify the string passed in the expected result field with the uppercase of strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">length</TableCell>
                          <TableCell align="left">13</TableCell>
                          <TableCell align="left">This action will be verify the length passed in the expected result field with the length of strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">left;5</TableCell>
                          <TableCell align="left">TELEV</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of left operation, which returns specified characters from left side of a string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">right;5</TableCell>
                          <TableCell align="left">BLETS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of left operation, which returns specified characters from right side of a string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">ltrim</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of the ltrim operation, which verifies spaces on the left side of the string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">rtrim</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of the rtrim operation, which verifies spaces on the right side of the string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">trim</TableCell>
                          <TableCell align="left">TELEVISIONS</TableCell>
                          <TableCell align="left">This action will verify the string passed in the expected result field with the result of the trim operation, which verifies spaces on the both sides of the string.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">replace;HEADPHONES</TableCell>
                          <TableCell align="left">TABLETS</TableCell>
                          <TableCell align="left">This action verifies the replaced string from the list of strings.Here the string to be replaced is passed along with the action name separated by a semicolon in the test data.The string in the expected result field is the new string which is verified against the strings in the list.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">startswith;Sapient</TableCell>
                          <TableCell align="left">true</TableCell>
                          <TableCell align="left">This action will verify the start text of the element as specified in the test data column with the boolean value as specified in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">endswith;Sapient</TableCell>
                          <TableCell align="left">true</TableCell>
                          <TableCell align="left">This action will verify the end text of the element as specified in the test data column with the boolean value as specified in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LAYOUT_ABOVE</TableCell>
                          <TableCell align="left">Then VERIFY_LAYOUT_ABOVE [Locator] &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">charat;I</TableCell>
                          <TableCell align="left">I</TableCell>
                          <TableCell align="left">This action will verify the character from the element as specified by the index in the test data column with the text in the expected result column.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_LINK_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This Action will check whether the link exist on the page.</p>
                  <p><b>Required</b> Object Reference, Test Data</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LINK_EXIST</TableCell>
                          <TableCell align="left">Then VERIFY_LINK_EXIST &lt;Test_Data&gt; * Where locator should be described in locators page and Test_Data should be described in UI Testing column in Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Logout</TableCell>
                          <TableCell align="left">Step will pass if link matches the xpath equivalent to //a[text()='conact'] in the current page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_LINK_TEXT_BY_INDEX</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies whether the specific link text exist at (n-1)th index, in otherworldly whether the link is available at least (n-1)-number of times. Underscore in the expected result will act as a delimiter between the link text and the index.
                    <br></br>Note : index starts from 0.
                    <br></br>This is applicable when ObjectRef matches more than one objects</p>
                  <p><b>Required</b> Object Reference, Expected_Result </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_LINK_TEXT_BY_INDEX</TableCell>
                          <TableCell align="left">Then VERIFY_LINK_TEXT_BY_INDEX &lt;Test_Data&gt; * Where locator should be described in locators page and Test_Data should be described in UI Testing column in Environment. </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">contact_5</TableCell>
                          <TableCell align="left">Step will pass if link matches the xpath equivalent to //a[text()='contact'][6] in the current page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_NUMERIC_VALUES</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action compares the two numeric values by ignoring comma seperator and $ symbol returns pass or fail.
                    <br></br>The numeric value provided in the Test Data is compared against the element text using the comparison operator specified in the expected result. For the numeric comparision 10,000.00 is same as 10000.0, $10,000.000, 10,000 and so on
                    <br></br>Valid Operator: &#61;&#61;, !&#61;, &#62;=, &#60;=, &#62;&#60;, !&#60;, !&#62;</p>
                  <p><b>Required</b> Object Reference, Test_Data, Expected_Result </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_NUMERIC_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_NUMERIC_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">100</TableCell>
                          <TableCell align="left">&#62;=</TableCell>
                          <TableCell align="left">This action verifies if the Test Data is greater than or equals to ObjectRef text to make this step PASS.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_PAGE_BROKEN_IMAGES</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                                          <p><b>Description</b>: This action will verify the broken images for a page.</p>
                                          <p><b>Required</b>: Element Reference</p>
                                          <p><b>Applicable To</b>: Desktop, Mobile</p>
                                          <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                                            <Table border="2" className={classes.table} aria-label="simple table">
                                              <TableHead bgcolor="green">
                                                <TableRow>
                                                  <TableCell align="left">ACTION</TableCell>
                                                  <TableCell align="left">SYNTAX</TableCell>
                                                  <TableCell align="left">TEST DATA</TableCell>
                                                  <TableCell align="left">EXPECTED RESULT</TableCell>
                                                  <TableCell align="left">OUTCOME</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow key='ac'>
                                                  <TableCell component="th" scope="row">VERIFY_PAGE_BROKEN_IMAGES</TableCell>
                                                  <TableCell align="left">Then VERIFY_PAGE_BROKEN_IMAGES</TableCell>
                                                  <TableCell align="left"></TableCell>
                                                  <TableCell align="left"></TableCell>
                                                  <TableCell align="left">This will verify the broken images for a page.It will also provide the number of broken images in a page</TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </div>
                                      </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_PAGE_BROKEN_LINKS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used to verify the broken page links. This action requires the element reference to filter out the type of element to be considered for the verification. In Object Repository user can just pass Tag Name "a" to validate all the anchor tags or img to verify all the image links or can be xpath "//product//img" etc., However the elements matching the given criteria will be further filtered to ensure it has "href" tag.
                    <br></br>All the links matching the criteria will be sent through HttpURLConnection and will expect the status 200, to consider the link is not broken. The links will not be navigated through browser driver.</p>
                  <p><b>Required</b> Object Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_PAGE_BROKEN_LINKS</TableCell>
                          <TableCell align="left">Then VERIFY_PAGE_BROKEN_LINKS [Locator] * Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">This action verifies page broken links.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_PARTIAL_LINK_TEXT_AT_INDEX_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies whether the link text contains the specific text at n-th index, in otherwords whether the link text containing specific text is available at least n-number of times. Underscore in the expected result will act as a delimiter between the partial link text and the index.
                    <br></br>This is applicable when ObjectRef matches more than one objects</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_PARTIAL_LINK_TEXT_AT_INDEX_EXIST</TableCell>
                          <TableCell align="left">Then VERIFY_PARTIAL_LINK_TEXT_AT_INDEX_EXIST [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">approved_3</TableCell>
                          <TableCell align="left">Step will pass if link matches the xpath equivalent to //a[contains(text(), 'approved')][3] in the page.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_PARTIAL_LINK_TEXT_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies whether the link text contains the specific text at n-th index, in otherwords whether the link text containing specific text is available at least n-number of times. Underscore in the expected result will act as a delimiter between the partial link text and the index.
                    <br></br>This is applicable when ObjectRef matches more than one objects</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_PARTIAL_LINK_TEXT_EXIST</TableCell>
                          <TableCell align="left">Then VERIFY_PARTIAL_LINK_TEXT_EXIST [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">ontains 'Order#' in the Page. It will match Order# in 'Order#145'</TableCell>
                          <TableCell align="left">order#</TableCell>
                          <TableCell align="left">This action will verify if the link text contains 'Order#' in the Page. It will match Order# in 'Order#12345'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_PART_TEXT_PRESENT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies if the element text contains the Expected Result.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_PART_TEXT_PRESENT</TableCell>
                          <TableCell align="left">Then VERIFY_PART_TEXT_PRESENT [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Sanjeet</TableCell>
                          <TableCell align="left">This step will PASS even if the text of the ObjectReference is 'Welcome Sanjeet'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_REGEX_PATTERN_ATTRIBUTE_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the regular expression pattern against the element attribute value passed in Test Data and this step will pass if the element attribute value matches the expected pattern.</p>
                  <p><b>Required</b> Object Reference, Test_Data, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_REGEX_PATTERN_ATTRIBUTE_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_REGEX_PATTERN_ATTRIBUTE_VALUE [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">type</TableCell>
                          <TableCell align="left">(text|password)</TableCell>
                          <TableCell align="left">This action will pass if the element attribute "type" is either text or password.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_REGEX_PATTERN_CSS_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the regular expression pattern against the element text and this step will pass if the element text matches the expected pattern.</p>
                  <p><b>Required</b> Object Reference, Test_Data, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_REGEX_PATTERN_CSS_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_REGEX_PATTERN_CSS_VALUE [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">font-family</TableCell>
                          <TableCell align="left">Times</TableCell>
                          <TableCell align="left">This action verifies if the font-family name contains Times in it.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_REGEX_PATTERN_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the regular expression pattern against the element text and this step will pass if the element text matches the expected pattern.</p>
                  <p><b>Required</b> Object Reference, Test_Data, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_REGEX_PATTERN_TEXT</TableCell>
                          <TableCell align="left">Then VERIFY_REGEX_PATTERN_TEXT [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">-100px;0px</TableCell>
                          <TableCell align="left">\\d{4}-\\d{2}-\\d{2}</TableCell>
                          <TableCell align="left">This action will pass if text of the ObjectRef contains 1224-43-00 ( four digit hyphen two digit hyphen two digit). This will also match the phrase "Your Policy Expiry is on 2017-19-10.".</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_REGEX_VARIABLE_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the test data against the expected regular expression pattern. The step passes when the match is successful.</p>
                  <p><b>Required</b> Object Reference, Test_Data, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_REGEX_VARIABLE_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_REGEX_VARIABLE_VALUE [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">ordernumber</TableCell>
                          <TableCell align="left">The Order Number is \\d{4}-\\d{2}-\\d{2}</TableCell>
                          <TableCell align="left">This action will pass if the test data contains like "The Order Number is 1234-54-22"</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_REGEX_VARIABLE_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_REGEX_VARIABLE_VALUE [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">2019-AUG-01</TableCell>
                          <TableCell align="left">\\d{4}-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-\\d{2}</TableCell>
                          <TableCell align="left">This step will pass as the test data matches the expected regex pattern</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_RELATIVE_VALUES</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action does the relative comparison of two numeric variables such as equals ,greater than , less than , greater than equals , less than equals , not equals :
                    <br></br><b>Valid Operations:</b> eq, equal =, greaterthan, gt, &#62;, greaterthanorequalsto, gte, &#62;=, lesserthan, lt, &#60;, lesserthanorequalsto, lte, &#60;=, notequal, &#60;&#62;, !=, ne</p>
                  <p><b>Required</b> Object Reference, Test_Data, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_RELATIVE_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_RELATIVE_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment. </TableCell>
                          <TableCell align="left">lesserthan</TableCell>
                          <TableCell align="left">varExpected</TableCell>
                          <TableCell align="left">This action will pass if value of the variable in the test data is lesser than the value of the variable in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_RELATIVE_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_RELATIVE_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">gt;50</TableCell>
                          <TableCell align="left">35</TableCell>
                          <TableCell align="left">This action will pass if value of the value in the test data is greater than the value of the variable in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_RELATIVE_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_RELATIVE_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">>=;15</TableCell>
                          <TableCell align="left">Expected Variable</TableCell>
                          <TableCell align="left">	Action will pass if expected varaiable has value 15 or less.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_RELATIVE_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_RELATIVE_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">lte</TableCell>
                          <TableCell align="left">25</TableCell>
                          <TableCell align="left">This action will pass if value of the variable in the test data is lesser than or equal to the value of the variable in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_RELATIVE_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_RELATIVE_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">equal</TableCell>
                          <TableCell align="left">20</TableCell>
                          <TableCell align="left">This action will pass if value of the variable in the test data is equal to the value of the variable in the expected result column.</TableCell>
                        </TableRow>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_RELATIVE_VALUES</TableCell>
                          <TableCell align="left">Then VERIFY_RELATIVE_VALUES [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">!=</TableCell>
                          <TableCell align="left">40</TableCell>
                          <TableCell align="left">This action will pass if value of the variable in the test data is not equal to the value of the variable in the expected result column.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_TEXT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the text of the element against the Expected Result.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_TEXT</TableCell>
                          <TableCell align="left">Then VERIFY_TEXT [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Welcome Sanjeet</TableCell>
                          <TableCell align="left"> This step will PASS if the text of the ObjectRef is 'Welcome Sanjeet'.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_TEXT_CONTAINS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies element text contains the expected result.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_TEXT_CONTAINS</TableCell>
                          <TableCell align="left">Then VERIFY_TEXT_CONTAINS [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">order#</TableCell>
                          <TableCell align="left"> This step will pass if element text is "Order# 23444" as it contains expected string.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_TEXT_CONTAINS_IGNORE_CASE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies element text contains the expected result ignoring the case-sensitive.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_TEXT_CONTAINS_IGNORE_CASE</TableCell>
                          <TableCell align="left">Then VERIFY_TEXT_CONTAINS_IGNORE_CASE [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">order#</TableCell>
                          <TableCell align="left"> This step will pass if element text is "Order# 23444" as it contains expected string.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_TEXT_EXIST</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action will check if the specified text exist in the page or not.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_TEXT_EXIST</TableCell>
                          <TableCell align="left">Then VERIFY_TEXT_EXIST [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">SANJEET</TableCell>
                          <TableCell align="left"> This step will pass if current page contains the text "SANJEET".</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> VERIFY_TEXT_IS_EMPTY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies whether the element text is empty.</p>
                  <p><b>Required</b> Object Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row"> VERIFY_TEXT_IS_EMPTY</TableCell>
                          <TableCell align="left">Then  VERIFY_TEXT_IS_EMPTY [Locator] * Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> This action will pass when the ObjectRef doesn't have text in it.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> VERIFY_TEXT_IS_NOT_EMPTY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies whether the element text is empty.</p>
                  <p><b>Required</b> Object Reference</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row"> VERIFY_TEXT_IS__NOT_EMPTY</TableCell>
                          <TableCell align="left">Then  VERIFY_TEXT_IS_NOT_EMPTY [Locator] * Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> This action will pass when the ObjectRef has text in it.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_TEXT_NOT_AS_EXPECTED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the text of the element against the Expected Result by ignoring the case.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_TEXT_NOT_AS_EXPECTED</TableCell>
                          <TableCell align="left">Then VERIFY_TEXT_NOT_AS_EXPECTED [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Publicis Sapient</TableCell>
                          <TableCell align="left"> This action will verify the text passed in the expected result does not matches with the result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the value of the element against the Expected Result.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_VALUE [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Publicis Sapient</TableCell>
                          <TableCell align="left"> This action verifies the value of the element against the Expected Result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_VALUE_JS</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- Verify value based on element value using javascript /n Expected : Locator of element and expected value as expected message.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_VALUE_JS</TableCell>
                          <TableCell align="left">Then VERIFY_VALUE_JS [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">Publicis Sapient</TableCell>
                          <TableCell align="left"> This action verifies the value using javascriptexecutor of the element against the Expected Result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
     <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}> VERIFY_VARIABLE_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:This action is used to compare the actual variable value against the expected variable value.</p>
                  <p><b>Required</b>:Test Data, Options </p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_VARIABLE_VALUE</TableCell>
                          <TableCell align="left">Then VERIFY_VARIABLE_VALUE &lt;TestData&gt; &#123;Expected_Result&#125;<br></br>* Where test data and options should be described in UI Testing section of the Environment tab.</TableCell>
                          <TableCell align="left">actualVariableValue</TableCell>
                          <TableCell align="left">expectedVariableValue</TableCell>
                          <TableCell align="left">This action will compare the actual variable value against the expected variable value.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_WEB_TABLE_COUNT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action is used to count the number of records for the particular column value</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_WEB_TABLE_COUNT</TableCell>        
                          <TableCell align="left">And User VERIFY_WEB_TABLE_COUNT &#123;Record_Count&#125; &lt;coloumname;coloumData&gt; of [Locator]<br></br>* Where locator should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will calculate the count of matching columns</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_WEB_TABLE_VALUES</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action validates the records for the particular column value</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_WEB_TABLE_VALUES</TableCell>        
                          <TableCell align="left">And User VERIFY_WEB_TABLE_VALUES &lt;colName;colValue&gt; on [columnHeadingRow ;valueRows] <br></br>* Where columnHeadingRow specifies the row headers and valueRows specifies the rows and 
                          should be described in locators page.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will validate records against matching columns</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WAIT_UNTIL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>: This action waits untill the element state changes</p>
                  <p><b>Required</b>: Element Reference</p>
                  <p><b>Applicable To</b>: Desktop, Mobile</p>

                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WAIT_UNTIL</TableCell>        
                          <TableCell align="left">And User WAIT_UNTIL &lt;timeToWait&gt; [locator] with &#123;stateToBeTested&#125; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; and &lt;stateToBeTested&gt; should be declared in UI Testing section of Environment where state can be enabled, disabled, visible, hidden,selected, unselected </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">It will wait for the particular element to change its state</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>



            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>VERIFY_WIDTH</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action verifies the rendered width of the element against the Expected Result.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">VERIFY_WIDTH</TableCell>
                          <TableCell align="left">Then VERIFY_WIDTH [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">20px</TableCell>
                          <TableCell align="left"> This action verifies the element width against the Expected Result.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WAIT_TILL_CSS_DISPLAYED</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action wait till the CSS value of the element is matched or times out.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WAIT_TILL_CSS_DISPLAYED</TableCell>
                          <TableCell align="left">Then WAIT_TILL_CSS_DISPLAYED [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">color=rgba(255,0,0,1)</TableCell>
                          <TableCell align="left">This action will wait till the element css style color changes to red.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WAIT_UNTIL_ATTRIBUTE_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- To wait until timeout (provided in Test-data) or the attribute of object meets the expected condition.
                    <br></br> TestData Format: Integer value in seconds
                    <br></br> Expected Result: Attribute name and expected value separated by "=". For ex. data-target=primary-menu</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WAIT_UNTIL_ATTRIBUTE_VALUE</TableCell>
                          <TableCell align="left">Then WAIT_UNTIL_ATTRIBUTE_VALUE [Locator] + &lt;TestData&gt; &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;TestData&gt; and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">20</TableCell>
                          <TableCell align="left">data-target=primary-menu</TableCell>
                          <TableCell align="left">Step will wait until either data-target attribute of the object is changed to primary-menu or will wait maximum of 20 Seconds whichever condition meets early.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WAIT_UNTIL_CSS_VALUE</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- To wait until the CSS value of the element is matched or times out.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WAIT_UNTIL_CSS_VALUE</TableCell>
                          <TableCell align="left">Then WAIT_UNTIL_CSS_VALUE [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">50</TableCell>
                          <TableCell align="left">color=rgba(255,0,0,1)</TableCell>
                          <TableCell align="left">This action will wait until the element css style color changes to red or maximum wait for 50 seconds.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WAIT_UNTIL_ELEMENT_DISPLAY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- Wait till element is displayed until specified time.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WAIT_UNTIL_ELEMENT_DISPLAY</TableCell>
                          <TableCell align="left">Then WAIT_UNTIL_ELEMENT_DISPLAY [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">60</TableCell>
                          <TableCell align="left">data-target=primary-menu</TableCell>
                          <TableCell align="left"> It waits until the element is displayed.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WAIT_UNTIL_ELEMENT_NOT_DISPLAY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- Wait until element not displayed.</p>
                  <p><b>Required</b> Object Reference, Expected_Result</p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">EXAMPLE</TableCell>
                          <TableCell align="left">DATA</TableCell>
                          <TableCell align="left">EXPECTED_RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WAIT_UNTIL_ELEMENT_NOT_DISPLAY</TableCell>
                          <TableCell align="left">Then WAIT_UNTIL_ELEMENT_NOT_DISPLAY [Locator] + &lt;Expected_Result&gt; * Where locator should be described in locators page and &lt;Expected_Result&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">60</TableCell>
                          <TableCell align="left">data-target=primary-menu</TableCell>
                          <TableCell align="left"> It wait until the element is not displayed.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: '#3f51b5' }}>WIDTH </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ fontFamily: 'Roboto', fontSize: '16px', marginTop: '-20px' }}>
                  <p><b>Description</b>:- This action is used compare the width of actual value with the given value (Data Reference)</p>
                  <p><b>Required</b>: Element Reference, Test Data </p>
                  <p><b>Applicable To</b>: Desktop</p>
                  <TableContainer component={Paper} style={{ marginTop: '30px' }}>
                    <Table border="2" className={classes.table} aria-label="simple table">
                      <TableHead bgcolor="green">
                        <TableRow>
                          <TableCell align="left">ACTION</TableCell>
                          <TableCell align="left">SYNTAX</TableCell>
                          <TableCell align="left">TEST DATA</TableCell>
                          <TableCell align="left">EXPECTED RESULT</TableCell>
                          <TableCell align="left">OUTCOME</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key='ac'>
                          <TableCell component="th" scope="row">WIDTH</TableCell>
                          <TableCell align="left">Then WIDTH [locator] + &lt;TestData&gt; <br></br>* Where locator should be described in locators page and &lt;TestData&gt; should be declared in UI Testing section of Environment.</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"> It will pass if the element have width of 10 px.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
