import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddApplication from "./AddApplication";

const useStyles = makeStyles(theme => ({
  font: {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    }
  }
}));

const EmptyApplication = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Box mt={20} ml={2} mr={2} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <img src="/empty_apps.svg" alt="No Records" width="360" />
      </Box>
      <Box mt={8} ml={2} mr={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      <img src="/empty_apps.svg" alt="No Records" width="800" />
      </Box>
      <Box mt={2} ml={2}>
        <Typography variant="h6" color="primary" className={classes.font}>
          Your are currently not assigned to any project.
          Please contact with your project admin to get required access.
        </Typography>
      </Box>
      <Box mt={2}>
        <AddApplication />
      </Box>
    </Grid>
  );
};

export default EmptyApplication;
