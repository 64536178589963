import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ReplaceIcon from "@material-ui/icons/Update";
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { saveFileRequest } from "../../thunks/supportiveFiles/thunks.js";
import { hasReadWriteRole } from '../../thunks/common/auth';
import { getSelectedApplication } from "../../selectors/application/selector";
import { getSelectedFile } from "../../selectors/file/selector";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2)
  },
  formControl: {
    minWidth: 180
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const ReplaceFile = ({ selectedFile,onReplaceFileClick,applicationId}) => {
 
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFile(false);
    setOpen(false);
  };

  const classes = useStyles();

  function SubmitButton() {

    if (file) {
      return <Button
        onClick={() => {
          onReplaceFileClick(selectedFile.id, file, applicationId);
          setFile("");
          handleClose();
        }}
        color="primary"
        variant="contained">Replace File</Button>
    } else {
      return <Button
        onClick={() => {
          onReplaceFileClick(file, applicationId);
          setFile("");
          handleClose();
        }}
        color="primary"
        disabled
        variant="contained">Replace File</Button>
    };
  };


  return (
    <Box>
      {hasReadWriteRole() && (
        <ToolTip title="Replace File">
          <ReplaceIcon onClick={handleClickOpen} />
        </ToolTip>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Replace File</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box mb={2}>
            <Button
              variant="contained"
              component="label"
              value={file}
              onChange={e => setFile(e.target.files[0])}
              style={{ background: 'white' }}
              >
              <input 
              type="file"
              className="form-control"
              placeholder="file"
              id="raised-button-file"
              multiple
              title
               />
            </Button>
          </Box>

        </DialogContent>
        <DialogActions>
          <SubmitButton />
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedFile: getSelectedFile(state),
  applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  onReplaceFileClick: (selectedFile, file, applicationId) => {
    dispatch(saveFileRequest(selectedFile, file, applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceFile);
