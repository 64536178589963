import {LIST_ALL_RUN} from "../../actions/run/actions";

export const allrun = (state = "", action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_ALL_RUN: {
      const { runs } = payload;
      return {
        ...state,
        data: runs
      };
    }
    
    default:
      return state;
  }
};
