import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EditSharpIcon from '@material-ui/icons/EditSharp';
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { addOrEditApplicationRequest } from "../../thunks/application/thunks.js";
import { syncApplicationRequest } from "../../thunks/application/thunks.js";
import { getSelectedApplication } from "../../selectors/application/selector.js";
import { hasAdminRole, hasReadWriteRole,hasProjectAdminRole } from '../../thunks/common/auth';
import { Sync } from "@material-ui/icons";
import { pushApplicationRequest } from "../../thunks/application/thunks.js";
import { ArrowUpward } from "@material-ui/icons";


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0)
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const PushApplication = ({
  selectedApplication,
  onPushClick,
}) => {

  const [open, setOpen] = React.useState(false);
  const [gitUri, setGitUri] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");  
  const [branch,setBranch] = React.useState("");
  const [commitMessage,setCommitMessage] = React.useState("");

  var validGitUri = gitUri.length > 0 && gitUri.includes("https://");
  var branchNameLength = branch.length>0 && branch.length<16;
  

  function SubmitButton(){
    if (branchNameLength && validGitUri){
      return <Button onClick={() => {
        onPushClick(selectedApplication.id, gitUri,username,password, branch, commitMessage);
        handleClose();
      }}
      color="primary"
      variant="contained">Push</Button>
    } else {
      return <Button onClick={() => {
        onPushClick(selectedApplication.id, gitUri,username,password, branch, commitMessage);
        handleClose();
      }}
      color="primary"
      disabled
      variant="contained">Push</Button>
    };
  };

  const handleClickOpen = () => {
    setOpen(true);
    setGitUri("");
    setUserName("");
    setPassword("");
    setBranch("");
    setCommitMessage("");
  };


  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Box>
      {/* {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) &&( */}
      <ToolTip title="Push Project">
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <ArrowUpward />
        </Fab>
      </ToolTip>
      {/* )} */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Push Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Push project , Please enter Git Url,username, password and Branch name.
          </DialogContentText>
          <Box mb={2}>
            
          <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="standard-basic"
              label="Git Url"
              value={gitUri}
              required
              error={!validGitUri}
              helperText={gitUri !== "" ? "Enter valid git Url" : " "}
              onChange={e => setGitUri(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="standard-basic"
              label="Git Username"
              value={username}
              required
              helperText={"Enter Correct git Username"}
              onChange={e => setUserName(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="standard-basic"
              label="Git password"
              value={password}
              type="password"
              required
              helperText={"Enter Correct git Password"}
              onChange={e => setPassword(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="standard-basic"
              label="Branch Name"
              value={branch}
              required
              helperText={"Enter correct existing Branch name"}
              onChange={e => setBranch(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="standard-basic"
              label="Commit Message"
              value={commitMessage}
              // required
              helperText={"Enter your Commit Message"}
              onChange={e => setCommitMessage(e.target.value)}
              fullWidth
            />
           
          </Box>
        </DialogContent>
        <DialogActions>
          <SubmitButton/>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedApplication: getSelectedApplication(state),
});

const mapDispatchToProps = dispatch => ({
  onPushClick: (id, gitUri, username, password, branch, commitMessage) => dispatch(pushApplicationRequest(id, gitUri, username, password, branch, commitMessage))
});

export default connect(mapStateToProps, mapDispatchToProps)(PushApplication);
