import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import GlobalObjectTab from "./GlobalObjectTab";
import TablePagination from "@material-ui/core/TablePagination";
import { connect } from "react-redux";
import {
  listGlobalObjectRequest,
  setSelectedGlobalObjectRequest
} from "../../thunks/globalObject/thunks";
import { map } from "lodash";
import {
  getGlobalObjects,
  getSearchGlobalObjectCount,
  getPage,
  getSize
} from "../../selectors/globalObject/selector";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import DisplayDate from "../common/DisplayDate";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AddGlobalObject from "./AddGlobalObject";
import Chip from "@material-ui/core/Chip";
import { getSelectedApplication } from "../../selectors/application/selector";
import AlertDialog from './GlobalObjectDialog'
import { hasReadWriteRole, hasAdminRole, hasProjectAdminRole } from '../../thunks/common/auth';
import GlobalObjectSearch from "./GlobalObjectSearchBar";

const GlobalObjectList = ({
  loadGlobalObjectFromServer,
  setSelectedGlobalObject,
  globalObjects = [],
  count = -1,
  page = 0,
  size = 10,
  applicationId
}) => {
  useEffect(() => {
    loadGlobalObjectFromServer(0, size, applicationId);
  }, [loadGlobalObjectFromServer, size, applicationId]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [globalObjectSelected, setGlobalObjectSelected] = React.useState({});


  const handleListItemClick = index => {
    setSelectedIndex(index);
    setGlobalObjectSelected(globalObjects[index]);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    purple: {
      backgroundColor: '#3f51b5'
    }
  }));

  const classes = useStyles();

  const onChangePage = (event, pageSelected) => {
    loadGlobalObjectFromServer(pageSelected, size,applicationId);
    setSelectedIndex(0);
  };

  return (
    <div>
    <Box  sx={{  display: { xs: 'none', md: 'block'}}}>
      <Grid container>
        <Grid item xs={4}>
          <Box mt={1} mr={2}>
          <div style={{ marginTop: '30px' }}> <GlobalObjectSearch /></div>
            <List className={classes.root} component="nav">
              {map(globalObjects, (globalObject, index) => (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ListItem
                      divider
                      button
                      selected={selectedIndex === index}
                      onClick={event => {
                        handleListItemClick(index);
                        setSelectedGlobalObject(globalObjects[index]);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.purple}>
                          <FindInPageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={globalObject.name}
                        secondary={
                          <DisplayDate
                            dateString={globalObject.created_date_time}
                            prefixString="Created on "
                          />
                        }
                      />
                      <Grid item xs={12} sm={3}>
                          <Chip label={globalObject.application.name} style={{backgroundColor: '#f50057', color: 'white'}}/>
                        </Grid>

                        {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                  <Grid item xs={12} sm={1}>
                      <AlertDialog dialogLabel='Object Repository' selectedGlobalobjectId={globalObjectSelected.id} globalObjectName={globalObjectSelected.name} />
                  </Grid>
                  )}
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
            </List>
            {count > 0 && (
              <Box display="flex" flexDirection="row-reverse">
                <AddGlobalObject />
                <TablePagination
                  rowsPerPageOptions={[size]}
                  component="div"
                  count={count}
                  rowsPerPage={size}
                  page={page}
                  onChangePage={onChangePage}
                />
              </Box>
            )}

            
{count === 0 && (
                <Box display="flex" justifyContent="space-evenly" style={{ color: '#f50057', fontSize: "large" }}>
                  No Search Results found. Please clear search box and try again !!!
                </Box>
              )}

          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box mt={2} mr={2}>
            {count > 0 && <GlobalObjectTab />}
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box  sx={{  display: { xs: 'block', md: 'none' } }}>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={1} mr={2}>
            <List className={classes.root} component="nav">
              {map(globalObjects, (globalObject, index) => (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ListItem
                      divider
                      button
                      selected={selectedIndex === index}
                      onClick={event => {
                        handleListItemClick(index);
                        setSelectedGlobalObject(globalObjects[index]);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.purple}>
                          <FindInPageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Box><Typography variant="body2"  style={{ width: '400px' }}>{globalObject.name}</Typography></Box>}
                        secondary={<Typography variant="body2" color="textSecondary" style={{ width: '400px' }}> <DisplayDate
                        dateString={globalObject.created_date_time}
                        />
                      </Typography>}
                      />
                      <Grid item xs={12} sm={3}>
                          {/* <Chip label={globalObject.application.name} style={{backgroundColor: '#f50057', color: 'white', marginLeft: '120px'}}/> */}
                        </Grid>
                    
                        {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                  <Grid item xs={12} sm={1}>
                      <AlertDialog dialogLabel='globalObject' selectedGlobalobjectId={globalObjectSelected.id} globalObjectName={globalObjectSelected.name} />
                  </Grid>
                  )}
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
            </List>
            {count > 0 && (
              <Box display="flex" flexDirection="row-reverse">
                <AddGlobalObject />
                <TablePagination
                  rowsPerPageOptions={[size]}
                  component="div"
                  count={count}
                  rowsPerPage={size}
                  page={page}
                  onChangePage={onChangePage}
                />
              </Box>
            )}
            
{count === 0 && (
                <Box display="flex" justifyContent="space-evenly" style={{ color: '#f50057', fontSize: "large" }}>
                  No Search Results found. Please clear search box and try again !!!
                </Box>
              )}

          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mr={2}>
            {count > 0 && <GlobalObjectTab />}
          </Box>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  globalObjects: getGlobalObjects(state),
  count: getSearchGlobalObjectCount(state),
  page: getPage(state),
  size: getSize(state),
  applicationId: getSelectedApplication(state).id
});

const mapDispatchToProps = dispatch => ({
  loadGlobalObjectFromServer: (page, size, applicationId) => {
    dispatch(listGlobalObjectRequest(page, size,"", applicationId));
  },
  setSelectedGlobalObject: globalObject => {
    dispatch(setSelectedGlobalObjectRequest(globalObject));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalObjectList);
