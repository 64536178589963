import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddFeature from "./AddFeature";

const useStyles = makeStyles(theme => ({
  font: {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    }
  }
}));

const EmptyFeature = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Box mt={4} ml={2} mr={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <img src="/empty.svg" alt="No Records" width="600" />
      </Box>
      <Box mt={10} ml={2} mr={2} mb={8} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <img src="/empty.svg" alt="No Records" width="360" />
      </Box>

      <Box mt={2} ml={2}>
        <Typography variant="h6" color="primary" className={classes.font}>
          No features found. Do you want to add the first feature?
        </Typography>
      </Box>
      <Box mt={2}>
        <AddFeature />
      </Box>
    </Grid>
  );
};

export default EmptyFeature;
