import React from "react";
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import HelpIcon from '@material-ui/icons/HelpOutline';
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from "@material-ui/icons/Assignment";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ListIcon from '@material-ui/icons/Assignment';
import Box from "@material-ui/core/Box";
import ToolTip from "@material-ui/core/Tooltip";
import EcoRoundedIcon from "@material-ui/icons/EcoRounded";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useKeycloak } from "@react-keycloak/web";
import { setToken, setKeycloakJson } from "../../thunks/common/auth";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import AcUnitIcon from "@material-ui/icons/AcUnit";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {KEYCLOAK_LOGOUT_REDIRECT_URL} from "../../EnvVariables";
import { Sync } from "@material-ui/icons";
import SyncApplication from "../application/SyncApplication";
import GitActions from "../application/GitActions";
import PushApplication from "../application/PushApplication";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    marginRight: theme.spacing(3)
  },
  margin: {
    margin: "5px"
  },
  marginLog: {
    marginLeft: 20

  },
  marginRight: {
    position: "absolute",
    right: theme.spacing(2)
  },
  marginEnvSelect: {},
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = () => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  var logoutOptions = { redirectUri : KEYCLOAK_LOGOUT_REDIRECT_URL }

  if (location.pathname == "/" || location.pathname == "/projects" || location.pathname == "/faq") {
    return (
      <div className={classes.root}>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
                <MenuIcon />
              </IconButton>
              <img src="/logo.png" width="100"  style={{marginLeft: '90px'}}/>
              {/* <Button color="inherit" href="/" >
                <Typography variant="h6" style={{marginLeft: '70px'}}>
                  CATS Lite
                </Typography>
              </Button> */}
              {keycloak && keycloak.authenticated && (
                <React.Fragment>
                  {setToken(keycloak.token)}
                  {setKeycloakJson(keycloak)}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{marginTop: '40px'}}
                  >
                    <MenuItem component={Link} to={"/projects"} onClick={handleClose}>Projects</MenuItem>
                    <MenuItem component={Link} to={"/faq"} onClick={handleClose}>Faq</MenuItem>
                  </Menu>

                  <Box className={classes.marginRight}>
                    <Button
                      color="inherit"
                      className={classes.marginLog}
                      startIcon={<ExitToAppIcon color="secondary" />}
                      onClick={() => keycloak.logout(logoutOptions)}
                    >
                      Logout
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Toolbar>
          </AppBar>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <AppBar position="static">
            <Toolbar>
              {/* <IconButton edge="start" color="inherit" aria-label="menu">
                <AcUnitIcon />
              </IconButton> */}
              <Button color="inherit" href="/">
              <img src="/logo.png" width="100" />
                {/* <Typography variant="h6" className={classes.title}>
                  CATS Lite
                </Typography> */}
                
              </Button>
              {keycloak && keycloak.authenticated && (
                <React.Fragment>
                  {setToken(keycloak.token)}
                  {setKeycloakJson(keycloak)}
                  <Box className={classes.marginRight}>
                    <ToolTip title="Projects">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/projects"}
                      >
                        <EcoRoundedIcon />
                      </Fab>
                    </ToolTip>
                    <ToolTip title="help">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/faq"}
                      >
                        <HelpIcon />
                      </Fab>
                    </ToolTip>
                    <Button
                      color="inherit"
                      className={classes.marginLog}
                      startIcon={<ExitToAppIcon color="secondary" />}
                      onClick={() => keycloak.logout(logoutOptions)}
                    >
                      Logout
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    );
  }
  else {
    return (
      <div className={classes.root}>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
                <MenuIcon />
              </IconButton>

              <Button color="inherit" href="/" >
              <img src="/logo.png" width="100"  style={{marginLeft: '90px'}}/>
              {/* <Typography variant="h6" style={{marginLeft: '70px'}}>
                  CATS Lite
                </Typography> */}
              </Button>
              {keycloak && keycloak.authenticated && (
                <React.Fragment>
                  {setToken(keycloak.token)}
                  {setKeycloakJson(keycloak)}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    style={{marginTop: '40px'}}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem component={Link} to={"/features"} onClick={handleClose}>Features</MenuItem>
                    <MenuItem component={Link} to={"/test-plan"} onClick={handleClose}>Test Plan</MenuItem>
                    <MenuItem component={Link} to={"/runs"} onClick={handleClose}>Runs</MenuItem>
                    <MenuItem component={Link} to={"/report-portal"} onClick={handleClose}>Reports</MenuItem>
                    <MenuItem component={Link} to={"/dashboard"} onClick={handleClose}>Dashboard</MenuItem>
                    <MenuItem component={Link} to={"/globalObjects"} onClick={handleClose}>Objects</MenuItem>
                    <MenuItem component={Link} to={"/environments"} onClick={handleClose}>Environments</MenuItem>
                    <MenuItem component={Link} to={"/projects"} onClick={handleClose} >Projects</MenuItem>
                  </Menu>

                  <Box className={classes.marginRight}>
                    <Button
                      color="inherit"
                      className={classes.marginLog}
                      startIcon={<ExitToAppIcon color="secondary" />}
                      onClick={() => keycloak.logout(logoutOptions)}
                    >
                      Logout
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Toolbar>
          </AppBar>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <AppBar position="static">
            <Toolbar>
            <img src="/logo.png" width="100" />
              {/* <Button color="inherit" href="/">
                <Typography variant="h6" className={classes.title}>
                  CATS Lite
                </Typography>
              </Button> */}
              {keycloak && keycloak.authenticated && (
                <React.Fragment>
                  {setToken(keycloak.token)}
                  {setKeycloakJson(keycloak)}
                  <Button
                    color="inherit"
                    startIcon={<AssignmentIcon />}
                    className={classes.margin}
                    component={Link}
                    to={"/features"}
                  >
                    Features
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={<BusinessCenterIcon />}
                    className={classes.margin}
                    component={Link}
                    to={"/test-plan"}
                  >
                    Test Plan
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={<DirectionsRunIcon />}
                    className={classes.margin}
                    component={Link}
                    to={"/runs"}
                  >
                    Runs
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={<AssessmentIcon />}
                    className={classes.margin}
                    component={Link}
                    to={"/report-portal"}
                  >
                    Report Portal
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={<DashboardIcon />}
                    className={classes.margin}
                    component={Link}
                    to={"/dashboard"}
                  >
                    Dashboard
                  </Button>
                  <Box className={classes.marginRight}>
                  <ToolTip title="File List">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/file"}
                      >
                        <ListIcon />
                      </Fab>
                    </ToolTip>
                    <ToolTip title="Object Repository">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/globalObjects"}
                      >
                        <FileCopyIcon />
                      </Fab>
                    </ToolTip>
                    <ToolTip title="Environments">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/environments"}
                      >
                        <ImportantDevicesIcon />
                      </Fab>
                    </ToolTip>
                    <ToolTip title="Projects">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/projects"}
                      >
                        <EcoRoundedIcon />
                      </Fab>
                    </ToolTip>
                    <ToolTip title="help">
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        className={classes.margin}
                        component={Link}
                        to={"/faq"}
                      >
                        <HelpIcon />
                      </Fab>
                    </ToolTip>
                     <Button
                      color="inherit"
                      className={classes.marginLog}
                    >
                      <PushApplication/>
                    </Button> 
                    <Button
                      color="inherit"
                      className={classes.marginLog}
                      startIcon={<ExitToAppIcon color="secondary" />}
                      onClick={() => keycloak.logout(logoutOptions)}
                    >
                      Logout
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    );
  }
};


export default Header;
