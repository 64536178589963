/**
 * Created by prakumar11 on 10/09/21.
 */

import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
    {
        name: 'Rel A',
        Fail: 400,
        Pass: 240,
        Skipped: 240,
    },
    {
        name: 'Rel B',
        Fail: 300,
        Pass: 139,
        Skipped: 220,
    },
    {
        name: 'Release C',
        Fail: 200,
        Pass: 980,
        Skipped: 229,
    },
    {
        name: 'Rel D',
        Fail: 270,
        Pass: 308,
        Skipped: 200,
    },
    {
        name: 'Rel E',
        Fail: 189,
        Pass: 480,
        Skipped: 281,
    },
    {
        name: 'Release F',
        Fail: 239,
        Pass: 380,
        Skipped: 250,
    },
    {
        name: 'Rel G',
        Fail: 340,
        Pass: 430,
        Skipped: 200,
    },
    {
        name: 'Rel H',
        Fail: 230,
        Pass: 380,
        Skipped: 250,
    },
    {
        name: 'Release I',
        ail: 340,
        Pass: 430,
        Skipped: 210,
    },

];

function demoOnClick(e) {
    alert(e.name);
}



export default class Chart6 extends PureComponent {



    render() {
        return (
        
                <BarChart
                    width={750}
                    height={300}
                    data={data}
                    margin={{
            top: 50,
            right: 20,
            left: 50,
            bottom: 5,
          }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend  />
                    <Bar dataKey="Pass" stackId="a" fill="#00C49F" onClick={demoOnClick} />
                    <Bar dataKey="Fail" stackId="a" fill="#FF8042" onClick={demoOnClick} />
                    <Bar dataKey="Skipped" stackId="a" fill="#FFBB28" onClick={event =>  window.location.href='/team'}/>
                </BarChart>
        );
    }
}
