import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    marginLeft: '50px',
    marginRight: '20px',
  },
  details: {
    textAlign: 'center',
    border: '1px solid lightgrey',
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
    borderRadius: '6px',
  },
  box: {
    marginTop: "60px",
    marginLeft: '50px',
    marginRight: '20px',
    width: "flex",
    boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
    borderRadius: '6px'
  },
  header: {
    float: 'left',
    paddingBottom: '106px'
  },
  chart: {
    flexGrow: 1,
    padding: '20px',
  }
}));

const columns = [
  { id: 'Feature', label: 'Feature', minWidth: 170 },
  {
    id: 'ScenarioCount',
    label: 'ScenarioCount',
    minWidth: 170,
    align: 'center',
    format: (value) => value,
  },
];

function createData(Feature, ScenarioCount) {
  return { Feature, ScenarioCount };
}

const rows = [
  createData('Feature 1', 12),
  createData('Feature 2', 78),
  createData('Feature 3', 33),
  createData('Feature 4', 31),
  createData('Feature 5', 12),
  createData('Feature 6', 3),
  createData('Feature 7', 44),
  createData('Feature 8', 12),
  createData('Feature 9', 55),
  createData('Feature 10', 53),
  createData('Feature 11', 245),
  createData('Feature 12', 2),
  createData('Feature 13', 432),
  createData('Feature 14', 3),
  createData('Feature 15', 21),
];



const DefectDashboard = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.root}>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit">
                  Total Defect Count
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  32
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{ backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                  Count of Open Defect (P1 & P2)
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  8
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card >
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                  Defect leakage ratio
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  4%
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                  Defect per release
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  30
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>

      </Grid>
    </div>

  );
};

export default DefectDashboard;
