export const START_LOADING = "START_LOADING";

export const startLoading = () => ({
  type: START_LOADING
});

export const STOP_LOADING = "STOP_LOADING";

export const stopLoading = () => ({
  type: STOP_LOADING
});

export const DISPLAY_MESSAGE = "DISPLAY_MESSAGE";

export const displayMessage = message => ({
  type: DISPLAY_MESSAGE,
  payload: message
});

export const CLEAR_DISPLAY_MESSAGE = "CLEAR_DISPLAY_MESSAGE";

export const clearDisplayMessage = () => ({
  type: CLEAR_DISPLAY_MESSAGE
});

export const LIST_USER = "LIST_USER";

export const listUser = user => ({
  type: LIST_USER,
  payload: { user }
});

export const LIST_ADMIN_USER = "LIST_ADMIN_USER";

export const listAdminUser = Admuser => ({
  type: LIST_ADMIN_USER,
  payload: { Admuser }
});

export const LIST_DEV_USER = "LIST_DEV_USER";

export const listDevUser = devUser => ({
  type: LIST_DEV_USER,
  payload: { devUser }
});

export const SET_SELECTED_USER = "SET_SELECTED_USER";

export const setSelectedUser = selectedUser => ({
  type: SET_SELECTED_USER,
  payload: { selectedUser }
});

export const LIST_SCHEDULE = "LIST_SCHEDULE";

export const listSchedule = schedule => ({
  type: LIST_SCHEDULE,
  payload: { schedule }
});