import {
  LIST_USER,
  LIST_ADMIN_USER,
  LIST_DEV_USER,
  SET_SELECTED_USER
} from "../../actions/common/actions.js";

export const user = (state = [], action) => {
  const { type, payload } = action;
  switch (type)
   {
    case LIST_USER: {
      const { user } = payload;
      const userList = {
        ...state,
        data: user
      };
      return userList;
    }

    case LIST_ADMIN_USER: {
      const { Admuser } = payload;
      const listAdminUser = {
        ...state,
        admdata: Admuser
      };
      return listAdminUser;
    }

    case LIST_DEV_USER: {
      const { devUser } = payload;
      const userList = {
        ...state,
        devdata: devUser
      };
      return userList;
    }
   
    case SET_SELECTED_USER: {
      const { selectedUser } = payload;
      const user = {
        ...state,
        selectedUser
      };
      return user;
    }
    
    default:
      return state;
  }
};
