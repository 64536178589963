
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { connect } from "react-redux";
import { map } from "lodash";

import {
    getFeatures,
    getFeatureCount
} from "../../selectors/feature/selector";
import {
    getBusinessScenarios
} from "../../selectors/businessScenario/selector";


const BarChartPlan = ({ release = [] }) => {

    const data1 = []

    const rows =
    map(release, (rel) => (
            data1.push({
                Name: rel.name,
                Feature_count:  rel.features.length
            }
            )))

        return (
        
                <BarChart
                    width={700}
                    height={280}
                    data={data1}
                    margin={{
            top: 50,
            right: 20,
            left: 20,
            bottom: 5,
          }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Name" />
                    <YAxis />
                    <Tooltip />
                    <Legend  />
                    <Bar dataKey="Feature_count" stackId="a" fill="#3f51b5" />
                </BarChart>
        );
    }


    const mapStateToProps = state => ({
        features: getFeatures(state),
        count: getFeatureCount(state),
        release: getBusinessScenarios(state)
    
    });
    

export default connect(mapStateToProps)(BarChartPlan);